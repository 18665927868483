import {call, put, takeEvery} from "redux-saga/effects";

// ApartmentTypes Redux States
import {ADD_NEW_APARTMENT_TYPE, APARTMENT_TYPE_CRITERIA, DELETE_APARTMENT_TYPE, GET_APARTMENT_TYPES, UPDATE_APARTMENT_TYPE} from "./actionTypes";

import {
    addApartmentTypeFail,
    addApartmentTypeSuccess,
    deleteApartmentTypeFail,
    deleteApartmentTypeSuccess,
    getApartmentTypesFail,
    getApartmentTypesSuccess,
    updateApartmentTypeFail,
    updateApartmentTypeSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchApartmentTypes() {
    try {
        const response = yield call(getApartmentTypes);
        yield put(getApartmentTypesSuccess(response));
    } catch (error) {
        yield put(getApartmentTypesFail(error));
    }
}

const getApartmentTypes = () => get(url.APARTMENT_TYPE_GET_ALL)

function* onUpdateApartmentType({ payload: apartmentType }) {
    try {
        const response = yield call(updateApartmentType, apartmentType);
        yield put(updateApartmentTypeSuccess(response));
    } catch (error) {
        yield put(updateApartmentTypeFail(error));
    }
}

const updateApartmentType = (apartmentType) => post(url.APARTMENT_TYPE_API,apartmentType)

function* onDeleteApartmentType({id}) {
    try {
        const response = yield call(deleteApartmentType, id);
        if (response.status === 200){
            yield put(deleteApartmentTypeSuccess(response,id));
        }else{
            yield put(deleteApartmentTypeFail(response));
        }
    } catch (error) {
        yield put(deleteApartmentTypeFail(error));
    }
}

const deleteApartmentType = (id) => del(`${url.APARTMENT_TYPE_API}/${id}`);

function* onAddNewApartmentType({ payload: apartmentType }) {
    try {
        const response = yield call(addNewApartmentType, apartmentType);

        yield put(addApartmentTypeSuccess(response));
    } catch (error) {
        yield put(addApartmentTypeFail(error));
    }
}

const addNewApartmentType = (apartmentType) => post(url.APARTMENT_TYPE_API,apartmentType);

function* apartmentTypesSaga() {
    yield takeEvery(GET_APARTMENT_TYPES, fetchApartmentTypes);
    yield takeEvery(ADD_NEW_APARTMENT_TYPE, onAddNewApartmentType);
    yield takeEvery(UPDATE_APARTMENT_TYPE, onUpdateApartmentType);
    yield takeEvery(DELETE_APARTMENT_TYPE, onDeleteApartmentType);
}

export default apartmentTypesSaga;
