import {
  ADD_APARTMENT_RESERVATION_FAIL,
  ADD_APARTMENT_RESERVATION_SUCCESS,
  DELETE_APARTMENT_RESERVATION_FAIL,
  DELETE_APARTMENT_RESERVATION_SUCCESS,
  GET_LEAD_APARTMENT_RESERVATIONS_FAIL,
  GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS,
  GET_APARTMENT_RESERVATION_CRITERIA_FAIL,
  GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS,
  GET_APARTMENT_RESERVATIONS_FAIL,
  GET_APARTMENT_RESERVATIONS_SUCCESS,
  GET_TODAY_APARTMENT_RESERVATIONS_FAIL,
  GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS,
  GET_USER_APARTMENT_RESERVATIONS_FAIL,
  GET_USER_APARTMENT_RESERVATIONS_SUCCESS,
  UPDATE_APARTMENT_RESERVATION_FAIL,
  UPDATE_APARTMENT_RESERVATION_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  apartmentReservations: [],
  error: {},
  success: false
};

const ApartmentReservation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APARTMENT_RESERVATIONS_SUCCESS:
      return {
        ...state,
        apartmentReservations: action.payload
      };

    case GET_APARTMENT_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS:
      return {
        ...state,
        apartmentReservations: action.payload
      };

    case GET_LEAD_APARTMENT_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_APARTMENT_RESERVATIONS_SUCCESS:
      return {
        ...state,
        apartmentReservations: action.payload
      };

    case GET_USER_APARTMENT_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_APARTMENT_RESERVATION_SUCCESS:
      return {
        ...state,
        apartmentReservations: [...state.apartmentReservations, action.payload],
        success: true
      };

    case ADD_APARTMENT_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_APARTMENT_RESERVATION_SUCCESS:
      return {
        ...state,
        success: true,
        apartmentReservations: state.apartmentReservations.map(customerAppointment =>
          customerAppointment.id.toString() === action.payload.id.toString()
            ? action.payload
            : customerAppointment
        )
      };

    case UPDATE_APARTMENT_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_APARTMENT_RESERVATION_SUCCESS:
      return {
        ...state,
        success: true,
        apartmentReservations: state.apartmentReservations.filter(
          customerAppointment => customerAppointment.id.toString() !== action.req.toString()
        )
      };

    case DELETE_APARTMENT_RESERVATION_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS:
      return {
        ...state,
        apartmentReservations: action.payload
      };

    case GET_TODAY_APARTMENT_RESERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS:
      return {
        ...state,
        apartmentReservations: action.payload.content
      };

    case GET_APARTMENT_RESERVATION_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
        success: false,
        error: {}
      };
  }
};

export default ApartmentReservation;
