import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//E-commerce
import Lead from "./leads/reducer";

//User
import User from "./users/reducer";

// segment
import Segment from "./segment/reducer";

// Resource
import Resource from "./resource/reducer";

// category
import Category from "./category/reducer";

// dashboard
import Dashboard from "./dashboard/reducer";

// roles
import Role from "./roles/reducer";

// notes
import Note from "./note/reducer";

// import
import FileImport from "./import/reducer";

// reference
import Reference from "./reference/reducer";

// appointment
import Appointment from "./appointment/reducer";

//criteria
import Criteria from "./Criteria/reducer";

//report
import Report from "./report/reducer";

//report
import Language from "./language/reducer";

//IntegrationDefinition
import IntegrationDefinition from "./IntegrationDefinition/reducer";

//report
import Platform from "./platform/reducer";

// reminder
import Reminder from "./reminder/reducer";

// system parameter
import Parameter from "./parameter/reducer";

// history
import History from "./history/reducer";

// auth log
import AuthLog from "./authLog/reducer";

// TermsAndPrivacy
import TermsAndPolicy from "./TermsAndPrivacy/reducer";

// forms
import FacebookForm from "./FacebookForms/reducer";

//
import LeadIntegrationDef from "./leadIntegrationDefinition/reducer";

// calendar
import Calendar from "./calendar/reducer";
//Doctor
import Doctor from "./doctor/reducer";

//Hospital
import Hospital from "./hospital/reducer";

//Operation Definition
import OperationDefinition from "./operationDefinition/reducer";

//Operation Category
import OperationCategory from "./operationCategory/reducer";

//Medical Operation
import MedicalOperation from "./medicalOperation/reducer";

//Offer
import Offer from "./offer/reducer";

//Offer Item
import OfferItem from "./offerItem/reducer";

//Description Template
import DescriptionTemplate from "./descriptionTemplate/reducer";

//Firm Info
import FirmInfo from "./firmInfo/reducer";

//Screen
import Screen from "./screen/reducer";

//Screen Action
import ScreenAction from "./screenAction/reducer";

//Group
import Group from "./group/reducer";

//Mail Info
import MailInfo from "./mailInfo/reducer";

import Translate from "./translate/reducer";

//Patient
import Patient from "./patient/reducer";

import PatientAppointment from "./patientAppointment/reducer";
import PatientReminder from "./patientReminder/reducer";
import PatientNote from "./patientNote/reducer";
import Department from "./department/reducer";
import UserWorkSchedule from "./userWorkSchedule/reducer";
import UserAvailability from "./userAvailability/reducer";
import Section from "./section/reducer";
import Building from "./building/reducer";
import Apartment from "./apartment/reducer";
import ApartmentRoom from "./apartmentRoom/reducer";
import ApartmentDirection from "./apartmentDirection/reducer";
import ApartmentType from "./apartmentType/reducer";
import RealEstateProject from "./realEstateProject/reducer";

//Payment
import Payment from "./payment/reducer";
import Customer from "./customer/reducer";
import CustomerAppointment from "./customerAppointment/reducer";
import ApartmentReservation from "./apartmentReservation/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Lead,
  User,
  Segment,
  Resource,
  Category,
  Dashboard,
  Role,
  Note,
  FileImport,
  Reference,
  Appointment,
  Criteria,
  Report,
  Language,
  IntegrationDefinition,
  Platform,
  Reminder,
  Parameter,
  History,
  TermsAndPolicy,
  FacebookForm,
  AuthLog,
  LeadIntegrationDef,
  Doctor,
  Hospital,
  OperationCategory,
  OperationDefinition,
  MedicalOperation,
  Offer,
  OfferItem,
  DescriptionTemplate,
  FirmInfo,
  Screen,
  ScreenAction,
  Calendar,
  Group,
  Patient,
  PatientAppointment,
  PatientNote,
  PatientReminder,
  Department,
  UserWorkSchedule,
  UserAvailability,
  Payment,
  Section,
  MailInfo,
  Translate,
  Apartment,
  Building,
  ApartmentRoom,
  Customer,
  CustomerAppointment,
  ApartmentReservation,
  RealEstateProject,
  ApartmentType,
  ApartmentDirection
})

export default rootReducer
