import {
    ADD_CUSTOMER_FAIL,
    ADD_CUSTOMER_SUCCESS,
    ADD_NEW_CUSTOMER,
    CUSTOMER_CRITERIA,
    CUSTOMER_CRITERIA_FAIL,
    CUSTOMER_CRITERIA_SUCCESS, CUSTOMER_FIND_FILTERS, CUSTOMER_FIND_FILTERS_FAIL, CUSTOMER_FIND_FILTERS_SUCCESS,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_FAIL,
    DELETE_CUSTOMER_SUCCESS,
    GET_CUSTOMERS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_FAIL,
    UPDATE_CUSTOMER_SUCCESS
} from "./actionTypes";

export const getCustomers = () => ({
    type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: customers,
})

export const getCustomersFail = error => ({
    type: GET_CUSTOMERS_FAIL,
    payload: error,
})

export const addNewCustomer = customer => ({
    type: ADD_NEW_CUSTOMER,
    payload: customer,
})

export const addCustomerSuccess = customer => ({
    type: ADD_CUSTOMER_SUCCESS,
    payload: customer,
})

export const addCustomerFail = error => ({
    type: ADD_CUSTOMER_FAIL,
    payload: error,
})

export const updateCustomer = customer => ({
    type: UPDATE_CUSTOMER,
    payload: customer,
})

export const updateCustomerSuccess = customer => ({
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: customer,
})

export const updateCustomerFail = error => ({
    type: UPDATE_CUSTOMER_FAIL,
    payload: error,
})

export const deleteCustomer = id => ({
    type: DELETE_CUSTOMER,
    id,
})

export const deleteCustomerSuccess = (customer,req) => ({
    type: DELETE_CUSTOMER_SUCCESS,
    payload: customer,
    req: req,
})

export const deleteCustomerFail = error => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error,
})

export const customerCriteria = res => ({
    type: CUSTOMER_CRITERIA,
    payload: res
});

export const customerCriteriaSuccess = (res) => ({
    type: CUSTOMER_CRITERIA_SUCCESS,
    payload: res
});

export const customerCriteriaFail = error => ({
    type: CUSTOMER_CRITERIA_FAIL,
    payload: error
});

export const customerFindFilters = () => ({
    type: CUSTOMER_FIND_FILTERS,
})

export const customerFindFiltersSuccess = customers => ({
    type: CUSTOMER_FIND_FILTERS_SUCCESS,
    payload: customers,
})

export const customerFindFiltersFail = error => ({
    type: CUSTOMER_FIND_FILTERS_FAIL,
    payload: error,
})