import {
    ADD_APARTMENT_DIRECTION_FAIL,
    ADD_APARTMENT_DIRECTION_SUCCESS,
    ADD_NEW_APARTMENT_DIRECTION,
    DELETE_APARTMENT_DIRECTION,
    DELETE_APARTMENT_DIRECTION_FAIL,
    DELETE_APARTMENT_DIRECTION_SUCCESS,
    GET_APARTMENT_DIRECTIONS,
    GET_APARTMENT_DIRECTIONS_FAIL,
    GET_APARTMENT_DIRECTIONS_SUCCESS,
    UPDATE_APARTMENT_DIRECTION,
    UPDATE_APARTMENT_DIRECTION_FAIL,
    UPDATE_APARTMENT_DIRECTION_SUCCESS
} from "./actionTypes";

export const getApartmentDirections = () => ({
    type: GET_APARTMENT_DIRECTIONS,
})

export const getApartmentDirectionsSuccess = apartments => ({
    type: GET_APARTMENT_DIRECTIONS_SUCCESS,
    payload: apartments,
})

export const getApartmentDirectionsFail = error => ({
    type: GET_APARTMENT_DIRECTIONS_FAIL,
    payload: error,
})

export const addNewApartmentDirection = apartment => ({
    type: ADD_NEW_APARTMENT_DIRECTION,
    payload: apartment,
})

export const addApartmentDirectionSuccess = apartment => ({
    type: ADD_APARTMENT_DIRECTION_SUCCESS,
    payload: apartment,
})

export const addApartmentDirectionFail = error => ({
    type: ADD_APARTMENT_DIRECTION_FAIL,
    payload: error,
})

export const updateApartmentDirection = apartment => ({
    type: UPDATE_APARTMENT_DIRECTION,
    payload: apartment,
})

export const updateApartmentDirectionSuccess = apartment => ({
    type: UPDATE_APARTMENT_DIRECTION_SUCCESS,
    payload: apartment,
})

export const updateApartmentDirectionFail = error => ({
    type: UPDATE_APARTMENT_DIRECTION_FAIL,
    payload: error,
})

export const deleteApartmentDirection = id => ({
    type: DELETE_APARTMENT_DIRECTION,
    id,
})

export const deleteApartmentDirectionSuccess = (apartment,req) => ({
    type: DELETE_APARTMENT_DIRECTION_SUCCESS,
    payload: apartment,
    req: req,
})

export const deleteApartmentDirectionFail = error => ({
    type: DELETE_APARTMENT_DIRECTION_FAIL,
    payload: error,
})
