
/* APARTMENT_DIRECTIONS */
export const GET_APARTMENT_DIRECTIONS = "GET_APARTMENT_DIRECTIONS"
export const GET_APARTMENT_DIRECTIONS_SUCCESS = "GET_APARTMENT_DIRECTIONS_SUCCESS"
export const GET_APARTMENT_DIRECTIONS_FAIL = "GET_APARTMENT_DIRECTIONS_FAIL"

/**
 * add APARTMENT_DIRECTION
 */
export const ADD_NEW_APARTMENT_DIRECTION = "ADD_NEW_APARTMENT_DIRECTION"
export const ADD_APARTMENT_DIRECTION_SUCCESS = "ADD_APARTMENT_DIRECTION_SUCCESS"
export const ADD_APARTMENT_DIRECTION_FAIL = "ADD_APARTMENT_DIRECTION_FAIL"

/**
 * Edit APARTMENT_DIRECTION
 */
export const UPDATE_APARTMENT_DIRECTION = "UPDATE_APARTMENT_DIRECTION"
export const UPDATE_APARTMENT_DIRECTION_SUCCESS = "UPDATE_APARTMENT_DIRECTION_SUCCESS"
export const UPDATE_APARTMENT_DIRECTION_FAIL = "UPDATE_APARTMENT_DIRECTION_FAIL"

/**
 * Delete APARTMENT_DIRECTION
 */
export const DELETE_APARTMENT_DIRECTION = "DELETE_APARTMENT_DIRECTION"
export const DELETE_APARTMENT_DIRECTION_SUCCESS = "DELETE_APARTMENT_DIRECTION_SUCCESS"
export const DELETE_APARTMENT_DIRECTION_FAIL = "DELETE_APARTMENT_DIRECTION_FAIL"

/**
 * Lead Criteria
 */
export const APARTMENT_DIRECTION_CRITERIA = "APARTMENT_DIRECTION_CRITERIA"
export const APARTMENT_DIRECTION_CRITERIA_SUCCESS = "APARTMENT_DIRECTION_CRITERIA_SUCCESS"
export const APARTMENT_DIRECTION_CRITERIA_FAIL = "APARTMENT_DIRECTION_CRITERIA_FAIL"
