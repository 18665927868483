import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import {useDispatch, useSelector} from "react-redux";

// Column
import {withTranslation} from "react-i18next";
import {
    addNewCustomerAppointment,
    deleteCustomerAppointment,
    getCustomerAppointmentCriteria,
    getCustomerAppointments
} from "../../store/customerAppointment/actions";
import WarningModal from "../../components/Common/WarningModal";
import Select from "react-select";
import {DateToString} from "../../common/commonFunctions";
import AppointmentDetail from "./appointmentDetail";
import {Type} from "../LeadIntegrationDefinition/leadIntDefCol";
import {ApptDate, Status} from "./appointmentCol";
import TableContainer from "../../components/Common/TableContainer";
import Notification from "../../components/Notification";
import withRouter from "../../hooks/withRouter";
import {apptStatusActionClass} from "../../components/Calendar/constant";
import {userCriteria} from "../../store/users/actions";
import {getCustomers} from "../../store/customer/actions";

const CustomerAppointment = props => {

    //meta title
    document.title = `CRM | ${props.t("Customer Appointments")}`;

    const now = new Date();
    const plus2Month = new Date(now.getFullYear(),
        (now.getMonth() + 2),
        now.getDay());

    const apptStatusOptions = [
        {label: props.t("WAITING"), value: "WAITING"},
        {label: props.t("APPROVED"), value: "APPROVED"},
        {label: props.t("REJECTED"), value: "REJECTED"},
        {label: props.t("CANCELED"), value: "CANCELLED"},
        {label: props.t("COMPLETED"), value: "COMPLETED"}
    ];

    const initialCustomerAppointment = {
        appointmentDate: "",
        appointmentEndDate: "",
        description: "",
        status: "",
        customer: {},
        appointmentUser: {}
    }

    const dispatch = useDispatch();

    const [customerAppointment, setCustomerAppointment] = useState(initialCustomerAppointment);
    const [apptStatus, setApptStatus] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isApptModalOpen, setIsApptModalOpen] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [filterCreateDateStart, setFilterCreateDateStart] = useState(DateToString(now, "YYYY-MM-DD", "-"));
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState(DateToString(plus2Month, "YYYY-MM-DD", "-"));
    const [filterUser, setFilterUser] = useState();
    const [filterCustomer, setFilterCustomer] = useState("");
    const [filterStatus, setFilterStatus] = useState({label: props.t("WAITING"), value: "WAITING"});
    const [filterPhone, setFilterPhone] = useState("");
    const [userOptions, setUserOptions] = useState("");
    const [customerOptions, setCustomerOptions] = useState("");

    function handleFilterCreateDateStart(e) {
        setFilterCreateDateStart(e.target.value);
    }

    function handleFilterCreateDateEnd(e) {
        setFilterCreateDateEnd(e.target.value);
    }

    function handleFilterStatus(e) {
        setFilterStatus(e);
    }

    const handleClearFilterInputs = () => {
        setFilterCreateDateStart("");
        setFilterCreateDateEnd("");
        setFilterUser();
        setFilterCustomer();
        setFilterPhone("");
        setFilterStatus();
    };

    const handleOpenModal = () => setIsModalOpen(!isModalOpen);
    const handleOpenApptModal = () => {
        setIsApptModalOpen(!isApptModalOpen);
    };

    const {customerAppointments, error, success} = useSelector(state => ({
        customerAppointments: state.CustomerAppointment.customerAppointments,
        error: state.CustomerAppointment.error,
        success: state.CustomerAppointment.success
    }));

    const {users, userError} = useSelector(state => ({
        users: state.User.users,
        userError: state.User.error
    }));

    useEffect(() => {
        let userReq = Object.assign({}, {notRole: "ROLE_MODERATOR", status: "ACTIVE", role: "ROLE_DOCTOR"});
        dispatch(userCriteria(userReq));
    }, []);

    useEffect(() => {
        setUserOptions(users.map(item => {
            return {label: item.fullName, value: item}
        }));
    }, [users])

    const {customers, custError} = useSelector(state => ({
        customers: state.Customer.customers,
        custError: state.Customer.error
    }));

    useEffect(() => {
        dispatch(getCustomerAppointments());
    }, []);

    useEffect(() => {
        setCustomerOptions(customers.map(item => {
            return {label: item.fullName, value: item}
        }));
    }, [customers])

    const handleDelete = () => {
        setDeleteModal(false);
        selecteds.forEach(customerAppointment => dispatch(deleteCustomerAppointment(customerAppointment.id)));
        setSelecteds([]);
        setShowOptions(false);
    };

    useEffect(() => {
        if (error && Object.entries(error).length > 0 && !success) {
            setMessage(error.data.message);
            setShowNotification(true);
            setNotificationType("Danger");
        } else if (success) {
            setMessage(`${props.t("Success")}`);
            setShowNotification(true);
            setNotificationType("Success");
            isApptModalOpen === true ? handleOpenApptModal() : isModalOpen === true ? handleOpenModal() : null;
        }
    }, [error, success]);

    let body = {
        size: 1000,
        page: 0,
        appointmentDateStart: filterCreateDateStart,
        appointmentDateEnd: filterCreateDateEnd,
    };

    const customerAppointmentColumns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Appointment Time")}`,
                accessor: "appointmentDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ApptDate {...cellProps} />;
                }
            }, {
                HeaderLabel: `${props.t("Appointment Time End")}`,
                accessor: "appointmentEndDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ApptDate {...cellProps} />;
                }
            }, {
                HeaderLabel: `${props.t("Customer")}`,
                accessor: "customer.fullName",
                filterable: true,
                Cell: (cellProps) => {
                    return <Type {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Status")}`,
                accessor: "status",
                filterable: true,
                Cell: (cellProps) => {
                    const data = cellProps.row.original;
                    return (
                        <Badge
                            key={data.id}
                            pill
                            className={apptStatusActionClass[data.status]}
                        >
                            {props.t(data.status)}
                        </Badge>);
                }
            },
            {
                HeaderLabel: `${props.t("Specialist")}`,
                accessor: "appointmentUser.fullName",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            },
            {
                HeaderLabel: `${props.t("Description")}`,
                accessor: "description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        dispatch(getCustomers());
    }, []);

    const handleFilteredCustomerAppointmentData = () => {
        if (filterCreateDateStart !== "") {
            body = Object.assign(body, {appointmentDateStart: filterCreateDateStart});
        } else {
            delete body.appointmentDateStart;
        }
        if (filterCreateDateEnd !== "") {
            body = Object.assign(body, {appointmentDateEnd: filterCreateDateEnd});
        } else {
            delete body.appointmentDateEnd;
        }
        if (filterStatus && filterStatus.value) {
            body = Object.assign(body, {status: filterStatus.value});
        } else {
            delete body.status;
        }
        if (filterUser && filterUser.value) {
            body = Object.assign(body, {appointmentUser: filterUser.value.id});
        }
        if (filterCustomer) {
            body = Object.assign(body, {customerName: filterUser.value});
        }
        if (filterPhone) {
            body = Object.assign(body, {phoneNumber: filterPhone});
        }
        dispatch(getCustomerAppointments());
    };

    function handleChangeInput(e) {
        setCustomerAppointment({
            ...customerAppointment,
            [e.target.name]: e.target.value
        });
    };

    const handleRefresh = () => {
        dispatch(getCustomerAppointments());
    };

    const handleRowClick = row => {
        const customerAppt = row.original;
        setCustomerAppointment(customerAppt);
        customerAppointment.status ? setApptStatus({
            label: customerAppointment.status,
            value: customerAppointment.status
        }) : null;

        handleOpenModal();
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const saveAppointment = () => {
        const req = {
            appointmentDate: customerAppointment.appointmentDate.replaceAll("T", " ") + ":00",
            appointmentEndDate: customerAppointment.appointmentEndDate.replaceAll("T", " ") + ":00",
            description: customerAppointment.description,
            status: customerAppointment.status || "WAITING",
            customer: customerAppointment.customer,
            appointmentUser: customerAppointment.appointmentUser,
        }
        dispatch(addNewCustomerAppointment(req));
    };

    function onChangeCustomer(event) {
        setSelectedCustomer(event);
        setCustomerAppointment({
            ...customerAppointment,
            customer: event.value
        })
    }

    function onChangeUser(event) {
        setSelectedUser(event);
        setCustomerAppointment({
            ...customerAppointment,
            appointmentUser: event.value
        })
    }

    function onChangeStatus(event) {
        setApptStatus(event);
        setCustomerAppointment({
            ...customerAppointment,
            status: event.value
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Notification
                        message={message}
                        show={showNotification}
                        callbackShow={handleClose}
                        type={notificationType}
                    />
                    <WarningModal
                        id="deleteModal"
                        show={deleteModal}
                        onApproveClick={handleDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <Modal isOpen={isModalOpen} toggle={handleOpenModal} centered={true} size="md" backdrop="static">
                        <ModalHeader toggle={handleOpenModal} tag="h4">{props.t("Appointment Detail")}</ModalHeader>
                        <ModalBody>
                            {customerAppointment ? (
                                <AppointmentDetail
                                    t={props.t}
                                    detailObject={customerAppointment}
                                    callback={handleOpenModal}
                                />
                            ) : null}
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={isApptModalOpen} toggle={handleOpenApptModal} centered={true} size="md"
                           backdrop="static">
                        <ModalHeader toggle={handleOpenApptModal} tag="h4">{props.t("New Appointment")}</ModalHeader>
                        <ModalBody>
                            <Row className="m-2">
                                <Col className="col-12">
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Customer")}</Label>
                                        <Select
                                            value={selectedCustomer}
                                            onChange={(event) => onChangeCustomer(event)}
                                            options={customerOptions}
                                            className="bs-select"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("User")}</Label>
                                        <Select
                                            value={selectedUser}
                                            onChange={(event) => onChangeUser(event)}
                                            options={userOptions}
                                            className="bs-select"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Status")}</Label>
                                        <Select
                                            id="Status-user"
                                            name="status"
                                            onChange={e => onChangeStatus(e)}
                                            value={apptStatus}
                                            options={apptStatusOptions}
                                            isClearable={true}
                                        />
                                    </div>
                                    <Col className="col-12 mb-3">
                                        <div className="row row-cols-2">
                                            <div className="col-md-6">
                                                <Label>{props.t("Start Date")}</Label>
                                                <Input
                                                    id="appointmentDate"
                                                    name="appointmentDate"
                                                    type="datetime-local"
                                                    onChange={handleChangeInput}
                                                    value={customerAppointment && customerAppointment.appointmentDate || ""}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Label>{props.t("End Date")}</Label>
                                                <Input
                                                    id="appointmentEndDate"
                                                    name="appointmentEndDate"
                                                    type="datetime-local"
                                                    onChange={handleChangeInput}
                                                    value={customerAppointment && customerAppointment.appointmentEndDate || ""}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                                <Row className="m-2">
                                    <Col>
                                        <div className="text-end mt-2">
                                            <button
                                                id="save"
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={() => saveAppointment()}
                                            >
                                                {"Save"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </ModalBody>
                    </Modal>
                    <Breadcrumbs title={props.t("Customer Appointments")}/>
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Start Date")}</Label>
                                        <Input
                                            id="create-date-start"
                                            name="create-date-start"
                                            type="date"
                                            onChange={e => handleFilterCreateDateStart(e)}
                                            value={filterCreateDateStart}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("End Date")}</Label>
                                        <Input
                                            id="create-date-end"
                                            name="create-date-end"
                                            type="date"
                                            onChange={e => handleFilterCreateDateEnd(e)}
                                            value={filterCreateDateEnd}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Status")}</Label>
                                        <Select
                                            id="Status-user"
                                            name="status"
                                            onChange={e => handleFilterStatus(e)}
                                            value={filterStatus}
                                            options={apptStatusOptions}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mt-4 col text-end">
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleFilteredCustomerAppointmentData()}
                                        >
                                            <i className="mdi mdi-search-web me-1"/>
                                            {props.t("Filter")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="warning"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleClearFilterInputs()}
                                        >
                                            <i className="mdi mdi-format-clear me-1"/>
                                            {props.t("Clear All Filters")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded m-0"
                                            onClick={handleRefresh}
                                        >
                                            <i className="mdi mdi-refresh"/>
                                        </Button>
                                        {showOptions ? (
                                            <Button
                                                id="delete"
                                                type="button"
                                                color="danger"
                                                className="btn-rounded mb-2 me-2"
                                                onClick={() => setDeleteModal(true)}
                                            >
                                                <i className="mdi mdi-delete me-1"/>
                                                {props.t("Delete")}
                                            </Button>
                                        ) : ""}
                                    </div>
                                </div>
                                <hr/>
                                <TableContainer
                                    refreshButton={true}
                                    handleRefreshClick={handleRefresh}
                                    columns={customerAppointmentColumns}
                                    data={customerAppointments || []}
                                    handleRowClick={handleRowClick}
                                    isGlobalFilter={true}
                                    isAddOptions={true}
                                    handleAddClick={handleOpenApptModal}
                                    customPageSize={50}
                                    className="custom-header-css"
                                />
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
CustomerAppointment.propTypes = {
    t: PropTypes.any,
    customerAppointments: PropTypes.array,
    getCustomerAppointments: PropTypes.func
};

export default withRouter(withTranslation()(CustomerAppointment));
