import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Button, Input} from "reactstrap";

//Import Breadcrumb
//redux
import {useDispatch, useSelector} from "react-redux";

// Column
import {withTranslation} from "react-i18next";
import BasicTable from "../../../components/Common/BasicTable";
import {addNewApartmentReservation, getApartmentReservations} from "../../../store/apartmentReservation/actions";
import {ApptDate} from "../../ApartmentReservation/appointmentCol";
import {Type} from "../../LeadIntegrationDefinition/leadIntDefCol";
import Select from "react-select";
import {getCustomers} from "../../../store/customer/actions";

const ApartmentReservation = props => {

    const dispatch = useDispatch();

    const initialApartmentReservation = {
        startDate: "",
        endDate: "",
        description: "",
        status: "",
        customer: {},
        apartment: {}
    }

    const [apartment, setApartment] = useState(props.apartment);
    const [apartmentReservation, setApartmentReservation] = useState(initialApartmentReservation);
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [customerOptions, setCustomerOptions] = useState([]);

    const apartmentReservationColumns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Start Date")}`,
                accessor: "startDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ApptDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("End Date")}`,
                accessor: "endDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ApptDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Customer")}`,
                accessor: "customer.fullName",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            },
            {
                HeaderLabel: `${props.t("Description")}`,
                accessor: "description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            },
        ],
        []
    );

    useEffect(() => {
        dispatch(getCustomers());
    }, []);

    const {customers, custError} = useSelector(state => ({
        customers: state.Customer.customers,
        custError: state.Customer.error
    }));

    useEffect(() => {
        setCustomerOptions(customers.map(item => {
            return {label: item.fullName, value: item}
        }));
    }, [customers])

    useEffect(() => {
        setApartment(props.apartment);
    }, [props.apartment]);

    const saveApartmentReservation = (e) => {
        const req = {
            startDate: apartmentReservation.startDate.replaceAll("T", " ") + ":00",
            endDate: apartmentReservation.endDate.replaceAll("T", " ") + ":00",
            description: apartmentReservation.description,
            customer: apartmentReservation.customer,
            apartment: apartment,
        }
        dispatch(addNewApartmentReservation(req));
    };

    const handleChange = (e) => {
        setApartmentReservation({
            ...apartmentReservation,
            [e.target.name]: e.target.value
        })
    };

    function onChangeCustomer(event) {
        setSelectedCustomer(event);
        setApartmentReservation({
            ...apartmentReservation,
            customer: event.value
        })
    }

    const {apartmentReservations, error, success} = useSelector(state => ({
        apartmentReservations: state.ApartmentReservation.apartmentReservations,
        error: state.ApartmentReservation.error,
        success: state.ApartmentReservation.success
    }));

    useEffect(() => {
        dispatch(getApartmentReservations());
    }, []);

    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="row">
                    <div className="col-xs-12 col-md-4">
                        <div className="col">
                            <label>{props.t("Appointment Start Date")}</label>
                        </div>
                        <div className="col">
                            <Input
                                id="startDate"
                                name="startDate"
                                type="datetime-local"
                                value={apartmentReservation && apartmentReservation.startDate || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className="col">
                            <label>{props.t("Appointment End Date")}</label>
                        </div>
                        <div className="col">
                            <Input
                                id="endDate"
                                name="endDate"
                                type="datetime-local"
                                value={apartmentReservation && apartmentReservation.endDate || ""}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className="col">
                            <label>{props.t("Customer")}</label>
                        </div>
                        <div className="col">
                            <Select
                                value={selectedCustomer}
                                onChange={(event) => onChangeCustomer(event)}
                                options={customerOptions}
                                className="bs-select"
                                isClearable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <label>{props.t("Description")}</label>
                        <Input
                            id="description"
                            name="description"
                            value={apartmentReservation && apartmentReservation.description || ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-4 text-end">
                        <Button
                            id="saveAppt"
                            type="button"
                            color="success"
                            onClick={saveApartmentReservation}
                        >
                            {props.t("Add")}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                {apartmentReservations.length > 0 && (
                    <BasicTable
                        id="apartmentReservation"
                        customPageSize={10}
                        columns={apartmentReservationColumns}
                        data={apartmentReservations || []}
                        className="custom-header-basic-css"
                    />
                )}
            </div>
        </React.Fragment>
    );
};
ApartmentReservation.propTypes = {
    apartment: PropTypes.any
};

export default withTranslation()(ApartmentReservation);
