
/* APARTMENTS */
export const GET_APARTMENTS = "GET_APARTMENTS"
export const GET_APARTMENTS_SUCCESS = "GET_APARTMENTS_SUCCESS"
export const GET_APARTMENTS_FAIL = "GET_APARTMENTS_FAIL"

/**
 * add APARTMENT
 */
export const ADD_NEW_APARTMENT = "ADD_NEW_APARTMENT"
export const ADD_APARTMENT_SUCCESS = "ADD_APARTMENT_SUCCESS"
export const ADD_APARTMENT_FAIL = "ADD_APARTMENT_FAIL"

/**
 * Edit APARTMENT
 */
export const UPDATE_APARTMENT = "UPDATE_APARTMENT"
export const UPDATE_APARTMENT_SUCCESS = "UPDATE_APARTMENT_SUCCESS"
export const UPDATE_APARTMENT_FAIL = "UPDATE_APARTMENT_FAIL"

/**
 * Delete APARTMENT
 */
export const DELETE_APARTMENT = "DELETE_APARTMENT"
export const DELETE_APARTMENT_SUCCESS = "DELETE_APARTMENT_SUCCESS"
export const DELETE_APARTMENT_FAIL = "DELETE_APARTMENT_FAIL"

/**
 * Lead Criteria
 */
export const APARTMENT_CRITERIA = "APARTMENT_CRITERIA"
export const APARTMENT_CRITERIA_SUCCESS = "APARTMENT_CRITERIA_SUCCESS"
export const APARTMENT_CRITERIA_FAIL = "APARTMENT_CRITERIA_FAIL"

/**
 * FIND FOLTERS
 */
export const APARTMENT_FIND_FILTERS = "APARTMENT_FIND_FILTERS"
export const APARTMENT_FIND_FILTERS_SUCCESS = "APARTMENT_FIND_FILTERS_SUCCESS"
export const APARTMENT_FIND_FILTERS_FAIL = "APARTMENT_FIND_FILTERS_FAIL"