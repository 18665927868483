import {
    ADD_APARTMENT_ROOM_FAIL,
    ADD_APARTMENT_ROOM_SUCCESS,
    APARTMENT_ROOM_CRITERIA_FAIL,
    APARTMENT_ROOM_CRITERIA_SUCCESS,
    DELETE_APARTMENT_ROOM_FAIL,
    DELETE_APARTMENT_ROOM_SUCCESS,
    GET_APARTMENT_ROOMS_FAIL,
    GET_APARTMENT_ROOMS_SUCCESS,
    UPDATE_APARTMENT_ROOM_FAIL,
    UPDATE_APARTMENT_ROOM_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    apartmentRooms: [],
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const ApartmentRoom = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APARTMENT_ROOMS_SUCCESS:
            return {
                ...state,
                apartmentRooms: action.payload,
            };

        case GET_APARTMENT_ROOMS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_APARTMENT_ROOM_SUCCESS:
            return {
                ...state,
                apartmentRooms: [...state.apartmentRooms, action.payload],
            };

        case ADD_APARTMENT_ROOM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_APARTMENT_ROOM_SUCCESS:
            return {
                ...state,
                apartmentRooms: state.apartmentRooms.map(apartmentRoom =>
                    apartmentRoom.id.toString() === action.payload.id.toString()
                        ? { apartmentRoom, ...action.payload }
                        : apartmentRoom
                ),
            };

        case UPDATE_APARTMENT_ROOM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_APARTMENT_ROOM_SUCCESS:
            return {
                ...state,
                apartmentRooms: state.apartmentRooms.filter(
                    apartmentRoom => apartmentRoom.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_APARTMENT_ROOM_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case APARTMENT_ROOM_CRITERIA_SUCCESS:
            return {
                ...state,
                apartmentRooms: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case APARTMENT_ROOM_CRITERIA_FAIL:
            return {
                ...state,
                apartmentRooms: action.payload.data
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default ApartmentRoom;
