import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CloseButton,
  Col,
  Collapse,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";

import { addNewCustomer, customerCriteria, deleteCustomer, getCustomers } from "store/customer/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { Category, City, CreateDate, Phone, Surname, UpdateDate } from "./CustomerCol";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import CustomerDetail from "./CustomerDetail";
import Notification from "../../components/Notification";
import { phoneOptions } from "../../constants/phoneCodes";
import { phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import { Name } from "../Users/UserList/userlistCol";
import { isEmpty } from "lodash";
import CustomSelectableTable from "../../components/Common/CustomSelectableTable";
import { userCriteria } from "../../store/users/actions";
import useAuth from "../../hooks/useAuth";

const Customers = props => {

    //meta title
    document.title = `CRM | ${props.t("Customers")}`;

    const dispatch = useDispatch();

    const { authUser } = useAuth();
    const roleUser = (authUser && authUser.role && authUser.role.name === "ROLE_USER");
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

    const initialCustomer = {
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      maskedPhoneNumber: "",
      address: "",
      note: "",
      language: "",
      cityName: "",
      country: "",
      birthday: "",
      salesRepresentative: {},
      createUser: {},
      updateUser: {},
      lead: {},
      gender: "",
      type: ""
    };

    const [size, setSize] = useState(50);
    const [activeTab, setActiveTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [customer, setCustomer] = useState(initialCustomer);
    const [gender, setGender] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [callbackClose, setCallbackClose] = useState(false);
    let [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
    const [openedTabs, setOpenedTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [dropDownShown, setDropDownShown] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [clearSelected, setClearSelected] = useState(false);
    const [filterDoctor, setFilterDoctor] = useState(!roleUser ? {} : {
      label: authUser.username,
      value: authUser
    });
    const [filterName, setFilterName] = useState("");
    const [filterCityName, setFilterCityName] = useState("");
    const [filterCountry, setFilterCountry] = useState("");
    const [filterPhone, setFilterPhone] = useState("");
    const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
    const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
    const [filterUpdateDateEnd, setFilterUpdateDateEnd] = useState("");
    const [filterUpdateUser, setFilterUpdateUser] = useState("");
    const [filterIdentityNumber, setFilterIdentityNumber] = useState("");
    const [dataPerPage, setDataPerPage] = useState(50);
    const [isOpen, setIsOpen] = useState(false);
    const [updateUserOptions, setUpdateUserOptions] = useState([]);
    const [sizeButtonDisabled, setSizeButtonDisabled] = useState(false);
    const [mailValid, setMailValid] = useState(true);
    const [mail, setMail] = useState(customer && customer.email || "");
    const [sectionOptions, setSectionOptions] = useState([]);

    const genderOptions = [{ label: props.t("MALE"), value: "MALE" },
      { label: props.t("FEMALE"), value: "FEMALE" }];

    const { customers, page, totalCount, totalPages, error, processResponse } = useSelector(state => ({
      customers: state.Customer.customers,
      page: state.Customer.page,
      totalCount: state.Customer.totalCount,
      totalPages: state.Customer.totalPages,
      error: state.Customer.error,
      processResponse: state.Customer.processResponse
    }));

    const initialBody = {
      size: size,
      page: page ? page : 0
    };

    const [body, setBody] = useState(initialBody);

    useEffect(() => {
      dispatch(getCustomers());
    }, []);


    useEffect(() => {
      let userReq = Object.assign({}, { notRole: "ROLE_MODERATOR", status: "ACTIVE", role: "ROLE_DOCTOR" });
      dispatch(userCriteria(userReq));
    }, []);

    const toggleFilter = () => setIsOpen(!isOpen);

    const onChangeInGenderSelect = event => {
      setCustomer({
        ...customer,
        ["gender"]: event !== null ? event.value : null
      });
      setGender((event));
    };

    const handleChangeFilterName = (e) => {
      setFilterName(e.target.value);
      setBody({
        ...body,
        ["fullName"]: e.target.value
      });
    };

    const handleChangeFilterCityName = (e) => {
      setFilterCityName(e.target.value);
      setBody({
        ...body,
        ["cityName"]: e.target.value
      });
    };

    const handleChangeFilterCountry = (e) => {
      setFilterCountry(e.target.value);
      setBody({
        ...body,
        ["country"]: e.target.value
      });
    };

    const handleChangeFilterPhoneNumber = (e) => {
      setFilterPhone(e.target.value);
      setBody({
        ...body,
        ["phoneNumber"]: e.target.value
      });
    };

    function handleFilterCreateDateStart(e) {
      setFilterCreateDateStart(e.target.value);
      setBody({
        ...body,
        ["createDateStart"]: e.target.value
      });
    }

    function handleFilterCreateDateEnd(e) {
      setFilterCreateDateEnd(e.target.value);
      setBody({
        ...body,
        ["createDateEnd"]: e.target.value
      });
    }

    function handleFilterUpdateDateStart(e) {
      setFilterUpdateDateStart(e.target.value);
      setBody({
        ...body,
        ["updateDateStart"]: e.target.value
      });
    }

    function handleFilterUpdateDateEnd(e) {
      setFilterUpdateDateEnd(e.target.value);
      setBody({
        ...body,
        ["updateDateEnd"]: e.target.value
      });
    }

    const handleChangeMail = (e) => {
      const regEXpMail = /^\S+@\S+\.\S+$/;
      setMailValid(false);
      const mail = e.target.value.trim();
      setMail(mail);
      if (regEXpMail.test(mail)) {
        setMailValid(true);
        setCustomer({
          ...customer,
          ["email"]: mail
        });
      }
    };

    const handleChange = (e) => {
      switch (e.target.type) {
        case "text":
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
        case "textarea":
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
        default :
          setCustomer({
            ...customer,
            [e.target.name]: e.target.value
          });
          break;
      }

    };

    const handleChangePhone = (e) => {
      let str = e.target.value.toString();
      let phoneCode = "";
      let tel = "";
      if (str.includes("+")) {
        phoneCode = str.split(/\s/)[0];
        findPhoneCode(phoneCode);
        tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
      } else {
        tel = str.replaceAll(/\s+/g, "");
      }
      setCustomer({
        ...customer,
        ["phoneNumber"]: tel
      });
    };

    const findPhoneCode = (code) => {
      if (currentLanguage === "en") {
        phoneOptions.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      } else {
        phoneOptionsTurkish.forEach(cd => {
          if (cd.value === code)
            setPhoneCode(cd);
        });
      }
    };

    const handleSubmit = () => {
      dispatch(addNewCustomer(customer));
    };

    useEffect(() => {
      if (!isEmpty(error)) {
        setShowNotification(true);
        setMessage(error.message);
        setNotificationType("Danger");
      } else if (processResponse) {
        setShowNotification(true);
        setMessage("Değişiklikler Kaydedildi");
        setNotificationType("Success");
        if (modal) {
          toggle();
        }
      }
    }, [error, processResponse]);

    const handleCustomerClick = row => {
      const selected = row.original;

      setCustomer(selected);
      selected.gender ? setGender({ label: selected.gender, value: selected.gender }) : null;
      const exist = checkCustomerExistOnOpenedTab(selected);
      if (exist) {
        toggleTab("toggle", exist.tabIndex, selected);
      } else {
        createTab(tabIndex + 1, selected);
      }
    };

    const columns = useMemo(
      () => [
        {
          HeaderLabel: `${props.t("Firstname")}`,
          accessor: "firstName",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Lastname")}`,
          accessor: "lastName",
          Cell: (cellProps) => {
            return <Surname {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Phone")}`,
          accessor: "maskedPhone",
          Cell: (cellProps) => {
            return <Phone {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("City")}`,
          accessor: "cityName",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Country")}`,
          accessor: "country",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Create Date")}`,
          accessor: "createDate",
          Cell: (cellProps) => {
            return <CreateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update Date")}`,
          accessor: "updateDate",
          Cell: (cellProps) => {
            return <UpdateDate {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Update User")}`,
          accessor: "updateUser.fullName",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        }
      ],
      []
    );

    const onChangePhoneCode = (e) => {
      setPhoneCode({ label: e.target.label, value: e.target.value });
    };

    const toggle = () => {
      if (modal) {
        setModal(false);
        setCustomer(null);
        setGender(null);
        setMail("");
      } else {
        setModal(true);
        setCustomer(null);
        setGender(null);
        setMail("");
        setPhoneCode(phoneOptions[0]);
      }
    };

    const closeTab = (tab) => {
      const founded = openedTabs.findIndex(item => item.tabIndex === tab.tabIndex);
      const filtered = openedTabs.filter(item => item.tabIndex !== tab.tabIndex);
      setOpenedTabs(filtered);
      if (founded === 0) {
        toggleTab("toggle", 0);
      } else {
        toggleTab("toggle", openedTabs[founded - 1].tabIndex, openedTabs[founded - 1].customer);
      }
    };

    const checkCustomerExistOnOpenedTab = (custCheck) => {
      if (openedTabs.some(openedTab => openedTab.customer.id === custCheck.id)) {
        return true;
      }
    };

    const createTab = (tabInd, cust) => {
      openedTabs.push({ tabIndex: tabInd, customer: cust });
      setOpenedTabs(openedTabs);
      setTabIndex(tabInd);
      setActiveTab(tabInd);
      toggleTab("toggle", tabInd, cust);
    };

    const toggleTab = (action, tab, cust) => {
      if (tab === 0) {
        setActiveTab(0);
        return;
      }
      if (checkCustomerExistOnOpenedTab(cust)) {
        const exist = openedTabs.filter(openedTab => openedTab.customer.id === cust.id);
        if (action === "toggle") {
          setActiveTab(exist[0].tabIndex);
          setCustomer(exist[0].customer);
        } else {
          closeTab(exist[0]);
        }
      }
    };

    //delete Customer
    const [deleteModal, setDeleteModal] = useState(false);

    const handleRefresh = () => {
      dispatch(getCustomers());
    };

    const handleNavClick = (e, openedTab) => {
      if (e.target.id.split("-")[0] === ("close")) {
        closeTab(openedTab);
      } else {
        toggleTab("toggle", openedTab.tabIndex, openedTab.customer);
      }
    };
    const handleDeleteCustomer = () => {
      setDeleteModal(false);
      selectedCustomers.forEach(cust => {
          dispatch(deleteCustomer(cust));
          toggleTab("close", null, cust);
        }
      );

      handleRefresh();
      setSelectedCustomers([]);
      setDropDownShown(false);
    };

    const handleCustomerClicks = () => {
      toggle();
    };

    const callBackClose = (val) => {
      setMessage("");
      setShowNotification(val);
      setCallbackClose(false);
    };

    useEffect(() => {
      setActiveTab(activeTab);
    }, [activeTab]);

    useEffect(() => {
      if (selectedCustomers.length === 0) {
        setClearSelected(true);
      } else {
        setClearSelected(false);
      }
    }, [selectedCustomers]);

    const closeAllTabs = () => {
      toggleTab("toggle", 0, null);
      setOpenedTabs([]);
    };

    const handleFilteredCustomerData = (req) => {
      if (req) {
        dispatch(getCustomers());
      } else {
        dispatch(getCustomers());
      }
    };

    function handleClearFilterInputs() {
      setFilterDoctor({});
      setFilterName("");
      setFilterCityName("");
      setFilterPhone("");
      setFilterCreateDateStart("");
      setFilterCreateDateEnd("");
      setFilterUpdateDateStart("");
      setFilterUpdateDateEnd("");
      setFilterUpdateUser("");
      setBody(initialBody);
      handleFilteredCustomerData(initialBody);
    }

    function handleChangeFilterIdentityNumber(e) {
      setFilterIdentityNumber(e.target.value);
      setBody({
        ...body,
        ["identityNumber"]: e.target.value
      });
    }

    const searchBar = (
      <div className="custom-accordion">
        <Link
          className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
          onClick={toggleFilter}
          to="#"
        >
          <i className="mdi mdi-filter font-size-15 text-secondary me-1"></i>{" "}
          {props.t("Filters")}
          <i
            className={
              isOpen
                ? "mdi mdi-chevron-up accor-down-icon ms-2"
                : "mdi mdi-chevron-down accor-down-icon ms-2"
            }
          />
        </Link>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="container col-11 mt-1 d-inline-block align-content-end">
              <div className="row">
                <div className="mb-3 col-xs-12 col-md-3">
                  <Label className="form-label">{props.t("Fullname")}</Label>
                  <Input
                    name="fullname"
                    type="text"
                    value={filterName}
                    onChange={(e) => handleChangeFilterName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Phone")}</Label>
                  <Input
                    name="phone"
                    type="text"
                    value={filterPhone}
                    onChange={(e) => handleChangeFilterPhoneNumber(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("City")}</Label>
                  <Input
                    name="city"
                    type="text"
                    value={filterCityName}
                    onChange={(e) => handleChangeFilterCityName(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Country")}</Label>
                  <Input
                    name="country"
                    type="text"
                    value={filterCountry}
                    onChange={(e) => handleChangeFilterCountry(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col">
                  <Label className="form-label">{props.t("Create Date Start")}</Label>
                  <Input
                    id="start-date"
                    name="start-date"
                    type="date"
                    onChange={e => handleFilterCreateDateStart(e)}
                    value={filterCreateDateStart}
                  />
                </div>
                <div className="mb-3 col">
                  <Label className="form-label">{props.t("Create Date End")}</Label>
                  <Input
                    id="end-date"
                    name="end-date"
                    type="date"
                    onChange={e => handleFilterCreateDateEnd(e)}
                    value={filterCreateDateEnd}
                  />
                </div>
                <div className="mb-3 col">
                  <Label className="form-label">{props.t("Update Date Start")}</Label>
                  <Input
                    id="update-date-start"
                    name="update-date-start"
                    type="date"
                    onChange={e => handleFilterUpdateDateStart(e)}
                    value={filterUpdateDateStart}
                  />
                </div>
                <div className="mb-3 col">
                  <Label className="form-label">{props.t("Update Date End")}</Label>
                  <Input
                    id="update-date-end"
                    name="update-date-end"
                    type="date"
                    onChange={e => handleFilterUpdateDateEnd(e)}
                    value={filterUpdateDateEnd}
                  />
                </div>
              </div>
            </div>
            <div className="col-1 mt-5 d-inline-block align-content-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleFilteredCustomerData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    );

    function handlePreviousPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage - 1 });
      dispatch(customerCriteria(changedBody));
      setClearSelected(true);
    }

    function handleNextPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage + 1 });
      dispatch(customerCriteria(changedBody));
      setClearSelected(true);
    }

    function handleSizePerPage() {
      setSizeButtonDisabled(true);
      setSize(dataPerPage);
      const changedBody = Object.assign(body, { size: dataPerPage, page: 0 });
      dispatch(customerCriteria(changedBody));
      setClearSelected(true);
    }

    return (
      <React.Fragment>
        <WarningModal
          id="deleteModal"
          show={deleteModal}
          onApproveClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div id="container" className="container-fluid">
          <Notification
            type={notificationType}
            message={message}
            show={showNotification}
            callbackShow={callBackClose}
          />
          <Breadcrumbs title={props.t("Customers")} />
          <Row>
            <Card id="custList">
              <CardTitle>
                <Row className="mt-4">
                  <Col lg="10">
                  </Col>
                  <Col lg="2">
                    {openedTabs.length > 0 ? (
                      <Button
                        id="closeTabs"
                        type="button"
                        color="warning"
                        className="btn-rounded btn-sm mt-2"
                        onClick={() => closeAllTabs()}
                      >
                        <i className="mdi mdi-crosshairs me-1" />
                        {props.t("Close All Tabs")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </CardTitle>
              <CardBody>
                <div className="crypto-buy-sell-nav" id="contain">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem key={0}>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0
                        })}
                        onClick={() => {
                          toggleTab("toggle", 0);
                        }}
                      >
                        {props.t("Customers")}
                      </NavLink>
                    </NavItem>
                    {openedTabs.length > 0 ? (
                      openedTabs.map((openedTab, key) => (
                        <NavItem id={`nav-item${key}`} key={key} className="nav-item">
                          <NavLink
                            id={`link-${key}`}
                            className={classnames({
                              active: activeTab === openedTab.tabIndex
                            }, "nav-link")}
                            onClick={(e) => handleNavClick(e, openedTab)}
                          >
                            <span>{`${openedTab.customer.firstName} ${openedTab.customer.lastName}`} </span>
                            <CloseButton
                              id={`close-${key}`}
                              className="btn-xs p-0"
                              onClick={(event) => handleNavClick(event, openedTab)}
                            />
                          </NavLink>
                        </NavItem>
                      ))
                    ) : null}
                  </Nav>
                  <TabContent
                    id="tab-content"
                    activeTab={activeTab}
                    className="p-3"
                  >
                    <TabPane tabId={0} id="custListTab">
                      {searchBar}
                      <React.Fragment>
                        <Col className="col-12 mt-2">
                          {props.t("Total Data Count")} = {totalCount}
                        </Col>
                        <div>
                          <CustomSelectableTable
                            refreshButton={true}
                            handleRefreshClick={handleRefresh}
                            columns={columns}
                            data={customers}
                            isAddOptions={true}
                            handleAddClick={handleCustomerClicks}
                            handleRowClick={handleCustomerClick}
                            customPageSize={size}
                            className="custom-header-css"
                            isExportEnabled={authUser && authUser.role && authUser.role.name === "ROLE_MODERATOR"}
                          />
                          <Row className="col-12 mb-5">
                            <Col className="col-3">
                              <InputGroup>
                                <Input
                                  min={0}
                                  style={{ width: 70 }}
                                  max={totalPages}
                                  value={dataPerPage}
                                  onChange={(e) => setDataPerPage(e.target.value)}
                                />
                                <Button size="sm" className="btn btn-outline-secondary"
                                        disabled={sizeButtonDisabled}
                                        onClick={handleSizePerPage}>{props.t("Set Size")}
                                </Button>
                              </InputGroup>
                            </Col>
                            <Col className="col-8" />
                            <Col className="col-1 align-content-end">
                              <div className="btn-group me-0" role="group">
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === 0}
                                        onClick={() => handlePreviousPage(page)}>
                                  <i className="bx bx-chevron-left"></i>
                                </button>
                                <button type="button" className="btn btn-outline-secondary">
                                  {page + 1}
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === totalPages}
                                        onClick={() => handleNextPage(page)}>
                                  <i className="bx bx-chevron-right"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Modal isOpen={modal} toggle={toggle} size="lg">
                          <ModalHeader toggle={toggle} tag="h4">
                            {props.t("Add")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              id="new-customer"
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                return false;
                              }}
                            >
                              <Row form="true">
                                <Col className="col-12">
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Firstname")}</Label>
                                      <Input
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        onChange={handleChange}
                                        value={customer && customer.firstName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Lastname")}</Label>
                                      <Input
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        onChange={handleChange}
                                        value={customer && customer.lastName || ""}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-2">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Phone")}</Label>
                                      <InputGroup>
                                        <select
                                          id="phoneCode"
                                          className="form-select"
                                          value={phoneCode.value}
                                          onChange={onChangePhoneCode}
                                        >
                                          {currentLanguage === "en" ? (
                                            phoneOptions.map(code => (
                                              <option id={code.value}
                                                      key={code.label}
                                                      value={code.value}>
                                                {code.label}
                                              </option>
                                            ))
                                          ) : (phoneOptionsTurkish.map(code => (
                                            <option id={code.value}
                                                    key={code.label}
                                                    value={code.value}>
                                              {code.label}
                                            </option>
                                          )))}
                                        </select>
                                        <Input
                                          id="phoneNum"
                                          name="phoneNumber"
                                          type="text"
                                          onChange={handleChangePhone}
                                          value={customer && customer.phoneNumber || ""}
                                          required={true}
                                          placeholder="--- --- ----"
                                          invalid={!(customer && customer.phoneNumber)}
                                        />
                                      </InputGroup>
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("E-mail")}</Label>
                                      <Input
                                        name="email"
                                        label="Email"
                                        type="email"
                                        onChange={handleChangeMail}
                                        value={mail}
                                        invalid={(mail.length > 0 && !mailValid)}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Language")}</Label>
                                      <Input
                                        name="language"
                                        label="Language"
                                        type="text"
                                        onChange={handleChange}
                                        value={customer && customer.language || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Gender")}</Label>
                                      <Select
                                        id="genderSelect"
                                        value={gender}
                                        onChange={(e) => {
                                          onChangeInGenderSelect(e);
                                        }}
                                        options={genderOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Row>
                                  <Row className="row-cols-3">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("City")}</Label>
                                      <Input
                                        id="cityName"
                                        name="cityName"
                                        type="text"
                                        onChange={handleChange}
                                        value={customer && customer.cityName || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Country")}</Label>
                                      <Input
                                        id="country"
                                        name="country"
                                        type="text"
                                        onChange={handleChange}
                                        value={customer && customer.country || ""}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Birth Date")}</Label>
                                      <Input
                                        id="birthday"
                                        name="birthday"
                                        type="date"
                                        onChange={handleChange}
                                        value={customer && customer.birthday || ""}
                                      />
                                    </div>
                                  </Row>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Note")}</Label>
                                    <Input
                                      id="note"
                                      name="note"
                                      type="textarea"
                                      rows="1"
                                      onChange={handleChange}
                                      value={customer && customer.note || ""}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      id="save"
                                      type="submit"
                                      className="btn btn-success save-customer"
                                    >
                                      {props.t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </React.Fragment>
                    </TabPane>
                    {openedTabs.map((openedTab, key) =>
                      (
                        <TabPane key={key} tabId={openedTab.tabIndex}
                                 id={`custDetail${openedTab.customer.id}`}>
                          <CustomerDetail
                            id={openedTab.customer.id}
                            customer={openedTab.customer}
                            genderOptions={genderOptions}
                          />
                        </TabPane>
                      ))}
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
  }
;
Customers.propTypes = {
  t: PropTypes.any,
  Customers: PropTypes.array,
  onAddNewCustomer: PropTypes.func,
  onDeleteCustomer: PropTypes.func,
  onUpdateCustomer: PropTypes.func,
  onGetSegments: PropTypes.func,
  segmentId: PropTypes.any
};

export default withRouter(withTranslation()(Customers));
