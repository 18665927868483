import { call, put, takeEvery } from "redux-saga/effects";

// Buildings Redux States
import { ADD_NEW_BUILDING, DELETE_BUILDING, GET_BUILDINGS, UPDATE_BUILDING } from "./actionTypes";

import {
    addBuildingFail,
    addBuildingSuccess,
    deleteBuildingFail,
    deleteBuildingSuccess,
    getBuildingsFail,
    getBuildingsSuccess,
    updateBuildingFail,
    updateBuildingSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchBuildings() {
    try {
        const response = yield call(getBuildings);
        yield put(getBuildingsSuccess(response));
    } catch (error) {
        yield put(getBuildingsFail(error));
    }
}

const getBuildings = () => get(url.BUILDING_GET_ALL)

function* onUpdateBuilding({ payload: building }) {
    try {
        const response = yield call(updateBuilding, building);
        yield put(updateBuildingSuccess(response));
    } catch (error) {
        yield put(updateBuildingFail(error));
    }
}

const updateBuilding = (building) => post(url.BUILDING_API,building)

function* onDeleteBuilding({id}) {
    try {
        const response = yield call(deleteBuilding, id);
        if (response.status === 200){
            yield put(deleteBuildingSuccess(response,id));
        }else{
            yield put(deleteBuildingFail(response));
        }
    } catch (error) {
        yield put(deleteBuildingFail(error));
    }
}

const deleteBuilding = (id) => del(`${url.BUILDING_API}/${id}`);

function* onAddNewBuilding({ payload: building }) {
    try {
        const response = yield call(addNewBuilding, building);

        yield put(addBuildingSuccess(response));
    } catch (error) {
        yield put(addBuildingFail(error));
    }
}

const addNewBuilding = (building) => post(url.BUILDING_API,building);

function* buildingsSaga() {
    yield takeEvery(GET_BUILDINGS, fetchBuildings);
    yield takeEvery(ADD_NEW_BUILDING, onAddNewBuilding);
    yield takeEvery(UPDATE_BUILDING, onUpdateBuilding);
    yield takeEvery(DELETE_BUILDING, onDeleteBuilding);
}

export default buildingsSaga;
