import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Button, Input, Label} from "reactstrap";

// Column
import {withTranslation} from "react-i18next";
import {AppointmentDesc, CreateDate} from "../CustomerCol";
import {formatDate, formatDateScreen, formatTimeScreen, StringToDate} from "../../../common/commonFunctions";
import BasicTable from "../../../components/Common/BasicTable";
import Notification from "../../../components/Notification";
import {useDispatch, useSelector} from "react-redux";
import {addNewCustomerAppointment} from "../../../store/customerAppointment/actions";
import {postWithoutToken} from "../../../helpers/axios_with_headers";

const CustomerAppointment = props => {

    const dispatch = useDispatch();

    const [apptDescription, setApptDescription] = useState("");
    const [selectedAppt, setSelectedAppt] = useState(false);
    const [customerAppointment, setCustomerAppointment] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [customer, setCustomer] = useState({});
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [sectionOptions, setSectionOptions] = useState(props.sectionOptions);
    const [clinicOptions, setClinicOptions] = useState();
    const [selectedSection, setSelectedSection] = useState(null);
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [dateArray, setDateArray] = useState([]);
    const [timeArray, setTimeArray] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("09:00");

    const customerAppointmentBody = {
        size: 100,
        page: 0,
        customer: customer.id,
        status: "APPROVED"
    };

    const appointmentColumns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Date")}`,
                accessor: "appointmentDate",
                filterable: true,
                className: "col-3",
                Cell: (cellProps) => {
                    return <CreateDate {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Date End")}`,
                accessor: "appointmentEndDate",
                filterable: true,
                className: "col-3",
                Cell: (cellProps) => {
                    return <AppointmentDesc {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Description")}`,
                accessor: "description",
                filterable: true,
                className: "col-6",
                Cell: (cellProps) => {
                    return <AppointmentDesc {...cellProps} />;
                }
            }
        ],
        []
    );

    useEffect(() => {
        setForceUpdate(props.forceUpdate);
    }, [props.forceUpdate]);

    useEffect(() => {
        setCustomer(props.customer);
    }, [props.customer]);

    useEffect(() => {
        setSectionOptions(props.sectionOptions);
    }, [props.sectionOptions]);

    const saveAppt = () => {
        dispatch(addNewCustomerAppointment(customerAppointment));
    };

    const {error, success} = useSelector(state => ({
        error: state.CustomerAppointment.error,
        success: state.CustomerAppointment.success
    }));

    useEffect(() => {
        if (Object.entries(error).length > 0) {
            setShowNotification(true);
            setMessage(error.data.message);
            setNotificationType("Danger");
        } else if (success) {
            setShowNotification(true);
            setMessage(props.t("Appointment request has been received."));
            setNotificationType("Success");
        }
    }, [dispatch, error, success]);

    const handleChangeApptDescription = (e) => {
        setApptDescription(e.target.value);
    };

    const callBackClose = (val) => {
        setMessage("");
        setShowNotification(val);
    };

    function handleChange(e) {
        setCustomerAppointment({
            ...customerAppointment,
            [e.target.name]: e.target.value
        })
    }

    return (
        <React.Fragment>
            <Notification
                type={notificationType}
                message={message}
                show={showNotification}
                callbackShow={callBackClose}
            />
            <div className="row mt-3">
                <div className="row">
                    <div className="col-xs-12 col-md-3">
                        <div className=" row row-cols-2">
                            <div className="col-md-6">
                                <Label>{props.t("Appointment Start Date")}</Label>
                                <Input
                                    id="appointmentDate"
                                    name="appointmentDate"
                                    type="datetime-local"
                                    value={customerAppointment && customerAppointment.appointmentDate || ""}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <Label>{props.t("Appointment End Date")}</Label>
                                <Input
                                    id="appointmentEndDate"
                                    name="appointmentEndDate"
                                    type="datetime-local"
                                    value={customerAppointment && customerAppointment.appointmentEndDate || ""}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-12">
                        <label>{props.t("Description")}</label>
                        <Input
                            id="apptDesc"
                            value={apptDescription}
                            onChange={handleChangeApptDescription}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="mt-4 text-end">
                        <Button
                            id="saveAppt"
                            type="button"
                            color="success"
                            onClick={saveAppt}
                            disabled={!selectedAppt}
                        >
                            {props.t("Create")}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                {customerAppointment && customerAppointment.length > 0 ? (
                    <BasicTable
                        id="custAppt"
                        customPageSize={10}
                        columns={appointmentColumns}
                        data={customerAppointment}
                        className="custom-header-basic-css"
                    />) : null}
            </div>
        </React.Fragment>
    );
};
CustomerAppointment.propTypes = {
    doctors: PropTypes.any,
    customer: PropTypes.any,
    sectionOptions: PropTypes.any
};

export default withTranslation()(CustomerAppointment);
