import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import {useDispatch, useSelector} from "react-redux";

// Column
import {withTranslation} from "react-i18next";
import {
    addNewPatientWithAppointment,
    deletePatientAppointment,
    getPatientAppointmentCriteria
} from "../../store/patientAppointment/actions";
import {userCriteria} from "../../store/users/actions";
import WarningModal from "../../components/Common/WarningModal";
import Select from "react-select";
import {DateToString, formatDate, formatTimeScreen, StringToDate} from "../../common/commonFunctions";
import AppointmentDetail from "./appointmentDetail";
import {Type} from "../LeadIntegrationDefinition/leadIntDefCol";
import {ApptDate, Status} from "./appointmentCol";
import TableContainer from "../../components/Common/TableContainer";
import Notification from "../../components/Notification";
import {getWithoutToken, postWithoutToken} from "../../helpers/axios_with_headers";
import withRouter from "../../hooks/withRouter";
import {apptStatusActionClass} from "../../components/Calendar/constant";

const PatientAppointment = props => {

    //meta title
    document.title = `CRM | ${props.t("Patient Appointments")}`;
    const lang = localStorage.getItem("I18N_LANGUAGE").toUpperCase();

    const now = new Date();
    const plus2Month = new Date(now.getFullYear(),
        (now.getMonth() + 2),
        now.getDay());

    const apptStatusOptions = [
        {label: props.t("WAITING"), value: "WAITING"},
        {label: props.t("APPROVED"), value: "APPROVED"},
        {label: props.t("REJECTED"), value: "REJECTED"},
        {label: props.t("CANCELED"), value: "CANCELLED"},
        {label: props.t("COMPLETED"), value: "COMPLETED"}
    ];

    const dispatch = useDispatch();
    const [patientAppointment, setPatientAppointment] = useState({});
    const [apptStatus, setApptStatus] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isApptModalOpen, setIsApptModalOpen] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [showOptions, setShowOptions] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [filterCreateDateStart, setFilterCreateDateStart] = useState(DateToString(now, "YYYY-MM-DD", "-"));
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState(DateToString(plus2Month, "YYYY-MM-DD", "-"));
    const [filterUser, setFilterUser] = useState();
    const [filterPatient, setFilterPatient] = useState("");
    const [filterStatus, setFilterStatus] = useState({label: props.t("WAITING"), value: "WAITING"});
    const [filterPhone, setFilterPhone] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [clinicOptions, setClinicOptions] = useState([]);
    const [doctorOptions, setDoctorOptions] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState();
    const [selectedDoctor, setSelectedDoctor] = useState();
    const [selectedDay, setSelectedDay] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedSection, setSelectedSection] = useState({});
    const [sectionOptions, setSectionOptions] = useState([]);
    const [custAppt, setCustAppt] = useState([]);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [patient, setPatient] = useState({});
    const [dateArray, setDateArray] = useState([]);
    const [timeArray, setTimeArray] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedTime, setSelectedTime] = useState([]);
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");

    function handleFilterCreateDateStart(e) {
        setFilterCreateDateStart(e.target.value);
    }

    function handleFilterCreateDateEnd(e) {
        setFilterCreateDateEnd(e.target.value);
    }

    function handleFilterStatus(e) {
        setFilterStatus(e);
    }

    const handleClearFilterInputs = () => {
        setFilterCreateDateStart("");
        setFilterCreateDateEnd("");
        setFilterUser();
        setFilterPatient();
        setFilterPhone("");
        setFilterStatus();
    };

    const handleOpenModal = () => setIsModalOpen(!isModalOpen);
    const handleOpenApptModal = () => {
        setIsApptModalOpen(!isApptModalOpen);
        setFullName("");
        setPhoneNumber("");
        setSelectedDoctor();
        setSelectedClinic();
        setSelectedDay("");
    };

    const {patientAppointments, error, success} = useSelector(state => ({
        patientAppointments: state.PatientAppointment.patientAppointments,
        error: state.PatientAppointment.error,
        success: state.PatientAppointment.success
    }));

    const handleDelete = () => {
        setDeleteModal(false);
        selecteds.forEach(patientAppointment => dispatch(deletePatientAppointment(patientAppointment.id)));
        setSelecteds([]);
        setShowOptions(false);
    };

    useEffect(() => {
        if (error && Object.entries(error).length > 0 && !success) {
            setMessage(error.data.message);
            setShowNotification(true);
            setNotificationType("Danger");
        } else if (success) {
            setMessage(`${props.t("Success")}`);
            setShowNotification(true);
            setNotificationType("Success");
            isApptModalOpen === true ? handleOpenApptModal() : isModalOpen === true ? handleOpenModal() : null;
        }
    }, [error, success]);

    let body = {
        size: 1000,
        page: 0,
        appointmentDateStart: filterCreateDateStart,
        appointmentDateEnd: filterCreateDateEnd,
        status: "WAITING"
    };

    const patientAppointmentColumns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Appointment Time")}`,
                accessor: "appointmentDate",
                filterable: true,
                Cell: (cellProps) => {
                    return <ApptDate {...cellProps} />;
                }
            }, {
                HeaderLabel: `${props.t("Patient")}`,
                accessor: "patient.fullName",
                filterable: true,
                Cell: (cellProps) => {
                    return <Type {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Status")}`,
                accessor: "status",
                filterable: true,
                Cell: (cellProps) => {
                    const data = cellProps.row.original;
                    return (
                        <Badge
                            key={data.id}
                            pill
                            className={apptStatusActionClass[data.status]}
                        >
                            {props.t(data.status)}
                        </Badge>);
                }
            },
            {
                HeaderLabel: `${props.t("Specialist")}`,
                accessor: "appointmentUser.fullName",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            },
            {
                HeaderLabel: `${props.t("Description")}`,
                accessor: "description",
                filterable: true,
                Cell: (cellProps) => {
                    return (
                        <Type {...cellProps} />
                    );
                }
            }
        ],
        []
    );

    useEffect(() => {
        dispatch(getPatientAppointmentCriteria(body));
    }, []);

    const handleFilteredPatientAppointmentData = () => {
        if (filterCreateDateStart !== "") {
            body = Object.assign(body, {appointmentDateStart: filterCreateDateStart});
        } else {
            delete body.appointmentDateStart;
        }
        if (filterCreateDateEnd !== "") {
            body = Object.assign(body, {appointmentDateEnd: filterCreateDateEnd});
        } else {
            delete body.appointmentDateEnd;
        }
        if (filterStatus && filterStatus.value) {
            body = Object.assign(body, {status: filterStatus.value});
        } else {
            delete body.status;
        }
        if (filterUser && filterUser.value) {
            body = Object.assign(body, {appointmentUser: filterUser.value.id});
        }
        if (filterPatient) {
            body = Object.assign(body, {patientName: filterUser.value});
        }
        if (filterPhone) {
            body = Object.assign(body, {phoneNumber: filterPhone});
        }
        dispatch(getPatientAppointmentCriteria(body));
    };

    function handleChangeInput(e) {
        if (e.target.name === "fullName") {
            setFullName(e.target.value);
        } else if (e.target.name === "phoneNumber") {
            setPhoneNumber(e.target.value);
        }
    }

    function onChangeClinic(event) {
        if (event !== null) {
            setSelectedClinic(event);
            setSelectedDoctor(null);
            setDateArray([]);
            setTimeArray([]);
            setSelectedDate(null);
            setSelectedTime(null);
        } else {
            setSelectedClinic(null);
            setSelectedDoctor(null);
            setDateArray([]);
            setTimeArray([]);
            setSelectedDate(null);
            setSelectedTime(null);
        }
    }

    function onChangeSection(event) {
        if (event !== null) {
            setSelectedSection(event);
            setSelectedClinic(null);
            setSelectedDoctor(null);
            setSelectedDate(null);
            setSelectedTime(null);
        } else {
            setSelectedSection(null);
            setSelectedClinic(null);
            setSelectedDoctor(null);
            setDateArray([]);
            setTimeArray([]);
            setSelectedDate(null);
            setSelectedTime(null);
        }
    }

    function onChangeDoctor(event) {
        if (event !== null) {
            setSelectedDoctor(event);
            getAppointmentDataFromBackend(event.value);
        } else {
            setSelectedDoctor(null);
            setDateArray([]);
            setTimeArray([]);
        }
    }


    useEffect(() => {
        if (selectedSection && selectedSection.value) {
            const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
                label: item.departmentName,
                value: item
            }));
            setClinicOptions(clinicList);
        }
    }, [selectedSection]);

    const getAppointmentDataFromBackend = (id) => {
        const getReq = {
            secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
            doctorId: id
        };

        postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/findAvailableTimes", getReq).then(response => {
            if (response.status === 200 && response.data) {
                setDateArray(response.data.map((event) => ({label: event.date, value: event})));
            }
        });
    };


    useEffect(() => {
        if (selectedSection && selectedSection.value) {
            const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
                label: item.departmentName,
                value: item
            }));
            setClinicOptions(clinicList);
        }
    }, [selectedSection]);

    useEffect(() => {
        getWithoutToken(process.env.REACT_APP_API_URL + "/userGroup/findHighAuthUsersWithDepartment/" +lang).then(response => {
            if (response.status === 200) {
                const sectionOptions = response.data.length > 0 && response.data.map(item => ({
                    label: item.section,
                    value: item
                }));
                setSectionOptions(sectionOptions);
            }
        });
    }, []);

    useEffect(() => {
        const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
            label: doctor.fullName,
            value: doctor.id
        }));
        setDoctorOptions(doctorList || []);
    }, [selectedClinic]);

    useEffect(() => {
        if (selectedSection && selectedSection.value) {
            const clinicList = selectedSection && selectedSection.value.departments && selectedSection.value.departments.map(item => ({
                label: item.departmentName,
                value: item
            }));
            setClinicOptions(clinicList);
        }
    }, [selectedSection]);

    useEffect(() => {
        if (selectedClinic && selectedClinic.value) {
            const doctorList = selectedClinic && selectedClinic.value.users && selectedClinic.value.users.map((doctor) => ({
                label: doctor.fullName,
                value: doctor.id
            }));
            setDoctorOptions(doctorList);
        }
    }, [selectedClinic]);

    useEffect(() => {
        const userReq = Object.assign({notRole: "ROLE_MODERATOR", role: "ROLE_DOCTOR"});
        dispatch(userCriteria(userReq));
    }, []);

    const handleRefresh = () => {
        dispatch(getPatientAppointmentCriteria(body));
    };

    const handleRowClick = row => {
        const patientAppt = row.original;
        setPatientAppointment(patientAppt);
        patientAppointment.status ? setApptStatus({
            label: patientAppointment.status,
            value: patientAppointment.status
        }) : null;

        handleOpenModal();
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const saveAppointment = () => {
        const req = {
            secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
            fullName: fullName,
            phoneNumber: phoneNumber,
            appointmentDate: formatDate(StringToDate(date + " " + time, "DDMMYYYY", "-")),
            appointmentUserId: selectedDoctor && selectedDoctor.value && selectedDoctor.value.id
        };
        dispatch(addNewPatientWithAppointment(req));
    };


    function onChangeDate(e) {
        setSelectedDate(e);
        setDate(e && e.value ? e.value.date : null);
        setSelectedTime(null);
        const timeOptions = e && e.value && e.value.hours.map((hour) => ({label: formatTimeScreen(hour), value: hour}));
        setTimeArray(timeOptions);
    }

    function onChangeTime(e) {
        setSelectedTime(e);
        setTime(e && e.value ? e.value : null);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Notification
                        message={message}
                        show={showNotification}
                        callbackShow={handleClose}
                        type={notificationType}
                    />
                    <WarningModal
                        id="deleteModal"
                        show={deleteModal}
                        onApproveClick={handleDelete}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <Modal isOpen={isModalOpen} toggle={handleOpenModal} centered={true} size="md" backdrop="static">
                        <ModalHeader toggle={handleOpenModal} tag="h4">{props.t("Appointment Detail")}</ModalHeader>
                        <ModalBody>
                            {patientAppointment ? (
                                <AppointmentDetail
                                    t={props.t}
                                    detailObject={patientAppointment}
                                    callback={handleOpenModal}
                                />
                            ) : null}
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={isApptModalOpen} toggle={handleOpenApptModal} centered={true} size="md"
                           backdrop="static">
                        <ModalHeader toggle={handleOpenApptModal} tag="h4">{props.t("New Appointment")}</ModalHeader>
                        <ModalBody>
                            <Row className="m-2">
                                <Col className="col-12">
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Name")}</Label>
                                        <Input
                                            id="fullName"
                                            name="fullName"
                                            type="text"
                                            onChange={handleChangeInput}
                                            value={fullName || ""}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Phone")}</Label>
                                        <Input
                                            id="phoneNum"
                                            name="phoneNumber"
                                            type="text"
                                            onChange={handleChangeInput}
                                            value={phoneNumber || ""}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Section")}</Label>
                                        <Select
                                            value={selectedSection}
                                            onChange={(event) => onChangeSection(event)}
                                            options={sectionOptions}
                                            className="bs-select"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Health Office")}</Label>
                                        <Select
                                            value={selectedClinic}
                                            onChange={(event) => onChangeClinic(event)}
                                            options={clinicOptions}
                                            className="bs-select"
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <Label className="form-label">{props.t("Specialist")}</Label>
                                        <Select
                                            value={selectedDoctor}
                                            onChange={(event) => onChangeDoctor(event)}
                                            options={doctorOptions}
                                            className="bs-select"
                                            isClearable={true}
                                        />
                                    </div>
                                    <Col className="col-12 mb-3">
                                        <div className="row row-cols-2">
                                            <div className="col-md-6">
                                                <Label>{props.t("Date")}</Label>
                                                <Select
                                                    id="date"
                                                    className="bs-select"
                                                    value={selectedDate}
                                                    onChange={(e) => onChangeDate(e)}
                                                    options={dateArray}
                                                    isClearable={true}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Label>{props.t("Time")}</Label>
                                                <Select
                                                    id="representHistory"
                                                    className="bs-select"
                                                    value={selectedTime}
                                                    onChange={(e) => onChangeTime(e)}
                                                    options={timeArray}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                                <Row className="m-2">
                                    <Col>
                                        <div className="text-end mt-2">
                                            <button
                                                // disabled={disableButton || sendCodeButtonDisabled}
                                                id="save"
                                                type="submit"
                                                className="btn btn-success"
                                                onClick={() => saveAppointment()}
                                            >
                                                {"Save"}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Row>
                        </ModalBody>
                    </Modal>
                    <Breadcrumbs title={props.t("Patient Appointments")}/>
                    <Row>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Start Date")}</Label>
                                        <Input
                                            id="create-date-start"
                                            name="create-date-start"
                                            type="date"
                                            onChange={e => handleFilterCreateDateStart(e)}
                                            value={filterCreateDateStart}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("End Date")}</Label>
                                        <Input
                                            id="create-date-end"
                                            name="create-date-end"
                                            type="date"
                                            onChange={e => handleFilterCreateDateEnd(e)}
                                            value={filterCreateDateEnd}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Status")}</Label>
                                        <Select
                                            id="Status-user"
                                            name="status"
                                            onChange={e => handleFilterStatus(e)}
                                            value={filterStatus}
                                            options={apptStatusOptions}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mt-4 col text-end">
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleFilteredPatientAppointmentData()}
                                        >
                                            <i className="mdi mdi-search-web me-1"/>
                                            {props.t("Filter")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="warning"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleClearFilterInputs()}
                                        >
                                            <i className="mdi mdi-format-clear me-1"/>
                                            {props.t("Clear All Filters")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded m-0"
                                            onClick={handleRefresh}
                                        >
                                            <i className="mdi mdi-refresh"/>
                                        </Button>
                                        {showOptions ? (
                                            <Button
                                                id="delete"
                                                type="button"
                                                color="danger"
                                                className="btn-rounded mb-2 me-2"
                                                onClick={() => setDeleteModal(true)}
                                            >
                                                <i className="mdi mdi-delete me-1"/>
                                                {props.t("Delete")}
                                            </Button>
                                        ) : ""}
                                    </div>
                                </div>
                                <hr/>
                                {patientAppointments && (
                                    <TableContainer
                                        refreshButton={true}
                                        handleRefreshClick={handleRefresh}
                                        columns={patientAppointmentColumns}
                                        data={patientAppointments}
                                        handleRowClick={handleRowClick}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        handleAddClick={handleOpenApptModal}
                                        customPageSize={50}
                                        className="custom-header-css"
                                    />)}
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
PatientAppointment.propTypes = {
    t: PropTypes.any,
    patientAppointments: PropTypes.array,
    getPatientAppointments: PropTypes.func
};

export default withRouter(withTranslation()(PatientAppointment));
