import {
  ADD_NEW_CUSTOMER_WITH_APPT_FAIL,
  ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS,
  ADD_CUSTOMER_APPOINTMENT_FAIL,
  ADD_CUSTOMER_APPOINTMENT_SUCCESS,
  DELETE_CUSTOMER_APPOINTMENT_FAIL,
  DELETE_CUSTOMER_APPOINTMENT_SUCCESS,
  GET_LEAD_CUSTOMER_APPOINTMENTS_FAIL,
  GET_LEAD_CUSTOMER_APPOINTMENTS_SUCCESS,
  GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL,
  GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS,
  GET_CUSTOMER_APPOINTMENTS_FAIL,
  GET_CUSTOMER_APPOINTMENTS_SUCCESS,
  GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL,
  GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS,
  GET_USER_CUSTOMER_APPOINTMENTS_FAIL,
  GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS,
  UPDATE_CUSTOMER_APPOINTMENT_FAIL,
  UPDATE_CUSTOMER_APPOINTMENT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
  customerAppointments: [],
  error: {},
  success: false
};

const CustomerAppointment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        customerAppointments: action.payload
      };

    case GET_CUSTOMER_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_LEAD_CUSTOMER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        customerAppointments: action.payload
      };

    case GET_LEAD_CUSTOMER_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        customerAppointments: action.payload
      };

    case GET_USER_CUSTOMER_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_CUSTOMER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        customerAppointments: [...state.customerAppointments, action.payload],
        success: true
      };

    case ADD_CUSTOMER_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case UPDATE_CUSTOMER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        success: true,
        customerAppointments: state.customerAppointments.map(customerAppointment =>
          customerAppointment.id.toString() === action.payload.id.toString()
            ? action.payload
            : customerAppointment
        )
      };

    case UPDATE_CUSTOMER_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case DELETE_CUSTOMER_APPOINTMENT_SUCCESS:
      return {
        ...state,
        success: true,
        customerAppointments: state.customerAppointments.filter(
          customerAppointment => customerAppointment.id.toString() !== action.req.toString()
        )
      };

    case DELETE_CUSTOMER_APPOINTMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        customerAppointments: action.payload
      };

    case GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS:
      return {
        ...state,
        customerAppointments: action.payload.content
      };

    case GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS:
      return {
        ...state,
        customerAppointments: [action.payload, ...state.customerAppointments],
        success: true
      };

    case ADD_NEW_CUSTOMER_WITH_APPT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
        success: false,
        error: {}
      };
  }
};

export default CustomerAppointment;
