import {call, put, takeEvery} from "redux-saga/effects";

// Apartments Redux States
import {
    ADD_NEW_APARTMENT,
    APARTMENT_CRITERIA,
    APARTMENT_FIND_FILTERS,
    DELETE_APARTMENT,
    GET_APARTMENTS,
    UPDATE_APARTMENT
} from "./actionTypes";

import {
    addApartmentFail,
    addApartmentSuccess,
    apartmentCriteriaFail,
    apartmentCriteriaSuccess, apartmentFindFiltersFail, apartmentFindFiltersSuccess,
    deleteApartmentFail,
    deleteApartmentSuccess,
    getApartmentsFail,
    getApartmentsSuccess,
    updateApartmentFail,
    updateApartmentSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchApartments() {
    try {
        const response = yield call(getApartments);
        yield put(getApartmentsSuccess(response));
    } catch (error) {
        yield put(getApartmentsFail(error));
    }
}

const getApartments = () => get(url.APARTMENT_GET_ALL)

function* onUpdateApartment({ payload: apartment }) {
    try {
        const response = yield call(updateApartment, apartment);
        yield put(updateApartmentSuccess(response));
    } catch (error) {
        yield put(updateApartmentFail(error));
    }
}

const updateApartment = (apartment) => post(url.APARTMENT_API,apartment)

function* onDeleteApartment({id}) {
    try {
        const response = yield call(deleteApartment, id);
        if (response.status === 200){
            yield put(deleteApartmentSuccess(response,id));
        }else{
            yield put(deleteApartmentFail(response));
        }
    } catch (error) {
        yield put(deleteApartmentFail(error));
    }
}

const deleteApartment = (id) => del(`${url.APARTMENT_API}/${id}`);

function* onAddNewApartment({ payload: apartment }) {
    try {
        const response = yield call(addNewApartment, apartment);

        yield put(addApartmentSuccess(response));
    } catch (error) {
        yield put(addApartmentFail(error));
    }
}

const addNewApartment = (apartment) => post(url.APARTMENT_API,apartment);

function* fetchApartmentCriteria({ payload: req }) {

    try {
        const response = yield call(getApartmentCriteria, req);
        yield put(apartmentCriteriaSuccess(response, req));
    } catch (error) {
        yield put(apartmentCriteriaFail(error));
    }
}

const getApartmentCriteria = (req) => post(url.APARTMENT_CRITERIA, req);

function* fetchApartmentFilters() {

    try {
        const response = yield call(getApartmentFilters);
        yield put(apartmentFindFiltersSuccess(response));
    } catch (error) {
        yield put(apartmentFindFiltersFail(error));
    }
}

const getApartmentFilters = () => get(url.APARTMENT_FIND_FILTERS);

function* apartmentsSaga() {
    yield takeEvery(GET_APARTMENTS, fetchApartments);
    yield takeEvery(APARTMENT_CRITERIA, fetchApartmentCriteria);
    yield takeEvery(ADD_NEW_APARTMENT, onAddNewApartment);
    yield takeEvery(UPDATE_APARTMENT, onUpdateApartment);
    yield takeEvery(DELETE_APARTMENT, onDeleteApartment);
    yield takeEvery(APARTMENT_FIND_FILTERS, fetchApartmentFilters);
}

export default apartmentsSaga;
