
/* APARTMENT_ROOMS */
export const GET_APARTMENT_ROOMS = "GET_APARTMENT_ROOMS"
export const GET_APARTMENT_ROOMS_SUCCESS = "GET_APARTMENT_ROOMS_SUCCESS"
export const GET_APARTMENT_ROOMS_FAIL = "GET_APARTMENT_ROOMS_FAIL"

/**
 * add APARTMENT_ROOM
 */
export const ADD_NEW_APARTMENT_ROOM = "ADD_NEW_APARTMENT_ROOM"
export const ADD_APARTMENT_ROOM_SUCCESS = "ADD_APARTMENT_ROOM_SUCCESS"
export const ADD_APARTMENT_ROOM_FAIL = "ADD_APARTMENT_ROOM_FAIL"

/**
 * Edit APARTMENT_ROOM
 */
export const UPDATE_APARTMENT_ROOM = "UPDATE_APARTMENT_ROOM"
export const UPDATE_APARTMENT_ROOM_SUCCESS = "UPDATE_APARTMENT_ROOM_SUCCESS"
export const UPDATE_APARTMENT_ROOM_FAIL = "UPDATE_APARTMENT_ROOM_FAIL"

/**
 * Delete APARTMENT_ROOM
 */
export const DELETE_APARTMENT_ROOM = "DELETE_APARTMENT_ROOM"
export const DELETE_APARTMENT_ROOM_SUCCESS = "DELETE_APARTMENT_ROOM_SUCCESS"
export const DELETE_APARTMENT_ROOM_FAIL = "DELETE_APARTMENT_ROOM_FAIL"

/**
 * Lead Criteria
 */
export const APARTMENT_ROOM_CRITERIA = "APARTMENT_ROOM_CRITERIA"
export const APARTMENT_ROOM_CRITERIA_SUCCESS = "APARTMENT_ROOM_CRITERIA_SUCCESS"
export const APARTMENT_ROOM_CRITERIA_FAIL = "APARTMENT_ROOM_CRITERIA_FAIL"
