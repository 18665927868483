
/* REAL_ESTATE_PROJECTS */
export const GET_REAL_ESTATE_PROJECTS = "GET_REAL_ESTATE_PROJECTS"
export const GET_REAL_ESTATE_PROJECTS_SUCCESS = "GET_REAL_ESTATE_PROJECTS_SUCCESS"
export const GET_REAL_ESTATE_PROJECTS_FAIL = "GET_REAL_ESTATE_PROJECTS_FAIL"

/**
 * add REAL_ESTATE_PROJECT
 */
export const ADD_NEW_REAL_ESTATE_PROJECT = "ADD_NEW_REAL_ESTATE_PROJECT"
export const ADD_REAL_ESTATE_PROJECT_SUCCESS = "ADD_REAL_ESTATE_PROJECT_SUCCESS"
export const ADD_REAL_ESTATE_PROJECT_FAIL = "ADD_REAL_ESTATE_PROJECT_FAIL"

/**
 * Edit REAL_ESTATE_PROJECT
 */
export const UPDATE_REAL_ESTATE_PROJECT = "UPDATE_REAL_ESTATE_PROJECT"
export const UPDATE_REAL_ESTATE_PROJECT_SUCCESS = "UPDATE_REAL_ESTATE_PROJECT_SUCCESS"
export const UPDATE_REAL_ESTATE_PROJECT_FAIL = "UPDATE_REAL_ESTATE_PROJECT_FAIL"

/**
 * Delete REAL_ESTATE_PROJECT
 */
export const DELETE_REAL_ESTATE_PROJECT = "DELETE_REAL_ESTATE_PROJECT"
export const DELETE_REAL_ESTATE_PROJECT_SUCCESS = "DELETE_REAL_ESTATE_PROJECT_SUCCESS"
export const DELETE_REAL_ESTATE_PROJECT_FAIL = "DELETE_REAL_ESTATE_PROJECT_FAIL"

/**
 * Lead Criteria
 */
export const REAL_ESTATE_PROJECT_CRITERIA = "REAL_ESTATE_PROJECT_CRITERIA"
export const REAL_ESTATE_PROJECT_CRITERIA_SUCCESS = "REAL_ESTATE_PROJECT_CRITERIA_SUCCESS"
export const REAL_ESTATE_PROJECT_CRITERIA_FAIL = "REAL_ESTATE_PROJECT_CRITERIA_FAIL"
