import {
    ADD_APARTMENT_DIRECTION_FAIL,
    ADD_APARTMENT_DIRECTION_SUCCESS,
    APARTMENT_DIRECTION_CRITERIA_FAIL,
    APARTMENT_DIRECTION_CRITERIA_SUCCESS,
    DELETE_APARTMENT_DIRECTION_FAIL,
    DELETE_APARTMENT_DIRECTION_SUCCESS,
    GET_APARTMENT_DIRECTIONS_FAIL,
    GET_APARTMENT_DIRECTIONS_SUCCESS,
    UPDATE_APARTMENT_DIRECTION_FAIL,
    UPDATE_APARTMENT_DIRECTION_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    apartmentDirections: [],
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const ApartmentDirection = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APARTMENT_DIRECTIONS_SUCCESS:
            return {
                ...state,
                apartmentDirections: action.payload,
            };

        case GET_APARTMENT_DIRECTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_APARTMENT_DIRECTION_SUCCESS:
            return {
                ...state,
                apartmentDirections: [...state.apartmentDirections, action.payload],
            };

        case ADD_APARTMENT_DIRECTION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_APARTMENT_DIRECTION_SUCCESS:
            return {
                ...state,
                apartmentDirections: state.apartmentDirections.map(apartmentDirection =>
                    apartmentDirection.id.toString() === action.payload.id.toString()
                        ? { apartmentDirection, ...action.payload }
                        : apartmentDirection
                ),
            };

        case UPDATE_APARTMENT_DIRECTION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_APARTMENT_DIRECTION_SUCCESS:
            return {
                ...state,
                apartmentDirections: state.apartmentDirections.filter(
                    apartmentDirection => apartmentDirection.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_APARTMENT_DIRECTION_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case APARTMENT_DIRECTION_CRITERIA_SUCCESS:
            return {
                ...state,
                apartmentDirections: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case APARTMENT_DIRECTION_CRITERIA_FAIL:
            return {
                ...state,
                apartmentDirections: action.payload.data
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default ApartmentDirection;
