import {call, put, takeEvery} from "redux-saga/effects";

// ApartmentRooms Redux States
import {ADD_NEW_APARTMENT_ROOM, APARTMENT_ROOM_CRITERIA, DELETE_APARTMENT_ROOM, GET_APARTMENT_ROOMS, UPDATE_APARTMENT_ROOM} from "./actionTypes";

import {
    addApartmentRoomFail,
    addApartmentRoomSuccess,
    deleteApartmentRoomFail,
    deleteApartmentRoomSuccess,
    getApartmentRoomsFail,
    getApartmentRoomsSuccess,
    updateApartmentRoomFail,
    updateApartmentRoomSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchApartmentRooms() {
    try {
        const response = yield call(getApartmentRooms);
        yield put(getApartmentRoomsSuccess(response));
    } catch (error) {
        yield put(getApartmentRoomsFail(error));
    }
}

const getApartmentRooms = () => get(url.APARTMENT_ROOM_GET_ALL)

function* onUpdateApartmentRoom({ payload: apartmentRoom }) {
    try {
        const response = yield call(updateApartmentRoom, apartmentRoom);
        yield put(updateApartmentRoomSuccess(response));
    } catch (error) {
        yield put(updateApartmentRoomFail(error));
    }
}

const updateApartmentRoom = (apartmentRoom) => post(url.APARTMENT_ROOM_API,apartmentRoom)

function* onDeleteApartmentRoom({id}) {
    try {
        const response = yield call(deleteApartmentRoom, id);
        if (response.status === 200){
            yield put(deleteApartmentRoomSuccess(response,id));
        }else{
            yield put(deleteApartmentRoomFail(response));
        }
    } catch (error) {
        yield put(deleteApartmentRoomFail(error));
    }
}

const deleteApartmentRoom = (id) => del(`${url.APARTMENT_ROOM_API}/${id}`);

function* onAddNewApartmentRoom({ payload: apartmentRoom }) {
    try {
        const response = yield call(addNewApartmentRoom, apartmentRoom);

        yield put(addApartmentRoomSuccess(response));
    } catch (error) {
        yield put(addApartmentRoomFail(error));
    }
}

const addNewApartmentRoom = (apartmentRoom) => post(url.APARTMENT_ROOM_API,apartmentRoom);

function* apartmentRoomsSaga() {
    yield takeEvery(GET_APARTMENT_ROOMS, fetchApartmentRooms);
    yield takeEvery(ADD_NEW_APARTMENT_ROOM, onAddNewApartmentRoom);
    yield takeEvery(UPDATE_APARTMENT_ROOM, onUpdateApartmentRoom);
    yield takeEvery(DELETE_APARTMENT_ROOM, onDeleteApartmentRoom);
}

export default apartmentRoomsSaga;
