import {call, put, takeEvery} from "redux-saga/effects";

// ApartmentDirections Redux States
import {ADD_NEW_APARTMENT_DIRECTION, APARTMENT_DIRECTION_CRITERIA, DELETE_APARTMENT_DIRECTION, GET_APARTMENT_DIRECTIONS, UPDATE_APARTMENT_DIRECTION} from "./actionTypes";

import {
    addApartmentDirectionFail,
    addApartmentDirectionSuccess,
    deleteApartmentDirectionFail,
    deleteApartmentDirectionSuccess,
    getApartmentDirectionsFail,
    getApartmentDirectionsSuccess,
    updateApartmentDirectionFail,
    updateApartmentDirectionSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchApartmentDirections() {
    try {
        const response = yield call(getApartmentDirections);
        yield put(getApartmentDirectionsSuccess(response));
    } catch (error) {
        yield put(getApartmentDirectionsFail(error));
    }
}

const getApartmentDirections = () => get(url.APARTMENT_DIRECTION_GET_ALL)

function* onUpdateApartmentDirection({ payload: apartmentDirection }) {
    try {
        const response = yield call(updateApartmentDirection, apartmentDirection);
        yield put(updateApartmentDirectionSuccess(response));
    } catch (error) {
        yield put(updateApartmentDirectionFail(error));
    }
}

const updateApartmentDirection = (apartmentDirection) => post(url.APARTMENT_DIRECTION_API,apartmentDirection)

function* onDeleteApartmentDirection({id}) {
    try {
        const response = yield call(deleteApartmentDirection, id);
        if (response.status === 200){
            yield put(deleteApartmentDirectionSuccess(response,id));
        }else{
            yield put(deleteApartmentDirectionFail(response));
        }
    } catch (error) {
        yield put(deleteApartmentDirectionFail(error));
    }
}

const deleteApartmentDirection = (id) => del(`${url.APARTMENT_DIRECTION_API}/${id}`);

function* onAddNewApartmentDirection({ payload: apartmentDirection }) {
    try {
        const response = yield call(addNewApartmentDirection, apartmentDirection);

        yield put(addApartmentDirectionSuccess(response));
    } catch (error) {
        yield put(addApartmentDirectionFail(error));
    }
}

const addNewApartmentDirection = (apartmentDirection) => post(url.APARTMENT_DIRECTION_API,apartmentDirection);

function* apartmentDirectionsSaga() {
    yield takeEvery(GET_APARTMENT_DIRECTIONS, fetchApartmentDirections);
    yield takeEvery(ADD_NEW_APARTMENT_DIRECTION, onAddNewApartmentDirection);
    yield takeEvery(UPDATE_APARTMENT_DIRECTION, onUpdateApartmentDirection);
    yield takeEvery(DELETE_APARTMENT_DIRECTION, onDeleteApartmentDirection);
}

export default apartmentDirectionsSaga;
