import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import {
  addNewApartmentReservation,
  deleteApartmentReservation,
  getApartmentReservations
} from "../../store/apartmentReservation/actions";
import { userCriteria } from "../../store/users/actions";
import WarningModal from "../../components/Common/WarningModal";
import Select from "react-select";
import { DateToString } from "../../common/commonFunctions";
import AppointmentDetail from "./appointmentDetail";
import { Type } from "../LeadIntegrationDefinition/leadIntDefCol";
import { ApptDate } from "./appointmentCol";
import TableContainer from "../../components/Common/TableContainer";
import Notification from "../../components/Notification";
import withRouter from "../../hooks/withRouter";
import { getApartments } from "../../store/apartment/actions";
import { Name } from "../Users/UserList/userlistCol";
import { City } from "../Apartment/ApartmentCol";
import { getCustomers } from "../../store/customer/actions";

const ApartmentReservation = props => {

  //meta title
  document.title = `CRM | ${props.t("Apartment Reservations")}`;

  const now = new Date();
  const plus2Month = new Date(now.getFullYear(),
    (now.getMonth() + 2),
    now.getDay());

  const apptStatusOptions = [
    { label: props.t("WAITING"), value: "WAITING" },
    { label: props.t("APPROVED"), value: "APPROVED" },
    { label: props.t("REJECTED"), value: "REJECTED" },
    { label: props.t("CANCELED"), value: "CANCELLED" },
    { label: props.t("COMPLETED"), value: "COMPLETED" }
  ];

  const initialApartmentReservation = {
    startDate: "",
    endDate: "",
    description: "",
    status: "",
    customer: {},
    apartment: {}
  };

  const dispatch = useDispatch();
  const [apartmentReservation, setApartmentReservation] = useState(initialApartmentReservation);
  const [apptStatus, setApptStatus] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isApptModalOpen, setIsApptModalOpen] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [filterCreateDateStart, setFilterCreateDateStart] = useState(DateToString(now, "YYYY-MM-DD", "-"));
  const [filterCreateDateEnd, setFilterCreateDateEnd] = useState(DateToString(plus2Month, "YYYY-MM-DD", "-"));
  const [filterUser, setFilterUser] = useState();
  const [filterPatient, setFilterPatient] = useState("");
  const [filterStatus, setFilterStatus] = useState({ label: props.t("WAITING"), value: "WAITING" });
  const [filterPhone, setFilterPhone] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [apartmentOptions, setApartmentOptions] = useState("");
  const [customerOptions, setCustomerOptions] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [mappedApartmentReservations, setMappedApartmentReservations] = useState([]);


  function handleFilterCreateDateStart(e) {
    setFilterCreateDateStart(e.target.value);
  }

  function handleFilterCreateDateEnd(e) {
    setFilterCreateDateEnd(e.target.value);
  }

  function handleFilterStatus(e) {
    setFilterStatus(e);
  }

  const handleClearFilterInputs = () => {
    setFilterCreateDateStart("");
    setFilterCreateDateEnd("");
    setFilterUser();
    setFilterPatient();
    setFilterPhone("");
    setFilterStatus();
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  const handleOpenModal = () => setIsModalOpen(!isModalOpen);
  const handleOpenApptModal = () => {
    setIsApptModalOpen(!isApptModalOpen);
  };

  const { apartmentReservations, error, success } = useSelector(state => ({
    apartmentReservations: state.ApartmentReservation.apartmentReservations,
    error: state.ApartmentReservation.error,
    success: state.ApartmentReservation.success
  }));

  const handleDelete = () => {
    setDeleteModal(false);
    selecteds.forEach(apartmentReservation => dispatch(deleteApartmentReservation(apartmentReservation.id)));
    setSelecteds([]);
    setShowOptions(false);
  };

  const { apartments, userError } = useSelector(state => ({
    apartments: state.Apartment.apartments,
    userError: state.Apartment.error
  }));

  useEffect(() => {
    dispatch(getApartments());
  }, []);

  useEffect(() => {
    setApartmentOptions(apartments.map(item => {
      return { label: `${item.description}-${item.building.buildingName}`, value: item };
    }));
  }, [apartments]);

  useEffect(() => {
    if (error && Object.entries(error).length > 0 && !success) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    } else if (success) {
      setMessage(`${props.t("Success")}`);
      setShowNotification(true);
      setNotificationType("Success");
      isApptModalOpen === true ? handleOpenApptModal() : isModalOpen === true ? handleOpenModal() : null;
    }
  }, [error, success]);

  let body = {
    size: 1000,
    page: 0,
    appointmentDateStart: filterCreateDateStart,
    appointmentDateEnd: filterCreateDateEnd,
    status: "WAITING"
  };

  const apartmentReservationColumns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Reservation Date Start")}`,
        accessor: "startDate",
        filterable: true,
        Cell: (cellProps) => {
          return <ApptDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Reservation Date End")}`,
        accessor: "endDate",
        filterable: true,
        Cell: (cellProps) => {
          return <ApptDate {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Project")}`,
        accessor: "apartment.building.project.name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Building Name")}`,
        accessor: "apartment.building.blockName",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Number Of Rooms")}`,
        accessor: "apartment.numberOfRooms.numberOfRoom",
        Cell: (cellProps) => {
          return <City {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Customer")}`,
        accessor: "customer.fullName",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <Type {...cellProps} />
          );
        }
      },
      {
        HeaderLabel: `${props.t("Customer Consultant")}`,
        accessor: "user.fullName",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <Type {...cellProps} />
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    dispatch(getApartmentReservations());
  }, []);

  const { customers, custError } = useSelector(state => ({
    customers: state.Customer.customers,
    custError: state.Customer.error
  }));

  useEffect(() => {
    setCustomerOptions(customers.map(item => {
      return { label: item.fullName, value: item };
    }));
  }, [customers]);

  const handleFilteredApartmentReservationData = () => {
    if (filterCreateDateStart !== "") {
      body = Object.assign(body, { appointmentDateStart: filterCreateDateStart });
    } else {
      delete body.appointmentDateStart;
    }
    if (filterCreateDateEnd !== "") {
      body = Object.assign(body, { appointmentDateEnd: filterCreateDateEnd });
    } else {
      delete body.appointmentDateEnd;
    }
    if (filterStatus && filterStatus.value) {
      body = Object.assign(body, { status: filterStatus.value });
    } else {
      delete body.status;
    }
    if (filterUser && filterUser.value) {
      body = Object.assign(body, { appointmentUser: filterUser.value.id });
    }
    if (filterPatient) {
      body = Object.assign(body, { patientName: filterUser.value });
    }
    if (filterPhone) {
      body = Object.assign(body, { phoneNumber: filterPhone });
    }
    dispatch(getApartmentReservations());
  };

  function handleChangeInput(e) {
    setApartmentReservation({
      ...apartmentReservation,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    const userReq = Object.assign({ page: 0 });
    dispatch(userCriteria(userReq));
  }, []);

  const { users } = useSelector(state => ({
    users: state.User.users
  }));

  useEffect(() => {
    const mapped = apartmentReservations && apartmentReservations.map((item) => {
      return {
        ...item,
        user: users && users.filter((user) => user.id === item.createUserId)[0]
      };
    });
    setMappedApartmentReservations(mapped);
  }, [apartmentReservations, users]);

  const handleRefresh = () => {
    dispatch(getApartmentReservations());
  };

  const handleRowClick = row => {
    const patientAppt = row.original;
    setApartmentReservation(patientAppt);
    apartmentReservation.status ? setApptStatus({
      label: apartmentReservation.status,
      value: apartmentReservation.status
    }) : null;

    handleOpenModal();
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveAppointment = () => {
    const req = {
      startDate: apartmentReservation.startDate.replaceAll("T", " ") + ":00",
      endDate: apartmentReservation.endDate.replaceAll("T", " ") + ":00",
      description: apartmentReservation.description,
      customer: apartmentReservation.customer,
      apartment: apartmentReservation.apartment
    };
    dispatch(addNewApartmentReservation(req));
  };

  function onChangeCustomer(event) {
    setSelectedCustomer(event);
    setApartmentReservation({
      ...apartmentReservation,
      customer: event.value
    });
  }

  function onChangeApartment(event) {
    setSelectedApartment(event);
    setApartmentReservation({
      ...apartmentReservation,
      apartment: event.value
    });
  }

  function onChangeStatus(event) {
    setApptStatus(event);
    setApartmentReservation({
      ...apartmentReservation,
      status: event.value
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("Apartment Reservations")} />
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <WarningModal
            id="deleteModal"
            show={deleteModal}
            onApproveClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Modal isOpen={isModalOpen} toggle={handleOpenModal} centered={true} size="md" backdrop="static">
            <ModalHeader toggle={handleOpenModal} tag="h4">{props.t("Appointment Detail")}</ModalHeader>
            <ModalBody>
              {apartmentReservation ? (
                <AppointmentDetail
                  t={props.t}
                  detailObject={apartmentReservation}
                  callback={handleOpenModal}
                />
              ) : null}
            </ModalBody>
          </Modal>
          <Modal isOpen={isApptModalOpen} toggle={handleOpenApptModal} centered={true} size="md"
                 backdrop="static">
            <ModalHeader toggle={handleOpenApptModal} tag="h4">{props.t("New Appointment")}</ModalHeader>
            <ModalBody>
              <Row className="m-2">
                <Col className="col-12">
                  <div className="mb-2">
                    <Label className="form-label">{props.t("Customer")}</Label>
                    <Select
                      value={selectedCustomer}
                      onChange={(event) => onChangeCustomer(event)}
                      options={customerOptions}
                      className="bs-select"
                      isClearable={true}
                    />
                  </div>
                  <div className="mb-2">
                    <Label className="form-label">{props.t("Apartment")}</Label>
                    <Select
                      value={selectedApartment}
                      onChange={(event) => onChangeApartment(event)}
                      options={apartmentOptions}
                      className="bs-select"
                      isClearable={true}
                    />
                  </div>
                  {/*
                                     <div className="mb-2">
                                        <Label className="form-label">{props.t("Status")}</Label>
                                        <Select
                                            id="Status-user"
                                            name="status"
                                            onChange={e => onChangeStatus(e)}
                                            value={apptStatus}
                                            options={apptStatusOptions}
                                            isClearable={true}
                                        />
                                    </div>
                                    */}
                  <Col className="col-12 mb-3">
                    <div className="row row-cols-2">
                      <div className="col-md-6">
                        <Label>{props.t("Start Date")}</Label>
                        <Input
                          id="startDate"
                          name="startDate"
                          type="datetime-local"
                          onChange={handleChangeInput}
                          value={apartmentReservation && apartmentReservation.startDate || ""}
                        />
                      </div>
                      <div className="col-md-6">
                        <Label>{props.t("End Date")}</Label>
                        <Input
                          id="endDate"
                          name="endDate"
                          type="datetime-local"
                          onChange={handleChangeInput}
                          value={apartmentReservation && apartmentReservation.endDate || ""}
                        />
                      </div>
                    </div>
                  </Col>
                </Col>
                <Row className="m-2">
                  <Col>
                    <div className="text-end mt-2">
                      <button
                        id="save"
                        type="submit"
                        className="btn btn-success"
                        onClick={() => saveAppointment()}
                      >
                        {props.t("Save")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Row>
            </ModalBody>
          </Modal>
          {/*
                           <div className="row">
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Start Date")}</Label>
                                        <Input
                                            id="create-date-start"
                                            name="create-date-start"
                                            type="date"
                                            onChange={e => handleFilterCreateDateStart(e)}
                                            value={filterCreateDateStart}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("End Date")}</Label>
                                        <Input
                                            id="create-date-end"
                                            name="create-date-end"
                                            type="date"
                                            onChange={e => handleFilterCreateDateEnd(e)}
                                            value={filterCreateDateEnd}
                                        />
                                    </div>
                                    <div className="mb-3 col">
                                        <Label className="form-label">{props.t("Status")}</Label>
                                        <Select
                                            id="Status-user"
                                            name="status"
                                            onChange={e => handleFilterStatus(e)}
                                            value={filterStatus}
                                            options={apptStatusOptions}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="mt-4 col text-end">
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleFilteredApartmentReservationData()}
                                        >
                                            <i className="mdi mdi-search-web me-1"/>
                                            {props.t("Filter")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="warning"
                                            className="btn-rounded mb-0"
                                            onClick={() => handleClearFilterInputs()}
                                        >
                                            <i className="mdi mdi-format-clear me-1"/>
                                            {props.t("Clear All Filters")}
                                        </Button>
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-rounded m-0"
                                            onClick={handleRefresh}
                                        >
                                            <i className="mdi mdi-refresh"/>
                                        </Button>
                                        {showOptions ? (
                                            <Button
                                                id="delete"
                                                type="button"
                                                color="danger"
                                                className="btn-rounded mb-2 me-2"
                                                onClick={() => setDeleteModal(true)}
                                            >
                                                <i className="mdi mdi-delete me-1"/>
                                                {props.t("Delete")}
                                            </Button>
                                        ) : ""}
                                    </div>
                                </div>
                                <hr/>
*/}
          <Row>
            <Card>
              <CardBody>
                <TableContainer
                  refreshButton={true}
                  handleRefreshClick={handleRefresh}
                  columns={apartmentReservationColumns}
                  data={mappedApartmentReservations || []}
                  handleRowClick={handleRowClick}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  handleAddClick={handleOpenApptModal}
                  customPageSize={50}
                  className="custom-header-css"
                />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
ApartmentReservation.propTypes = {
  t: PropTypes.any,
  apartmentReservations: PropTypes.array,
  getApartmentReservations: PropTypes.func
};

export default withRouter(withTranslation()(ApartmentReservation));
