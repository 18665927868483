import {
    ADD_APARTMENT_FAIL,
    ADD_APARTMENT_SUCCESS,
    ADD_NEW_APARTMENT,
    APARTMENT_CRITERIA,
    APARTMENT_CRITERIA_FAIL,
    APARTMENT_CRITERIA_SUCCESS, APARTMENT_FIND_FILTERS, APARTMENT_FIND_FILTERS_FAIL, APARTMENT_FIND_FILTERS_SUCCESS,
    DELETE_APARTMENT,
    DELETE_APARTMENT_FAIL,
    DELETE_APARTMENT_SUCCESS,
    GET_APARTMENTS,
    GET_APARTMENTS_FAIL,
    GET_APARTMENTS_SUCCESS,
    UPDATE_APARTMENT,
    UPDATE_APARTMENT_FAIL,
    UPDATE_APARTMENT_SUCCESS
} from "./actionTypes";

export const getApartments = () => ({
    type: GET_APARTMENTS,
})

export const getApartmentsSuccess = apartments => ({
    type: GET_APARTMENTS_SUCCESS,
    payload: apartments,
})

export const getApartmentsFail = error => ({
    type: GET_APARTMENTS_FAIL,
    payload: error,
})

export const addNewApartment = apartment => ({
    type: ADD_NEW_APARTMENT,
    payload: apartment,
})

export const addApartmentSuccess = apartment => ({
    type: ADD_APARTMENT_SUCCESS,
    payload: apartment,
})

export const addApartmentFail = error => ({
    type: ADD_APARTMENT_FAIL,
    payload: error,
})

export const updateApartment = apartment => ({
    type: UPDATE_APARTMENT,
    payload: apartment,
})

export const updateApartmentSuccess = apartment => ({
    type: UPDATE_APARTMENT_SUCCESS,
    payload: apartment,
})

export const updateApartmentFail = error => ({
    type: UPDATE_APARTMENT_FAIL,
    payload: error,
})

export const deleteApartment = id => ({
    type: DELETE_APARTMENT,
    id,
})

export const deleteApartmentSuccess = (apartment,req) => ({
    type: DELETE_APARTMENT_SUCCESS,
    payload: apartment,
    req: req,
})

export const deleteApartmentFail = error => ({
    type: DELETE_APARTMENT_FAIL,
    payload: error,
})

export const apartmentCriteria = res => ({
    type: APARTMENT_CRITERIA,
    payload: res
});

export const apartmentCriteriaSuccess = (res) => ({
    type: APARTMENT_CRITERIA_SUCCESS,
    payload: res
});

export const apartmentCriteriaFail = error => ({
    type: APARTMENT_CRITERIA_FAIL,
    payload: error
});

export const apartmentFindFilters = () => ({
    type: APARTMENT_FIND_FILTERS,
})

export const apartmentFindFiltersSuccess = apartments => ({
    type: APARTMENT_FIND_FILTERS_SUCCESS,
    payload: apartments,
})

export const apartmentFindFiltersFail = error => ({
    type: APARTMENT_FIND_FILTERS_FAIL,
    payload: error,
})