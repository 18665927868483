import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Name } from "./realEstateProjectCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
  addNewRealEstateProject as onAddNewRealEstateProject,
  deleteRealEstateProject as onDeleteRealEstateProject,
  getRealEstateProjects as onGetRealEstateProjects,
  updateRealEstateProject as onUpdateRealEstateProject
} from "store/realEstateProject/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";

const RealEstateProject = props => {

  //meta title
  document.title = `CRM | ${props.t("Apartment Rooms")}`;

  const initialRealEstateProject = {
    name: ""
  };

  const dispatch = useDispatch();
  const [deleteRealEstateProject, setDeleteRealEstateProject] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [realEstateProject, setRealEstateProject] = useState(initialRealEstateProject);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const { realEstateProjects, error } = useSelector(state => ({
    realEstateProjects: state.RealEstateProject.realEstateProjects,
    error: state.RealEstateProject.error
  }));

  useEffect(() => {
    dispatch(onGetRealEstateProjects());
  }, []);

  const handleRefresh = () => {
    dispatch(onGetRealEstateProjects());
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  setRealEstateProject(cellProps.row.original),
                    handleRealEstateProjectClick(cellProps.row);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const realEstateProjectData = cellProps.row.original;
                  onClickDelete(realEstateProjectData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setRealEstateProject(initialRealEstateProject);
      setModal(false);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const handleRealEstateProjectClick = arg => {
    const realEstateProject = arg.original;
    setIsEdit(true);
    setRealEstateProject({
      name: realEstateProject.name
    });
    toggle();
  };

  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = realEstateProject => {
    setDeleteRealEstateProject(realEstateProject);
    setDeleteModal(true);
  };

  const handleDeleteRealEstateProject = () => {
    dispatch(onDeleteRealEstateProject(deleteRealEstateProject.id));
    setDeleteModal(false);
  };

  const handleChange = (e) => {
    setRealEstateProject({
      ...realEstateProject,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveRealEstateProject = () => {
    if (isEdit) {
      dispatch(onUpdateRealEstateProject(realEstateProject));
    } else {
      dispatch(onAddNewRealEstateProject(realEstateProject));
    }
    toggle();
  };

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setMessage(error.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteRealEstateProject}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Real Estate Projects")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={realEstateProjects}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={toggle}
                    customPageSize={25}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={realEstateProject.name || ""}
                              invalid={!realEstateProject.name}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-realEstateProject"
                              onClick={saveRealEstateProject}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(RealEstateProject));
