
/* BUILDINGS */
export const GET_BUILDINGS = "GET_BUILDINGS"
export const GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS"
export const GET_BUILDINGS_FAIL = "GET_BUILDINGS_FAIL"

/**
 * add BUILDING
 */
export const ADD_NEW_BUILDING = "ADD_NEW_BUILDING"
export const ADD_BUILDING_SUCCESS = "ADD_BUILDING_SUCCESS"
export const ADD_BUILDING_FAIL = "ADD_BUILDING_FAIL"

/**
 * Edit BUILDING
 */
export const UPDATE_BUILDING = "UPDATE_BUILDING"
export const UPDATE_BUILDING_SUCCESS = "UPDATE_BUILDING_SUCCESS"
export const UPDATE_BUILDING_FAIL = "UPDATE_BUILDING_FAIL"

/**
 * Delete BUILDING
 */
export const DELETE_BUILDING = "DELETE_BUILDING"
export const DELETE_BUILDING_SUCCESS = "DELETE_BUILDING_SUCCESS"
export const DELETE_BUILDING_FAIL = "DELETE_BUILDING_FAIL"
