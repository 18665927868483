import {
    ADD_APARTMENT_TYPE_FAIL,
    ADD_APARTMENT_TYPE_SUCCESS,
    ADD_NEW_APARTMENT_TYPE,
    DELETE_APARTMENT_TYPE,
    DELETE_APARTMENT_TYPE_FAIL,
    DELETE_APARTMENT_TYPE_SUCCESS,
    GET_APARTMENT_TYPES,
    GET_APARTMENT_TYPES_FAIL,
    GET_APARTMENT_TYPES_SUCCESS,
    UPDATE_APARTMENT_TYPE,
    UPDATE_APARTMENT_TYPE_FAIL,
    UPDATE_APARTMENT_TYPE_SUCCESS
} from "./actionTypes";

export const getApartmentTypes = () => ({
    type: GET_APARTMENT_TYPES,
})

export const getApartmentTypesSuccess = apartments => ({
    type: GET_APARTMENT_TYPES_SUCCESS,
    payload: apartments,
})

export const getApartmentTypesFail = error => ({
    type: GET_APARTMENT_TYPES_FAIL,
    payload: error,
})

export const addNewApartmentType = apartment => ({
    type: ADD_NEW_APARTMENT_TYPE,
    payload: apartment,
})

export const addApartmentTypeSuccess = apartment => ({
    type: ADD_APARTMENT_TYPE_SUCCESS,
    payload: apartment,
})

export const addApartmentTypeFail = error => ({
    type: ADD_APARTMENT_TYPE_FAIL,
    payload: error,
})

export const updateApartmentType = apartment => ({
    type: UPDATE_APARTMENT_TYPE,
    payload: apartment,
})

export const updateApartmentTypeSuccess = apartment => ({
    type: UPDATE_APARTMENT_TYPE_SUCCESS,
    payload: apartment,
})

export const updateApartmentTypeFail = error => ({
    type: UPDATE_APARTMENT_TYPE_FAIL,
    payload: error,
})

export const deleteApartmentType = id => ({
    type: DELETE_APARTMENT_TYPE,
    id,
})

export const deleteApartmentTypeSuccess = (apartment,req) => ({
    type: DELETE_APARTMENT_TYPE_SUCCESS,
    payload: apartment,
    req: req,
})

export const deleteApartmentTypeFail = error => ({
    type: DELETE_APARTMENT_TYPE_FAIL,
    payload: error,
})
