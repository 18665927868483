import {call, put, takeEvery} from "redux-saga/effects";

// Customers Redux States
import {ADD_NEW_CUSTOMER, CUSTOMER_CRITERIA, DELETE_CUSTOMER, GET_CUSTOMERS, UPDATE_CUSTOMER} from "./actionTypes";

import {
    addCustomerFail,
    addCustomerSuccess,
    customerCriteriaFail,
    customerCriteriaSuccess,
    deleteCustomerFail,
    deleteCustomerSuccess,
    getCustomersFail,
    getCustomersSuccess,
    updateCustomerFail,
    updateCustomerSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchCustomers() {
    try {
        const response = yield call(getCustomers);
        yield put(getCustomersSuccess(response));
    } catch (error) {
        yield put(getCustomersFail(error));
    }
}

const getCustomers = () => get(url.CUSTOMER_GET_ALL)

function* onUpdateCustomer({ payload: customer }) {
    try {
        const response = yield call(updateCustomer, customer);
        yield put(updateCustomerSuccess(response));
    } catch (error) {
        yield put(updateCustomerFail(error));
    }
}

const updateCustomer = (customer) => post(url.CUSTOMER_API,customer)

function* onDeleteCustomer({id}) {
    try {
        const response = yield call(deleteCustomer, id);
        if (response.status === 200){
            yield put(deleteCustomerSuccess(response,id));
        }else{
            yield put(deleteCustomerFail(response));
        }
    } catch (error) {
        yield put(deleteCustomerFail(error));
    }
}

const deleteCustomer = (id) => del(`${url.CUSTOMER_API}/${id}`);

function* onAddNewCustomer({ payload: customer }) {
    try {
        const response = yield call(addNewCustomer, customer);

        yield put(addCustomerSuccess(response));
    } catch (error) {
        yield put(addCustomerFail(error));
    }
}

const addNewCustomer = (customer) => post(url.CUSTOMER_API,customer);

function* fetchCustomerCriteria({ payload: req }) {

    try {
        const response = yield call(getCustomerCriteria, req);
        yield put(customerCriteriaSuccess(response, req));
    } catch (error) {
        yield put(customerCriteriaFail(error));
    }
}

const getCustomerCriteria = (req) => post(url.CUSTOMER_CRITERIA, req);

function* customersSaga() {
    yield takeEvery(GET_CUSTOMERS, fetchCustomers);
    yield takeEvery(CUSTOMER_CRITERIA, fetchCustomerCriteria);
    yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
    yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
    yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
}

export default customersSaga;
