import {
    ADD_REAL_ESTATE_PROJECT_FAIL,
    ADD_REAL_ESTATE_PROJECT_SUCCESS,
    REAL_ESTATE_PROJECT_CRITERIA_FAIL,
    REAL_ESTATE_PROJECT_CRITERIA_SUCCESS,
    DELETE_REAL_ESTATE_PROJECT_FAIL,
    DELETE_REAL_ESTATE_PROJECT_SUCCESS,
    GET_REAL_ESTATE_PROJECTS_FAIL,
    GET_REAL_ESTATE_PROJECTS_SUCCESS,
    UPDATE_REAL_ESTATE_PROJECT_FAIL,
    UPDATE_REAL_ESTATE_PROJECT_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    realEstateProjects: [],
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const RealEstateProject = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REAL_ESTATE_PROJECTS_SUCCESS:
            return {
                ...state,
                realEstateProjects: action.payload,
            };

        case GET_REAL_ESTATE_PROJECTS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_REAL_ESTATE_PROJECT_SUCCESS:
            return {
                ...state,
                realEstateProjects: [...state.realEstateProjects, action.payload],
            };

        case ADD_REAL_ESTATE_PROJECT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_REAL_ESTATE_PROJECT_SUCCESS:
            return {
                ...state,
                realEstateProjects: state.realEstateProjects.map(apartmentDirection =>
                    apartmentDirection.id.toString() === action.payload.id.toString()
                        ? { apartmentDirection, ...action.payload }
                        : apartmentDirection
                ),
            };

        case UPDATE_REAL_ESTATE_PROJECT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_REAL_ESTATE_PROJECT_SUCCESS:
            return {
                ...state,
                realEstateProjects: state.realEstateProjects.filter(
                    apartmentDirection => apartmentDirection.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_REAL_ESTATE_PROJECT_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case REAL_ESTATE_PROJECT_CRITERIA_SUCCESS:
            return {
                ...state,
                realEstateProjects: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case REAL_ESTATE_PROJECT_CRITERIA_FAIL:
            return {
                ...state,
                realEstateProjects: action.payload.data
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default RealEstateProject;
