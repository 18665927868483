import {call, put, takeEvery} from "redux-saga/effects";

// ApartmentReservations Redux States
import {
  ADD_NEW_APARTMENT_RESERVATION,
  DELETE_APARTMENT_RESERVATION,
  GET_APARTMENT_RESERVATION_CRITERIA,
  GET_APARTMENT_RESERVATIONS,
  GET_USER_APARTMENT_RESERVATIONS,
  UPDATE_APARTMENT_RESERVATION,
} from "./actionTypes";

import {
  addApartmentReservationFail,
  addApartmentReservationSuccess,
  deleteApartmentReservationFail,
  deleteApartmentReservationSuccess,
  getApartmentReservationCriteriaFail,
  getApartmentReservationCriteriaSuccess,
  getApartmentReservationsFail,
  getApartmentReservationsSuccess,
  getUserApartmentReservationsFail,
  getUserApartmentReservationsSuccess,
  updateApartmentReservationFail,
  updateApartmentReservationSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchApartmentReservations() {
  try {
    const response = yield call(getApartmentReservations);
    yield put(getApartmentReservationsSuccess(response));
  } catch (error) {
    yield put(getApartmentReservationsFail(error));
  }
}

const getApartmentReservations = () => get(url.APARTMENT_RESERVATION_GET_ALL);

function* onUpdateApartmentReservation({ payload: customerAppointment }) {
  try {
    const response = yield call(updateApartmentReservation, customerAppointment);
    yield put(updateApartmentReservationSuccess(response));
  } catch (error) {
    yield put(updateApartmentReservationFail(error));
  }
}

const updateApartmentReservation = (customerAppointment) => post(url.APARTMENT_RESERVATION_API, customerAppointment);

function* onDeleteApartmentReservation({ id }) {
  try {
    const response = yield call(deleteApartmentReservation, id);
    yield put(deleteApartmentReservationSuccess(response,id));
  } catch (error) {
    yield put(deleteApartmentReservationFail(error));
  }
}

const deleteApartmentReservation = (id) => del(`${url.APARTMENT_RESERVATION_API}/${id}`);

function* onAddNewApartmentReservation({ payload: customerAppointment }) {
  try {
    const response = yield call(addNewApartmentReservation, customerAppointment);

    yield put(addApartmentReservationSuccess(response));
  } catch (error) {
    yield put(addApartmentReservationFail(error));
  }
}

const addNewApartmentReservation = (customerAppointment) => post(url.APARTMENT_RESERVATION_API, customerAppointment);

function* fetchUserApartmentReservations({ id }) {
  try {
    const response = yield call(getUserApartmentReservations, id);
    yield put(getUserApartmentReservationsSuccess(response));
  } catch (error) {
    yield put(getUserApartmentReservationsFail(error));
  }
}

const getUserApartmentReservations = (id) => get(`${url.APARTMENT_RESERVATION_API}/${id}`);

function* fetchApartmentReservationCriteria({ payload: body }) {
  try {
    const response = yield call(getApartmentReservationCriteria, body);
    yield put(getApartmentReservationCriteriaSuccess(response));
  } catch (error) {
    yield put(getApartmentReservationCriteriaFail(error));
  }
}

const getApartmentReservationCriteria = (body) => post(url.APARTMENT_RESERVATION_CRITERIA, body);

function* customerAppointmentsSaga() {
  yield takeEvery(GET_APARTMENT_RESERVATIONS, fetchApartmentReservations);
  yield takeEvery(ADD_NEW_APARTMENT_RESERVATION, onAddNewApartmentReservation);
  yield takeEvery(UPDATE_APARTMENT_RESERVATION, onUpdateApartmentReservation);
  yield takeEvery(DELETE_APARTMENT_RESERVATION, onDeleteApartmentReservation);
  yield takeEvery(GET_USER_APARTMENT_RESERVATIONS, fetchUserApartmentReservations);
  yield takeEvery(GET_APARTMENT_RESERVATION_CRITERIA, fetchApartmentReservationCriteria);
}

export default customerAppointmentsSaga;
