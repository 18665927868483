import {
  ADD_NEW_CUSTOMER_APPOINTMENT,
  ADD_NEW_CUSTOMER_WITH_APPT,
  ADD_NEW_CUSTOMER_WITH_APPT_FAIL,
  ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS,
  ADD_CUSTOMER_APPOINTMENT_FAIL,
  ADD_CUSTOMER_APPOINTMENT_SUCCESS,
  DELETE_CUSTOMER_APPOINTMENT,
  DELETE_CUSTOMER_APPOINTMENT_FAIL,
  DELETE_CUSTOMER_APPOINTMENT_SUCCESS,
  GET_CUSTOMER_APPOINTMENTS,
  GET_CUSTOMER_APPOINTMENTS_FAIL,
  GET_CUSTOMER_APPOINTMENTS_SUCCESS,
  GET_CUSTOMER_APPOINTMENT_CRITERIA,
  GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL,
  GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS,
  GET_TODAY_CUSTOMER_APPOINTMENTS,
  GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL,
  GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS,
  GET_USER_CUSTOMER_APPOINTMENTS,
  GET_USER_CUSTOMER_APPOINTMENTS_FAIL,
  GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS,
  UPDATE_CUSTOMER_APPOINTMENT,
  UPDATE_CUSTOMER_APPOINTMENT_FAIL,
  UPDATE_CUSTOMER_APPOINTMENT_SUCCESS
} from "./actionTypes";

export const getCustomerAppointments = () => ({
  type: GET_CUSTOMER_APPOINTMENTS
});

export const getCustomerAppointmentsSuccess = customerAppointments => ({
  type: GET_CUSTOMER_APPOINTMENTS_SUCCESS,
  payload: customerAppointments
});

export const getCustomerAppointmentsFail = error => ({
  type: GET_CUSTOMER_APPOINTMENTS_FAIL,
  payload: error
});

export const addNewCustomerAppointment = customerAppointment => ({
  type: ADD_NEW_CUSTOMER_APPOINTMENT,
  payload: customerAppointment
});

export const addCustomerAppointmentSuccess = customerAppointment => ({
  type: ADD_CUSTOMER_APPOINTMENT_SUCCESS,
  payload: customerAppointment
});

export const addCustomerAppointmentFail = error => ({
  type: ADD_CUSTOMER_APPOINTMENT_FAIL,
  payload: error
});

export const updateCustomerAppointment = customerAppointment => ({
  type: UPDATE_CUSTOMER_APPOINTMENT,
  payload: customerAppointment
});

export const updateCustomerAppointmentSuccess = customerAppointment => ({
  type: UPDATE_CUSTOMER_APPOINTMENT_SUCCESS,
  payload: customerAppointment
});

export const updateCustomerAppointmentFail = error => ({
  type: UPDATE_CUSTOMER_APPOINTMENT_FAIL,
  payload: error
});

export const deleteCustomerAppointment = id => ({
  type: DELETE_CUSTOMER_APPOINTMENT,
  id
});

export const deleteCustomerAppointmentSuccess = (customerAppointment,req) => ({
  type: DELETE_CUSTOMER_APPOINTMENT_SUCCESS,
  payload: customerAppointment,
  req: req
});

export const deleteCustomerAppointmentFail = error => ({
  type: DELETE_CUSTOMER_APPOINTMENT_FAIL,
  payload: error
});

export const getLeadCustomerAppointments = id => ({
  type: GET_CUSTOMER_APPOINTMENTS,
  id
});

export const getLeadCustomerAppointmentsSuccess = customerAppointment => ({
  type: GET_CUSTOMER_APPOINTMENTS_SUCCESS,
  payload: customerAppointment
});

export const getLeadCustomerAppointmentsFail = error => ({
  type: GET_CUSTOMER_APPOINTMENTS_FAIL,
  payload: error
});

export const getUserCustomerAppointments = id => ({
  type: GET_USER_CUSTOMER_APPOINTMENTS,
  id
});

export const getUserCustomerAppointmentsSuccess = customerAppointment => ({
  type: GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS,
  payload: customerAppointment
});

export const getUserCustomerAppointmentsFail = error => ({
  type: GET_USER_CUSTOMER_APPOINTMENTS_FAIL,
  payload: error
});

export const getTodayCustomerAppointments = () => ({
  type: GET_TODAY_CUSTOMER_APPOINTMENTS
});

export const getTodayCustomerAppointmentsSuccess = customerAppointment => ({
  type: GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS,
  payload: customerAppointment
});

export const getTodayCustomerAppointmentsFail = error => ({
  type: GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL,
  payload: error
});


export const getCustomerAppointmentCriteria = customerAppointment => ({
  type: GET_CUSTOMER_APPOINTMENT_CRITERIA,
  payload: customerAppointment
});

export const getCustomerAppointmentCriteriaSuccess = customerAppointment => ({
  type: GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS,
  payload: customerAppointment
});

export const getCustomerAppointmentCriteriaFail = error => ({
  type: GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL,
  payload: error
});

export const addNewCustomerWithAppointment = res => ({
  type: ADD_NEW_CUSTOMER_WITH_APPT,
  payload: res
});

export const addNewCustomerWithAppointmentSuccess = (res) => ({
  type: ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS,
  payload: res
});

export const addNewCustomerWithAppointmentFail = error => ({
  type: ADD_NEW_CUSTOMER_WITH_APPT_FAIL,
  payload: error
});