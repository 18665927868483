
/* APARTMENT_RESERVATIONS */
export const GET_APARTMENT_RESERVATIONS = "GET_APARTMENT_RESERVATIONS"
export const GET_APARTMENT_RESERVATIONS_SUCCESS = "GET_APARTMENT_RESERVATIONS_SUCCESS"
export const GET_APARTMENT_RESERVATIONS_FAIL = "GET_APARTMENT_RESERVATIONS_FAIL"

/**
 * add APARTMENT_RESERVATION
 */
export const ADD_NEW_APARTMENT_RESERVATION = "ADD_NEW_APARTMENT_RESERVATION"
export const ADD_APARTMENT_RESERVATION_SUCCESS = "ADD_APARTMENT_RESERVATION_SUCCESS"
export const ADD_APARTMENT_RESERVATION_FAIL = "ADD_APARTMENT_RESERVATION_FAIL"

/**
 * Edit APARTMENT_RESERVATION
 */
export const UPDATE_APARTMENT_RESERVATION = "UPDATE_APARTMENT_RESERVATION"
export const UPDATE_APARTMENT_RESERVATION_SUCCESS = "UPDATE_APARTMENT_RESERVATION_SUCCESS"
export const UPDATE_APARTMENT_RESERVATION_FAIL = "UPDATE_APARTMENT_RESERVATION_FAIL"

/**
 * Delete APARTMENT_RESERVATION
 */
export const DELETE_APARTMENT_RESERVATION = "DELETE_APARTMENT_RESERVATION"
export const DELETE_APARTMENT_RESERVATION_SUCCESS = "DELETE_APARTMENT_RESERVATION_SUCCESS"
export const DELETE_APARTMENT_RESERVATION_FAIL = "DELETE_APARTMENT_RESERVATION_FAIL"

/**
 * Lead APARTMENT_RESERVATION
 */
export const GET_LEAD_APARTMENT_RESERVATIONS = "GET_LEAD_APARTMENT_RESERVATIONS"
export const GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS = "GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS"
export const GET_LEAD_APARTMENT_RESERVATIONS_FAIL = "GET_LEAD_APARTMENT_RESERVATIONS_FAIL"


/**
 * Lead APARTMENT_RESERVATION
 */
export const GET_USER_APARTMENT_RESERVATIONS = "GET_USER_APARTMENT_RESERVATIONS"
export const GET_USER_APARTMENT_RESERVATIONS_SUCCESS = "GET_USER_APARTMENT_RESERVATIONS_SUCCESS"
export const GET_USER_APARTMENT_RESERVATIONS_FAIL = "GET_USER_APARTMENT_RESERVATIONS_FAIL"

/**
 * Today APARTMENT_RESERVATION
 */
export const GET_TODAY_APARTMENT_RESERVATIONS = "GET_TODAY_APARTMENT_RESERVATIONS"
export const GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS = "GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS"
export const GET_TODAY_APARTMENT_RESERVATIONS_FAIL = "GET_TODAY_APARTMENT_RESERVATIONS_FAIL"


/**
 * APPT CRITERIA
 */
export const GET_APARTMENT_RESERVATION_CRITERIA = "GET_APARTMENT_RESERVATION_CRITERIA"
export const GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS = "GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS"
export const GET_APARTMENT_RESERVATION_CRITERIA_FAIL = "GET_APARTMENT_RESERVATION_CRITERIA_FAIL"
