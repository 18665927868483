import {call, put, takeEvery} from "redux-saga/effects";

// RealEstateProjects Redux States
import {ADD_NEW_REAL_ESTATE_PROJECT, REAL_ESTATE_PROJECT_CRITERIA, DELETE_REAL_ESTATE_PROJECT, GET_REAL_ESTATE_PROJECTS, UPDATE_REAL_ESTATE_PROJECT} from "./actionTypes";

import {
    addRealEstateProjectFail,
    addRealEstateProjectSuccess,
    deleteRealEstateProjectFail,
    deleteRealEstateProjectSuccess,
    getRealEstateProjectsFail,
    getRealEstateProjectsSuccess,
    updateRealEstateProjectFail,
    updateRealEstateProjectSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchRealEstateProjects() {
    try {
        const response = yield call(getRealEstateProjects);
        yield put(getRealEstateProjectsSuccess(response));
    } catch (error) {
        yield put(getRealEstateProjectsFail(error));
    }
}

const getRealEstateProjects = () => get(url.REAL_ESTATE_PROJECT_GET_ALL)

function* onUpdateRealEstateProject({ payload: realEstateProject }) {
    try {
        const response = yield call(updateRealEstateProject, realEstateProject);
        yield put(updateRealEstateProjectSuccess(response));
    } catch (error) {
        yield put(updateRealEstateProjectFail(error));
    }
}

const updateRealEstateProject = (realEstateProject) => post(url.REAL_ESTATE_PROJECT_API,realEstateProject)

function* onDeleteRealEstateProject({id}) {
    try {
        const response = yield call(deleteRealEstateProject, id);
        if (response.status === 200){
            yield put(deleteRealEstateProjectSuccess(response,id));
        }else{
            yield put(deleteRealEstateProjectFail(response));
        }
    } catch (error) {
        yield put(deleteRealEstateProjectFail(error));
    }
}

const deleteRealEstateProject = (id) => del(`${url.REAL_ESTATE_PROJECT_API}/${id}`);

function* onAddNewRealEstateProject({ payload: realEstateProject }) {
    try {
        const response = yield call(addNewRealEstateProject, realEstateProject);

        yield put(addRealEstateProjectSuccess(response));
    } catch (error) {
        yield put(addRealEstateProjectFail(error));
    }
}

const addNewRealEstateProject = (realEstateProject) => post(url.REAL_ESTATE_PROJECT_API,realEstateProject);

function* realEstateProjectsSaga() {
    yield takeEvery(GET_REAL_ESTATE_PROJECTS, fetchRealEstateProjects);
    yield takeEvery(ADD_NEW_REAL_ESTATE_PROJECT, onAddNewRealEstateProject);
    yield takeEvery(UPDATE_REAL_ESTATE_PROJECT, onUpdateRealEstateProject);
    yield takeEvery(DELETE_REAL_ESTATE_PROJECT, onDeleteRealEstateProject);
}

export default realEstateProjectsSaga;
