import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import PropTypes from "prop-types";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  CloseButton,
  Col,
  Collapse,
  Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";

import { getBuildings as onGetBuildings } from "store/building/actions";

import {
  addNewApartment,
  apartmentCriteria,
  apartmentFindFilters,
  deleteApartment as onDeleteApartment
} from "store/apartment/actions";

import { getApartmentRooms as onGetApartmentRooms } from "store/apartmentRoom/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { BoolValue, Category, City, Facade, Phone, Resource, Segment, Surname } from "./ApartmentCol";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import ApartmentDetail from "./ApartmentDetail";
import Notification from "../../components/Notification";
import CustomSelectableTable from "../../components/Common/CustomSelectableTable";
import { Name } from "../Users/UserList/userlistCol";
import { apartmentCriteria as onGetApartmentCriteria } from "../../store/apartment/actions";
import { isEmpty } from "lodash";
import useAuth from "../../hooks/useAuth";
import { reservationStatusOptionClass } from "../../components/Calendar/constant";
import { getApartmentTypes } from "../../store/apartmentType/actions";
import { getApartmentDirections } from "../../store/apartmentDirection/actions";
import { get } from "../../helpers/axios_with_headers";

const isAgent = process.env.REACT_APP_IS_AGENT === "TRUE";

const Apartment = props => {
    //meta title
    document.title = `CRM | ${props.t("Apartments")}`;

    const initialApartment = {
      apartmentNumber: "",
      floorNumber: 0,
      grossSquareMeters: 0,
      netSquareMeters: 0,
      numberOfRooms: { numberOfRoom: "" },
      balconyCount: 0,
      bathroomCount: 0,
      rental: false,
      sale: false,
      rentalPrice: 0,
      salePrice: 0,
      facade: "",
      description: "",
      status: "AVAILABLE",   //AVAILABLE,RESERVED,DEPOSIT_RECEIVED,RENTED,SOLD
      building: {
        project: {},
        blockName: "",
        numberOfApartments: 0,
        address: "",
        constructionYear: 0
      }
    };

    const dispatch = useDispatch();

    const { authUser } = useAuth();
    const roleUser = (authUser && authUser.role && authUser.role.name === "ROLE_USER");

    const [size, setSize] = useState(50);
    const [activeTab, setActiveTab] = useState(0);
    const [openedTabs, setOpenedTabs] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");
    const [dataPerPage, setDataPerPage] = useState(50);
    const [sizeButtonDisabled, setSizeButtonDisabled] = useState(false);
    const [apartment, setApartment] = useState(initialApartment);
    const [building, setBuilding] = useState(null);
    const [status, setStatus] = useState(null);
    const [numberOfRooms, setNumberOfRooms] = useState(null);
    const [callbackClose, setCallbackClose] = useState(false);
    const [selectedApartments, setSelectedApartments] = useState([]);
    const [clearSelected, setClearSelected] = useState(false);
    const [filterBuilding, setFilterBuilding] = useState({});
    const [selectedMulti, setSelectedMulti] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterNumberOfRooms, setFilterNumberOfRooms] = useState(null);
    const [filterFacade, setFilterFacade] = useState(null);
    const [filterRepresentative, setFilterRepresentative] = useState(!roleUser ? {} : {
      label: authUser.username,
      value: authUser
    });
    const [filterApartmentNumber, setFilterApartmentNumber] = useState("");
    const [filterFloorNumber, setFilterFloorNumber] = useState("");
    const [filterGrossSquareMetersStart, setFilterGrossSquareMetersStart] = useState("");
    const [filterGrossSquareMetersEnd, setFilterGrossSquareMetersEnd] = useState("");
    const [filterNetSquareMetersStart, setFilterNetSquareMetersStart] = useState("");
    const [filterNetSquareMetersEnd, setFilterNetSquareMetersEnd] = useState("");
    const [filterNumberOfRoomsStart, setFilterNumberOfRoomsStart] = useState("");
    const [facade, setFacade] = useState([]);
    const [filterNumberOfRoomsEnd, setFilterNumberOfRoomsEnd] = useState("");
    const [filterBalconyCount, setFilterBalconyCount] = useState("");
    const [filterBathroomCount, setFilterBathroomCount] = useState("");
    const [filterDescription, setFilterDescription] = useState("");
    const [filterProject, setFilterProject] = useState("");
    const [filterCreateDateStart, setFilterCreateDateStart] = useState("");
    const [filterCreateDateEnd, setFilterCreateDateEnd] = useState("");
    const [filterUpdateDateStart, setFilterUpdateDateStart] = useState("");
    const [filterSale, setFilterSale] = useState("");
    const [filterRental, setFilterRental] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [apartmentNumberOptions, setApartmentNumberOptions] = useState([]);
    const [floorNumberOptions, setFloorNumberOptions] = useState([]);
    const [numberOfRoomOptions, setNumberOfRoomOptions] = useState([]);
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [apartmentDirection, setApartmentDirection] = useState();
    const [apartmentType, setApartmentType] = useState();
    const [apartmentDirectionOptions, setApartmentDirectionOptions] = useState([]);
    const [apartmentTypeOptions, setApartmentTypeOptions] = useState([]);

    const statusOptions = [
      { label: props.t("AVAILABLE"), value: "AVAILABLE" },
      { label: props.t("RESERVED"), value: "RESERVED" },
      { label: props.t("RENTED"), value: "RENTED" },
      { label: props.t("SOLD"), value: "SOLD" }
    ];

    const { apartments, apartmentFilters, page, totalCount, totalPages, error, processResponse } = useSelector(state => ({
      apartments: state.Apartment.apartments,
      page: state.Apartment.page,
      totalCount: state.Apartment.totalCount,
      totalPages: state.Apartment.totalPages,
      error: state.Apartment.error,
      processResponse: state.Apartment.processResponse,
      apartmentFilters: state.Apartment.apartmentFilters
    }));

    const initialBody = {
      size: size,
      page: page ? page : 0
    };

    const [body, setBody] = useState(initialBody);

    const { buildings } = useSelector(state => ({
      buildings: state.Building.buildings
    }));

    const buildingObjectOptions = [];
    buildings.map((item) => {
      buildingObjectOptions.push({ label: item.blockName, value: item });
    });

    const { apartmentRooms } = useSelector(state => ({
      apartmentRooms: state.ApartmentRoom.apartmentRooms
    }));

    const apartmentRoomOptions = [];
    apartmentRooms.map((item) => {
      apartmentRoomOptions.push({ label: item.numberOfRoom, value: item });
    });

    const toggleFilter = () => setIsOpen(!isOpen);

    useEffect(() => {
      dispatch(onGetBuildings());
    }, []);

    const { apartmentTypes } = useSelector(state => ({
      apartmentTypes: state.ApartmentType.apartmentTypes
    }));

    useEffect(() => {
      dispatch(getApartmentTypes());
    }, []);

    useEffect(() => {
      const apartmentTypeOpt = apartmentTypes.map((item) => {
        return { label: item.name, value: item };
      });
      setApartmentTypeOptions(apartmentTypeOpt);
    }, [apartmentTypes]);

    const { apartmentDirections } = useSelector(state => ({
      apartmentDirections: state.ApartmentDirection.apartmentDirections
    }));

    useEffect(() => {
      dispatch(getApartmentDirections());
    }, []);

    useEffect(() => {
      const apartmentDirectionOpt = apartmentDirections.map((item) => {
        return { label: item.name, value: item };
      });
      setApartmentDirectionOptions(apartmentDirectionOpt);
    }, [apartmentDirections]);

    useEffect(() => {
      dispatch(onGetApartmentRooms());
    }, []);

    useEffect(() => {
      const apartmentNumberOptions = apartmentFilters.apartmentNumbers && apartmentFilters.apartmentNumbers.map((item) => {
        return { label: item, value: item };
      });
      const floorNumbersOptions = apartmentFilters.floorNumbers && apartmentFilters.floorNumbers.map((item) => {
        return { label: item, value: item };
      });
      const numberOfRoomsOptions = apartmentFilters.numberOfRooms && apartmentFilters.numberOfRooms.map((item) => {
        return { label: item.numberOfRoom, value: item };
      });
      const buildingsOptions = apartmentFilters.buildings && apartmentFilters.buildings.map((item) => {
        return { label: item.blockName, value: item };
      });
      const projectOptions = apartmentFilters.buildings && apartmentFilters.buildings.map((item) => {
        return { label: item.project.name, value: item.project };
      });
      setApartmentNumberOptions(apartmentNumberOptions);
      setFloorNumberOptions(floorNumbersOptions);
      setNumberOfRoomOptions(numberOfRoomsOptions);
      setBuildingOptions(buildingsOptions);
      setProjectOptions(projectOptions);
    }, [apartmentFilters]);

    useEffect(() => {
      dispatch(apartmentCriteria());
    }, []);

    useEffect(() => {
      dispatch(apartmentFindFilters());
    }, []);

    const onChangeInBuildingSelect = event => {
      setApartment({
        ...apartment,
        ["building"]: event !== null ? event.value : null
      });
      setBuilding((event));
    };

    const onChangeInStatusSelect = event => {
      setApartment({
        ...apartment,
        ["status"]: event !== null ? event.value : null
      });
      setStatus((event));
    };

    const onChangeInNumberOfRoomsSelect = event => {
      setApartment({
        ...apartment,
        ["numberOfRooms"]: event !== null ? event.value : null
      });
      setNumberOfRooms((event));
    };

    const onChangeInApartmentTypeSelect = event => {
      setApartment({
        ...apartment,
        ["type"]: event !== null ? event.value : null
      });
      setApartmentType((event));
    };

    const onChangeInApartmentDirectionSelect = event => {
      setApartment({
        ...apartment,
        ["direction"]: event !== null ? event.value : null
      });
      setApartmentDirection((event));
    };

    const handleChangeFilterBuildingSelect = event => {
      setBody({
        ...body,
        ["building"]: event !== null ? event.value.id : null
      });
      setFilterBuilding((event));
    };

    const handleChangeFilterBathroomCount = (e) => {
      setFilterBathroomCount(e.target.value);
      setBody({
        ...body,
        ["bathroomCount"]: e.target.value
      });
    };

    const handleChangeFilterBalconyCount = (e) => {
      setFilterBalconyCount(e.target.value);
      setBody({
        ...body,
        ["balconyCount"]: e.target.value
      });
    };

    const handleChangeFilterDescription = (e) => {
      setFilterDescription(e.target.value);
      setBody({
        ...body,
        ["description"]: e.target.value
      });
    };

    const handleChangeFilterApartmentNumber = (e) => {
      if (e) {
        setFilterApartmentNumber(e);
        setBody({
          ...body,
          ["apartmentNumber"]: e.value
        });
      } else {
        setFilterApartmentNumber(e);
        delete body.apartmentNumber;
        setBody(body);
      }
    };

    const handleChangeFilterProject = (e) => {
      if (e) {
        setFilterProject(e);
        setBody({
          ...body,
          ["project"]: e.value
        });
      } else {
        setFilterProject(e);
        delete body.project;
        setBody(body);
      }
    };

    useEffect(() => {
      filterProject && filterProject.value && get(process.env.REACT_APP_API_URL + "/building/findByProject/" + filterProject.value.id).then(r => {
        if (r.status === 200) {
          if (r.data) {
            setBuildingOptions(r.data.map(item => {
              return { label: item.blockName, value: item };
            }));
          }
        }
      });
    }, [filterProject]);

    useEffect(() => {
      filterBuilding && filterBuilding.value && get(process.env.REACT_APP_API_URL + "/apartment/findByBuilding/" + filterBuilding.value.id).then(r => {
        if (r.status === 200) {
          if (r.data) {
            setApartmentNumberOptions(r.data.map(item => {
              return { label: item.apartmentNumber, value: item };
            }));
          }
        }
      });
    }, [filterBuilding]);

    const handleChangeFilterFloorNumber = (e) => {
      if (e) {
        setFilterFloorNumber(e);
        setBody({
          ...body,
          ["floorNumber"]: e.value
        });
      } else {
        setFilterFloorNumber(e);
        delete body.floorNumber;
        setBody(body);
      }
    };

    const handleChangeFilterNetSquareMetersStart = (e) => {
      setFilterNetSquareMetersStart(e.target.value);
      setBody({
        ...body,
        ["netSquareMetersStart"]: e.target.value
      });
    };

    const handleChangeFilterNetSquareMetersEnd = (e) => {
      setFilterNetSquareMetersEnd(e.target.value);
      setBody({
        ...body,
        ["netSquareMetersEnd"]: e.target.value
      });
    };

    const handleChangeFilterGrossSquareMetersStart = (e) => {
      setFilterGrossSquareMetersStart(e.target.value);
      setBody({
        ...body,
        ["grossSquareMetersStart"]: e.target.value
      });
    };

    const handleChangeFilterGrossSquareMetersEnd = (e) => {
      setFilterGrossSquareMetersEnd(e.target.value);
      setBody({
        ...body,
        ["grossSquareMetersEnd"]: e.target.value
      });
    };

    const handleChangeFilterNumberOfRoomsStart = (e) => {
      if (e) {
        const selectCodes = e.map((select) => select.value.numberOfRoom);
        setFilterNumberOfRoomsStart(e);
        setBody({
          ...body,
          ["numberOfRooms"]: selectCodes
        });
      } else {
        setFilterNumberOfRoomsStart(e);
        delete body.numberOfRooms;
        setBody(body);
      }

    };

    function handleChangeFilterIsRental(e) {
      if (e) {
        setFilterRental(e);
        setBody({
          ...body,
          ["rental"]: e.value
        });
      } else {
        setFilterRental(e);
        delete body.rental;
        setBody(body);
      }
    }

    function handleChangeFilterIsSale(e) {
      if (e) {
        setFilterSale(e);
        setBody({
          ...body,
          ["sale"]: e.value
        });
      } else {
        setFilterSale(e);
        delete body.sale;
        setBody(body);
      }
    }

    function handleFilterFacade(e) {
      if (e) {
        const selectCodes = e.map((select) => select.value);
        setFilterFacade(e);
        setBody({
          ...body,
          ["facade"]: selectCodes
        });
      } else {
        setFilterFacade(e);
        delete body.facade;
        setBody(body);
      }
    }

    function handleFilterStatus(e) {
      if (e) {
        setFilterStatus(e);
        setBody({
          ...body,
          ["status"]: e.value
        });
      } else {
        setFilterStatus(e);
        delete body.status;
        setBody(body);
      }
    }

    function handleFilterCreateDateEnd(e) {
      setFilterCreateDateEnd(e.target.value);
      setBody({
        ...body,
        ["createDateEnd"]: e.target.value
      });
    }

    function handleFilterUpdateDateStart(e) {
      setFilterUpdateDateStart(e.target.value);
      setBody({
        ...body,
        ["updateDateStart"]: e.target.value
      });
    }

    const handleChange = (e) => {
      switch (e.target.type) {
        case "text":
          setApartment({
            ...apartment,
            [e.target.name]: e.target.value
          });
          break;
        case "textarea":
          setApartment({
            ...apartment,
            [e.target.name]: e.target.value
          });
          break;
        default :
          setApartment({
            ...apartment,
            [e.target.name]: e.target.value
          });
          break;
      }

    };

    const handleSubmit = (values) => {
      dispatch(addNewApartment(apartment));
    };

    useEffect(() => {
      if (!isEmpty(error)) {
        setShowNotification(true);
        setMessage(error.message);
        setNotificationType("Danger");
      } else if (processResponse) {
        setShowNotification(true);
        setMessage("Değişiklikler Kaydedildi");
        setNotificationType("Success");
        if (modal) {
          toggle();
        }
      }
    }, [error, processResponse]);

    const handleApartmentClick = row => {
      const apartment = row.original;

      setApartment({
        id: apartment.id,
        apartmentNumber: apartment.apartmentNumber,
        floorNumber: apartment.floorNumber,
        grossSquareMeters: apartment.grossSquareMeters,
        netSquareMeters: apartment.netSquareMeters,
        numberOfRooms: apartment.numberOfRooms,
        balconyCount: apartment.balconyCount,
        bathroomCount: apartment.bathroomCount,
        facade: apartment.facade,
        rental: apartment.rental,
        sale: apartment.sale,
        rentalPrice: apartment.rentalPrice,
        salePrice: apartment.salePrice,
        description: apartment.description,
        status: apartment.status,
        building: apartment.building
      });

      const exist = checkApartmentExistOnOpenedTab(apartment);
      if (exist) {
        toggleTab("toggle", exist.tabIndex, apartment);
      } else {
        createTab(tabIndex + 1, apartment);
      }
    };

    function handleSelectAll(data) {
      if (data.length > 0) {
        setSelectedApartments(data);
      } else {
        setSelectedApartments([]);
      }
    }

    function handleSelect(data) {
      if (data.length > 0) {
        setSelectedApartments(data);
      } else {
        setSelectedApartments([]);
      }
    }

    const columns = useMemo(
      () => [
        {
          HeaderLabel: <input
            id="mastercheck"
            type="checkbox" />,
          Header: "Action",
          accessor: (cellProps) => {
            const id = cellProps.id;
            return (<input type="checkbox" id={id} />);
          }
        },
        {
          HeaderLabel: `${props.t("Project Name")}`,
          accessor: "building.project.name",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Apartment Type")}`,
          accessor: "type.name",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Building Name")}`,
          accessor: "building.blockName",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Type")}`,
          accessor: "numberOfRooms.numberOfRoom",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Net Square Meters")}`,
          accessor: "netSquareMeters",
          Cell: (cellProps) => {
            return <Segment {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Floor Number")}`,
          accessor: "floorNumber",
          Cell: (cellProps) => {
            return <Surname {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Apartment Number")}`,
          accessor: "apartmentNumber",
          Cell: (cellProps) => {
            return <Name {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Apartment Direction")}`,
          accessor: "direction.name",
          Cell: (cellProps) => {
            return <Facade {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Facade")}`,
          accessor: "facade",
          Cell: (cellProps) => {
            return <Facade {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Status")}`,
          accessor: "status",
          Cell: (cellProps) => {
            const data = cellProps.row.original;
            return (
              <Badge
                key={data.id}
                pill
                className={reservationStatusOptionClass[data.status]}
              >
                {props.t(data.status)}
              </Badge>);
          }
        },
        {
          HeaderLabel: `${props.t("Rental Price")}`,
          accessor: "rentalPrice",
          Cell: (cellProps) => {
            return <Resource {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Sale Price")}`,
          accessor: "salePrice",
          Cell: (cellProps) => {
            return <Resource {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Investor")}`,
          accessor: "investor",
          Cell: (cellProps) => {
            return <BoolValue {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Gross Square Meters")}`,
          accessor: "grossSquareMeters",
          Cell: (cellProps) => {
            return <Phone {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Balcony Count")}`,
          accessor: "balconyCount",
          Cell: (cellProps) => {
            return <City {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Bathroom Count")}`,
          accessor: "bathroomCount",
          Cell: (cellProps) => {
            return <Resource {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Rental")}`,
          accessor: "rental",
          Cell: (cellProps) => {
            return <BoolValue {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Sale")}`,
          accessor: "sale",
          Cell: (cellProps) => {
            return <BoolValue {...cellProps} />;
          }
        },
        {
          HeaderLabel: `${props.t("Description")}`,
          accessor: "description",
          Cell: (cellProps) => {
            return <Category {...cellProps} />;
          }
        }
      ],
      []
    );

    const toggle = () => {
      if (modal) {
        setModal(false);
        setApartment(null);
      } else {
        setModal(true);
        setApartment(null);
      }
    };

    const closeTab = (tab) => {
      const founded = openedTabs.findIndex(item => item.tabIndex === tab.tabIndex);
      const filtered = openedTabs.filter(item => item.tabIndex !== tab.tabIndex);
      setOpenedTabs(filtered);
      if (founded === 0) {
        toggleTab("toggle", 0);
      } else {
        toggleTab("toggle", openedTabs[founded - 1].tabIndex, openedTabs[founded - 1].apartment);
      }
    };

    const checkApartmentExistOnOpenedTab = (custCheck) => {
      if (openedTabs.some(openedTab => openedTab.apartment.id === custCheck.id)) {
        return true;
      }
    };

    const createTab = (tabInd, cust) => {
      openedTabs.push({ tabIndex: tabInd, apartment: cust });
      setOpenedTabs(openedTabs);
      setTabIndex(tabInd);
      setActiveTab(tabInd);
      toggleTab("toggle", tabInd, cust);
    };

    const toggleTab = (action, tab, cust) => {
      if (tab === 0) {
        setActiveTab(0);
        return;
      }
      if (checkApartmentExistOnOpenedTab(cust)) {
        const exist = openedTabs.filter(openedTab => openedTab.apartment.id === cust.id);
        if (action === "toggle") {
          setActiveTab(exist[0].tabIndex);
          setApartment(exist[0].apartment);
        } else {
          closeTab(exist[0]);
        }
      }
    };

    //delete apartment
    const [deleteModal, setDeleteModal] = useState(false);

    const handleRefresh = () => {
      dispatch(onGetApartmentCriteria(body));
    };

    useEffect(() => {
      dispatch(onGetApartmentCriteria(body));
    }, []);

    const handleNavClick = (e, openedTab) => {
      if (e.target.id.split("-")[0] === ("close")) {
        closeTab(openedTab);
      } else {
        toggleTab("toggle", openedTab.tabIndex, openedTab.apartment);
      }
    };
    const handleDeleteApartment = () => {
      setDeleteModal(false);
      selectedApartments.forEach(cust => {
          dispatch(onDeleteApartment(cust));
          toggleTab("close", null, cust);
        }
      );

      handleRefresh();
      setSelectedApartments([]);
    };

    const handleApartmentClicks = () => {
      toggle();
    };

    const callBackClose = (val) => {
      setMessage("");
      setShowNotification(val);
      setCallbackClose(false);
    };

    useEffect(() => {
      setActiveTab(activeTab);
    }, [activeTab]);

    useEffect(() => {
      if (selectedApartments.length === 0) {
        setClearSelected(true);
      } else {
        setClearSelected(false);
      }
    }, [selectedApartments]);

    const closeAllTabs = () => {
      toggleTab("toggle", 0, null);
      setOpenedTabs([]);
    };

    const handleFilteredApartmentData = (req) => {
      if (req) {
        dispatch(onGetApartmentCriteria(req));
      } else {
        dispatch(onGetApartmentCriteria(body));
      }
    };

    function handleClearFilterInputs() {
      setFilterRepresentative({});
      setFilterCreateDateStart("");
      setFilterCreateDateEnd("");
      setFilterUpdateDateStart("");
      setBody(initialBody);
      handleFilteredApartmentData(initialBody);
    }

    function handleMulti(e) {
      setFacade(e);
      setApartment({
        ...apartment,
        ["facade"]: e.value
      });
    }

    const searchBar = (
      <div className="custom-accordion">
        <Link
          className="text-body bg-transparent fw-medium py-1 d-flex align-items-center"
          onClick={toggleFilter}
          to="#"
        >
          <i className="mdi mdi-filter font-size-15 text-secondary me-1"></i>{" "}
          {props.t("Filters")}
          <i
            className={
              isOpen
                ? "mdi mdi-chevron-up accor-down-icon ms-2"
                : "mdi mdi-chevron-down accor-down-icon ms-2"
            }
          />
        </Link>
        <Collapse isOpen={isOpen}>
          <div className="row">
            <div className="container col-11 mt-1 d-inline-block align-content-end">
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Description")}</Label>
                  <Input
                    name="filterDescription"
                    type="text"
                    value={filterDescription}
                    onChange={(e) => handleChangeFilterDescription(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label
                    className="form-label">{props.t("Building")}</Label>
                  <Select
                    id="rental"
                    value={filterBuilding}
                    onChange={(e) => handleChangeFilterBuildingSelect(e)}
                    options={buildingOptions}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Project")}</Label>
                  <Select
                    id="project"
                    value={filterProject}
                    onChange={(e) => handleChangeFilterProject(e)}
                    options={projectOptions}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-3">
                  <Label className="form-label">{props.t("Apartment Number")}</Label>
                  <Select
                    id="number"
                    value={filterApartmentNumber}
                    onChange={(e) => handleChangeFilterApartmentNumber(e)}
                    options={apartmentNumberOptions}
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Floor Number")}</Label>
                  <Select
                    id="floorNumber"
                    value={filterFloorNumber}
                    onChange={(e) => handleChangeFilterFloorNumber(e)}
                    options={floorNumberOptions}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Status")}</Label>
                  <Select
                    id="statusss"
                    value={filterStatus}
                    onChange={(e) => handleFilterStatus(e)}
                    options={statusOptions}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Gross Square Meters Start")}</Label>
                  <Input
                    name="grossSquareMetersStart"
                    type="text"
                    value={filterGrossSquareMetersStart}
                    onChange={(e) => handleChangeFilterGrossSquareMetersStart(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Gross Square Meters End")}</Label>
                  <Input
                    name="grossSquareMetersEnd"
                    type="text"
                    value={filterGrossSquareMetersEnd}
                    onChange={(e) => handleChangeFilterGrossSquareMetersEnd(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Net Square Meters Start")}</Label>
                  <Input
                    name="netSquareMeters"
                    type="text"
                    value={filterNetSquareMetersStart}
                    onChange={(e) => handleChangeFilterNetSquareMetersStart(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Net Square Meters End")}</Label>
                  <Input
                    name="netSquareMetersEnd"
                    type="text"
                    value={filterNetSquareMetersEnd}
                    onChange={(e) => handleChangeFilterNetSquareMetersEnd(e)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Balcony Count")}</Label>
                  <Input
                    name="country"
                    type="text"
                    value={filterBalconyCount}
                    onChange={(e) => handleChangeFilterBalconyCount(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Bathroom Count")}</Label>
                  <Input
                    name="country"
                    type="text"
                    value={filterBathroomCount}
                    onChange={(e) => handleChangeFilterBathroomCount(e)}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label
                    className="form-label">{props.t("Rental")}</Label>
                  <Select
                    id="rental"
                    value={filterRental}
                    onChange={(e) => handleChangeFilterIsRental(e)}
                    options={[{ label: props.t("YES"), value: true }, {
                      label: props.t("NO"),
                      value: false
                    }]}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label
                    className="form-label">{props.t("Sale")}</Label>
                  <Select
                    id="sale"
                    value={filterSale}
                    onChange={(e) => handleChangeFilterIsSale(e)}
                    options={[{ label: props.t("YES"), value: true }, {
                      label: props.t("NO"),
                      value: false
                    }]}
                    isClearable={true}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label className="form-label">{props.t("Type")}</Label>
                  <Select
                    isMulti={true}
                    className="bs-select"
                    value={filterNumberOfRoomsStart}
                    onChange={(e) => handleChangeFilterNumberOfRoomsStart(e)}
                    options={numberOfRoomOptions}
                  />
                </div>
                <div className="mb-3 col-xs-12 col-lg-2">
                  <Label
                    className="form-label">{props.t("Facade")}</Label>
                  <Select
                    id="facade"
                    isMulti={true}
                    value={filterFacade}
                    onChange={(e) => handleFilterFacade(e)}
                    options={[
                      { label: props.t("NORTH"), value: "NORTH" },
                      { label: props.t("SOUTH"), value: "SOUTH" },
                      { label: props.t("EAST"), value: "EAST" },
                      { label: props.t("WEST"), value: "WEST" }
                    ]}
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="row">
                {/*
                                <div className="mb-3 col-xs-12 col-lg-3">
                                    <Label className="form-label">{props.t("Create Date Start")}</Label>
                                    <Input
                                        id="start-date"
                                        name="start-date"
                                        type="date"
                                        onChange={e => handleFilterCreateDateStart(e)}
                                        value={filterCreateDateStart}
                                    />
                                </div>
                                <div className="mb-3 col-xs-12 col-lg-3">
                                    <Label className="form-label">{props.t("Create Date End")}</Label>
                                    <Input
                                        id="end-date"
                                        name="end-date"
                                        type="date"
                                        onChange={e => handleFilterCreateDateEnd(e)}
                                        value={filterCreateDateEnd}
                                    />
                                </div>

                                <div className="mb-3 col-xs-12 col-lg-3">
                                    <Label className="form-label">{props.t("Update Date Start")}</Label>
                                    <Input
                                        id="update-date-start"
                                        name="update-date-start"
                                        type="date"
                                        onChange={e => handleFilterUpdateDateStart(e)}
                                        value={filterUpdateDateStart}
                                    />
                                </div>*/}
              </div>
            </div>
            <div className="col-1 mt-5 d-inline-block align-content-end">
              <Button
                type="button"
                color="success"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleFilteredApartmentData()}
              >
                <i className="mdi mdi-search-web me-1" />
                {props.t("Filter")}
              </Button>
              <Button
                type="button"
                color="warning"
                className="btn-rounded mb-2 me-0"
                onClick={() => handleClearFilterInputs()}
              >
                <i className="mdi mdi-format-clear me-1" />
                {props.t("Clear All Filters")}
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    );

    function handlePreviousPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage - 1 });
      dispatch(onGetApartmentCriteria(changedBody));
      setClearSelected(true);
    }

    function handleNextPage(currentPage) {
      const changedBody = Object.assign(body, { size: size, page: currentPage + 1 });
      dispatch(onGetApartmentCriteria(changedBody));
      setClearSelected(true);
    }

    function handleSizePerPage() {
      setSizeButtonDisabled(true);
      setSize(dataPerPage);
      const changedBody = Object.assign(body, { size: dataPerPage, page: 0 });
      dispatch(onGetApartmentCriteria(changedBody));
      setClearSelected(true);
    }

    const toolbar = (
      <div id="toolbar" className="col-12 d-inline-block align-content-end">
        {selectedApartments.length > 0 && (
          <div className="container">
            <Button
              id="delete"
              type="button"
              className="btn-rounded btn-danger m-1"
              onClick={() => setDeleteModal(true)}
            >
              <i className="mdi mdi-delete me-1" />
              {props.t("Delete")}
            </Button>
          </div>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <WarningModal
          id="deleteModal"
          show={deleteModal}
          onApproveClick={handleDeleteApartment}
          onCloseClick={() => setDeleteModal(false)}
        />
        <div id="container" className="container-fluid">
          <Notification
            type={notificationType}
            message={message}
            show={showNotification}
            callbackShow={callBackClose}
          />
          <Breadcrumbs title={props.t("Apartments")} />
          <Row>
            <Card id="custList">
              <CardTitle>
                <Row className="mt-4">
                  <Col lg="10">
                  </Col>
                  <Col lg="2">
                    {openedTabs.length > 0 ? (
                      <Button
                        id="closeTabs"
                        type="button"
                        color="warning"
                        className="btn-rounded btn-sm mt-2"
                        onClick={() => closeAllTabs()}
                      >
                        <i className="mdi mdi-crosshairs me-1" />
                        {props.t("Close All Tabs")}
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </CardTitle>
              <CardBody>
                <div className="crypto-buy-sell-nav" id="contain">
                  <Nav tabs className="nav-tabs-custom" role="tablist">
                    <NavItem key={0}>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0
                        })}
                        onClick={() => {
                          toggleTab("toggle", 0);
                        }}
                      >
                        {props.t("Apartments")}
                      </NavLink>
                    </NavItem>
                    {openedTabs.length > 0 ? (
                      openedTabs.map((openedTab, key) => (
                        <NavItem id={`nav-item${key}`} key={key} className="nav-item">
                          <NavLink
                            id={`link-${key}`}
                            className={classnames({
                              active: activeTab === openedTab.tabIndex
                            }, "nav-link")}
                            onClick={(e) => handleNavClick(e, openedTab)}
                          >
                            <span>{`${openedTab.apartment.building.blockName} - ${openedTab.apartment && openedTab.apartment.numberOfRooms ? openedTab.apartment.numberOfRooms.numberOfRoom : openedTab.apartment.description}`} </span>
                            <CloseButton
                              id={`close-${key}`}
                              className="btn-xs p-0"
                              onClick={(event) => handleNavClick(event, openedTab)}
                            />
                          </NavLink>
                        </NavItem>
                      ))
                    ) : null}
                  </Nav>
                  <TabContent
                    id="tab-content"
                    activeTab={activeTab}
                    className="p-3"
                  >
                    <TabPane tabId={0} id="custListTab">
                      {searchBar}
                      <React.Fragment>
                        <Col className="col-12 mt-2">
                          {props.t("Total Data Count")} = {totalCount}
                        </Col>
                        <div>
                          <CustomSelectableTable
                            refreshButton={true}
                            handleRefreshClick={handleRefresh}
                            columns={columns}
                            data={apartments || []}
                            isAddOptions={true}
                            handleAddClick={handleApartmentClicks}
                            handleRowClick={handleApartmentClick}
                            customPageSize={size}
                            handleChangeSelect={handleSelect}
                            handleChangeSelectAll={handleSelectAll}
                            toolbar={toolbar}
                            clearAllSelected={clearSelected}
                            className="custom-header-css"
                            isExportEnabled={authUser && authUser.role && authUser.role.name === "ROLE_MODERATOR"}
                          />
                          <Row className="col-12 mb-5">
                            <Col className="col-3">
                              <InputGroup>
                                <Input
                                  min={0}
                                  style={{ width: 70 }}
                                  max={totalPages}
                                  value={dataPerPage}
                                  onChange={(e) => setDataPerPage(e.target.value)}
                                />
                                <Button size="sm" className="btn btn-outline-secondary"
                                        disabled={sizeButtonDisabled}
                                        onClick={handleSizePerPage}>{props.t("Set Size")}
                                </Button>
                              </InputGroup>
                            </Col>
                            <Col className="col-6" />
                            <Col className="col-3 text-end">
                              <div className="btn-group me-0" role="group">
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === 0}
                                        onClick={() => handlePreviousPage(page)}>
                                  <i className="bx bx-chevron-left"></i>
                                </button>
                                <button type="button" className="btn btn-outline-secondary">
                                  {page + 1}
                                </button>
                                <button type="button" className="btn btn-outline-secondary"
                                        disabled={page === totalPages}
                                        onClick={() => handleNextPage(page)}>
                                  <i className="bx bx-chevron-right"></i>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <Modal isOpen={modal} toggle={toggle} size="xl">
                          <ModalHeader toggle={toggle} tag="h4">
                            {props.t("Add")}
                          </ModalHeader>
                          <ModalBody>
                            <Form
                              id="new-apartment"
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                return false;
                              }}
                            >
                              <Row form="true">
                                <Row className="row">
                                  <Col className="col-4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Description")}</Label>
                                      <Input
                                        id="description"
                                        name="description"
                                        type="textarea"
                                        rows="1"
                                        onChange={handleChange}
                                        value={apartment && apartment.description || ""}
                                      />
                                    </div>
                                  </Col>
                                  <Col className="col-4">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label">{props.t("Status")}</Label>
                                      <Select
                                        id="statusSelect"
                                        value={status}
                                        onChange={(e) => {
                                          onChangeInStatusSelect(e);
                                        }}
                                        options={statusOptions}
                                        isClearable={true}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row className="row-cols-4">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Building")}</Label>
                                    <Select
                                      id="building"
                                      value={building}
                                      onChange={(e) => {
                                        onChangeInBuildingSelect(e);
                                      }}
                                      options={buildingObjectOptions}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Apartment Type")}</Label>
                                    <Select
                                      id="apartmentType"
                                      value={apartmentType}
                                      onChange={(e) => {
                                        onChangeInApartmentTypeSelect(e);
                                      }}
                                      options={apartmentTypeOptions}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Apartment Direction")}</Label>
                                    <Select
                                      id="apartmentDirection"
                                      value={apartmentDirection}
                                      onChange={(e) => {
                                        onChangeInApartmentDirectionSelect(e);
                                      }}
                                      options={apartmentDirectionOptions}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Investor")}</Label>
                                    <Select
                                      id="investor"
                                      value={{
                                        label: apartment && apartment.investor === true ? props.t("YES") : props.t("NO"),
                                        value: apartment && apartment.investor
                                      }}
                                      onChange={(e) => {
                                        setApartment({
                                          ...apartment,
                                          investor: e.value
                                        });
                                      }}
                                      options={[{
                                        label: props.t("YES"),
                                        value: true
                                      }, { label: props.t("NO"), value: false }]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Row>
                                <Row className="row-cols-4">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Type")}</Label>
                                    <Select
                                      id="numberOfRooms"
                                      value={numberOfRooms}
                                      onChange={(e) => {
                                        onChangeInNumberOfRoomsSelect(e);
                                      }}
                                      options={apartmentRoomOptions}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Floor Number")}</Label>
                                    <Input
                                      id="floorNumber"
                                      name="floorNumber"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.floorNumber || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Apartment Number")}</Label>
                                    <Input
                                      id="apartmentNumber"
                                      name="apartmentNumber"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.apartmentNumber || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Facade")}</Label>
                                    <Select
                                      id="facade"
                                      value={facade}
                                      onChange={(e) => handleMulti(e)}
                                      options={[
                                        { label: props.t("NORTH"), value: "NORTH" },
                                        { label: props.t("SOUTH"), value: "SOUTH" },
                                        { label: props.t("EAST"), value: "EAST" },
                                        { label: props.t("WEST"), value: "WEST" }
                                      ]}
                                      isClearable={true}
                                    />
                                  </div>
                                </Row>
                                <Row className="row-cols-4">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Balcony Count")}</Label>
                                    <Input
                                      id="balconyCount"
                                      name="balconyCount"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.balconyCount || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Bathroom Count")}</Label>
                                    <Input
                                      id="bathroomCount"
                                      name="bathroomCount"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.bathroomCount || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Gross Square Meters")}</Label>
                                    <Input
                                      id="grossSquareMeters"
                                      name="grossSquareMeters"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.grossSquareMeters || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Net Square Meters")}</Label>
                                    <Input
                                      id="netSquareMeters"
                                      name="netSquareMeters"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.netSquareMeters || ""}
                                    />
                                  </div>
                                </Row>
                                <Row className="row-cols-4">
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Rental")}</Label>
                                    <Select
                                      id="rental"
                                      value={{
                                        label: apartment && apartment.rental === true ? props.t("YES") : props.t("NO"),
                                        value: apartment && apartment.rental
                                      }}
                                      onChange={(e) => {
                                        setApartment({
                                          ...apartment,
                                          rental: e.value
                                        });
                                      }}
                                      options={[{
                                        label: props.t("YES"),
                                        value: true
                                      }, { label: props.t("NO"), value: false }]}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Sale")}</Label>
                                    <Select
                                      id="sale"
                                      value={{
                                        label: apartment && apartment.sale === true ? props.t("YES") : props.t("NO"),
                                        value: apartment && apartment.sale
                                      }}
                                      onChange={(e) => {
                                        setApartment({
                                          ...apartment,
                                          sale: e.value
                                        });
                                      }}
                                      options={[{
                                        label: props.t("YES"),
                                        value: true
                                      }, { label: props.t("NO"), value: false }]}
                                      isClearable={true}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Rental Price")}</Label>
                                    <Input
                                      id="rentalPrice"
                                      name="rentalPrice"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.rentalPrice || ""}
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label">{props.t("Sale Price")}</Label>
                                    <Input
                                      id="salePrice"
                                      name="salePrice"
                                      type="text"
                                      onChange={handleChange}
                                      value={apartment && apartment.salePrice || ""}
                                    />
                                  </div>
                                </Row>
                              </Row>
                              <Row>
                                <Col>
                                  <div className="text-end">
                                    <button
                                      id="save"
                                      type="submit"
                                      className="btn btn-success save-apartment"
                                    >
                                      {props.t("Save")}
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </ModalBody>
                        </Modal>
                      </React.Fragment>
                    </TabPane>
                    {openedTabs.map((openedTab, key) =>
                      (
                        <TabPane key={key} tabId={openedTab.tabIndex}
                                 id={`apartmentDetail${openedTab.apartment.id}`}>
                          <ApartmentDetail
                            id={openedTab.apartment.id}
                            apartment={openedTab.apartment}
                            roomsOptions={apartmentRoomOptions}
                            buildingOptions={buildingObjectOptions}
                            statusOptions={statusOptions}
                            typeOptions={apartmentTypeOptions}
                            directionOptions={apartmentDirectionOptions}
                          />
                        </TabPane>
                      ))}
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </Row>
        </div>
      </React.Fragment>
    );
  }
;
Apartment.propTypes = {
  t: PropTypes.any,
  apartments: PropTypes.array,
  onAddNewApartment: PropTypes.func,
  onDeleteApartment: PropTypes.func,
  onUpdateApartment: PropTypes.func,
  onGetSegments: PropTypes.func,
  segmentId: PropTypes.any,
  resourceId: PropTypes.any
};

export default withRouter(withTranslation()(Apartment));
