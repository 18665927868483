import {
    ADD_BUILDING_FAIL,
    ADD_BUILDING_SUCCESS,
    DELETE_BUILDING_FAIL,
    DELETE_BUILDING_SUCCESS,
    GET_BUILDINGS_FAIL,
    GET_BUILDINGS_SUCCESS,
    UPDATE_BUILDING_FAIL,
    UPDATE_BUILDING_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    buildings: [],
    error: {},
};

const Building = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_BUILDINGS_SUCCESS:
            return {
                ...state,
                buildings: action.payload,
            };

        case GET_BUILDINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_BUILDING_SUCCESS:
            return {
                ...state,
                buildings: [...state.buildings, action.payload],
            };

        case ADD_BUILDING_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_BUILDING_SUCCESS:
            return {
                ...state,
                buildings: state.buildings.map(building =>
                    building.id.toString() === action.payload.id.toString()
                        ? { building, ...action.payload }
                        : building
                ),
            };

        case UPDATE_BUILDING_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_BUILDING_SUCCESS:
            return {
                ...state,
                buildings: state.buildings.filter(
                    building => building.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_BUILDING_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default Building;
