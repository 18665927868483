import {
  ADD_NEW_APARTMENT_RESERVATION,
  ADD_APARTMENT_RESERVATION_FAIL,
  ADD_APARTMENT_RESERVATION_SUCCESS,
  DELETE_APARTMENT_RESERVATION,
  DELETE_APARTMENT_RESERVATION_FAIL,
  DELETE_APARTMENT_RESERVATION_SUCCESS,
  GET_LEAD_APARTMENT_RESERVATIONS,
  GET_LEAD_APARTMENT_RESERVATIONS_FAIL,
  GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS,
  GET_APARTMENT_RESERVATION_CRITERIA,
  GET_APARTMENT_RESERVATION_CRITERIA_FAIL,
  GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS,
  GET_APARTMENT_RESERVATIONS,
  GET_APARTMENT_RESERVATIONS_FAIL,
  GET_APARTMENT_RESERVATIONS_SUCCESS,
  GET_TODAY_APARTMENT_RESERVATIONS,
  GET_TODAY_APARTMENT_RESERVATIONS_FAIL,
  GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS,
  GET_USER_APARTMENT_RESERVATIONS,
  GET_USER_APARTMENT_RESERVATIONS_FAIL,
  GET_USER_APARTMENT_RESERVATIONS_SUCCESS,
  UPDATE_APARTMENT_RESERVATION,
  UPDATE_APARTMENT_RESERVATION_FAIL,
  UPDATE_APARTMENT_RESERVATION_SUCCESS
} from "./actionTypes";

export const getApartmentReservations = () => ({
  type: GET_APARTMENT_RESERVATIONS
});

export const getApartmentReservationsSuccess = apartmentReservations => ({
  type: GET_APARTMENT_RESERVATIONS_SUCCESS,
  payload: apartmentReservations
});

export const getApartmentReservationsFail = error => ({
  type: GET_APARTMENT_RESERVATIONS_FAIL,
  payload: error
});

export const addNewApartmentReservation = apartmentReservation => ({
  type: ADD_NEW_APARTMENT_RESERVATION,
  payload: apartmentReservation
});

export const addApartmentReservationSuccess = apartmentReservation => ({
  type: ADD_APARTMENT_RESERVATION_SUCCESS,
  payload: apartmentReservation
});

export const addApartmentReservationFail = error => ({
  type: ADD_APARTMENT_RESERVATION_FAIL,
  payload: error
});

export const updateApartmentReservation = apartmentReservation => ({
  type: UPDATE_APARTMENT_RESERVATION,
  payload: apartmentReservation
});

export const updateApartmentReservationSuccess = apartmentReservation => ({
  type: UPDATE_APARTMENT_RESERVATION_SUCCESS,
  payload: apartmentReservation
});

export const updateApartmentReservationFail = error => ({
  type: UPDATE_APARTMENT_RESERVATION_FAIL,
  payload: error
});

export const deleteApartmentReservation = id => ({
  type: DELETE_APARTMENT_RESERVATION,
  id
});

export const deleteApartmentReservationSuccess = (apartmentReservation,req) => ({
  type: DELETE_APARTMENT_RESERVATION_SUCCESS,
  payload: apartmentReservation,
  req: req
});

export const deleteApartmentReservationFail = error => ({
  type: DELETE_APARTMENT_RESERVATION_FAIL,
  payload: error
});

export const getLeadApartmentReservations = id => ({
  type: GET_LEAD_APARTMENT_RESERVATIONS,
  id
});

export const getLeadApartmentReservationsSuccess = apartmentReservation => ({
  type: GET_LEAD_APARTMENT_RESERVATIONS_SUCCESS,
  payload: apartmentReservation
});

export const getLeadApartmentReservationsFail = error => ({
  type: GET_LEAD_APARTMENT_RESERVATIONS_FAIL,
  payload: error
});

export const getUserApartmentReservations = id => ({
  type: GET_USER_APARTMENT_RESERVATIONS,
  id
});

export const getUserApartmentReservationsSuccess = apartmentReservation => ({
  type: GET_USER_APARTMENT_RESERVATIONS_SUCCESS,
  payload: apartmentReservation
});

export const getUserApartmentReservationsFail = error => ({
  type: GET_USER_APARTMENT_RESERVATIONS_FAIL,
  payload: error
});

export const getTodayApartmentReservations = () => ({
  type: GET_TODAY_APARTMENT_RESERVATIONS
});

export const getTodayApartmentReservationsSuccess = apartmentReservation => ({
  type: GET_TODAY_APARTMENT_RESERVATIONS_SUCCESS,
  payload: apartmentReservation
});

export const getTodayApartmentReservationsFail = error => ({
  type: GET_TODAY_APARTMENT_RESERVATIONS_FAIL,
  payload: error
});


export const getApartmentReservationCriteria = apartmentReservation => ({
  type: GET_APARTMENT_RESERVATION_CRITERIA,
  payload: apartmentReservation
});

export const getApartmentReservationCriteriaSuccess = apartmentReservation => ({
  type: GET_APARTMENT_RESERVATION_CRITERIA_SUCCESS,
  payload: apartmentReservation
});

export const getApartmentReservationCriteriaFail = error => ({
  type: GET_APARTMENT_RESERVATION_CRITERIA_FAIL,
  payload: error
});
