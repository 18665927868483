import {
    ADD_APARTMENT_ROOM_FAIL,
    ADD_APARTMENT_ROOM_SUCCESS,
    ADD_NEW_APARTMENT_ROOM,
    APARTMENT_ROOM_CRITERIA,
    APARTMENT_ROOM_CRITERIA_FAIL,
    APARTMENT_ROOM_CRITERIA_SUCCESS,
    DELETE_APARTMENT_ROOM,
    DELETE_APARTMENT_ROOM_FAIL,
    DELETE_APARTMENT_ROOM_SUCCESS,
    GET_APARTMENT_ROOMS,
    GET_APARTMENT_ROOMS_FAIL,
    GET_APARTMENT_ROOMS_SUCCESS,
    UPDATE_APARTMENT_ROOM,
    UPDATE_APARTMENT_ROOM_FAIL,
    UPDATE_APARTMENT_ROOM_SUCCESS
} from "./actionTypes";

export const getApartmentRooms = () => ({
    type: GET_APARTMENT_ROOMS,
})

export const getApartmentRoomsSuccess = apartments => ({
    type: GET_APARTMENT_ROOMS_SUCCESS,
    payload: apartments,
})

export const getApartmentRoomsFail = error => ({
    type: GET_APARTMENT_ROOMS_FAIL,
    payload: error,
})

export const addNewApartmentRoom = apartment => ({
    type: ADD_NEW_APARTMENT_ROOM,
    payload: apartment,
})

export const addApartmentRoomSuccess = apartment => ({
    type: ADD_APARTMENT_ROOM_SUCCESS,
    payload: apartment,
})

export const addApartmentRoomFail = error => ({
    type: ADD_APARTMENT_ROOM_FAIL,
    payload: error,
})

export const updateApartmentRoom = apartment => ({
    type: UPDATE_APARTMENT_ROOM,
    payload: apartment,
})

export const updateApartmentRoomSuccess = apartment => ({
    type: UPDATE_APARTMENT_ROOM_SUCCESS,
    payload: apartment,
})

export const updateApartmentRoomFail = error => ({
    type: UPDATE_APARTMENT_ROOM_FAIL,
    payload: error,
})

export const deleteApartmentRoom = id => ({
    type: DELETE_APARTMENT_ROOM,
    id,
})

export const deleteApartmentRoomSuccess = (apartment,req) => ({
    type: DELETE_APARTMENT_ROOM_SUCCESS,
    payload: apartment,
    req: req,
})

export const deleteApartmentRoomFail = error => ({
    type: DELETE_APARTMENT_ROOM_FAIL,
    payload: error,
})
