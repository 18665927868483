
/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

/**
 * add CUSTOMER
 */
export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER"
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS"
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL"

/**
 * Edit CUSTOMER
 */
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL"

/**
 * Delete CUSTOMER
 */
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL"

/**
 * Lead Criteria
 */
export const CUSTOMER_CRITERIA = "CUSTOMER_CRITERIA"
export const CUSTOMER_CRITERIA_SUCCESS = "CUSTOMER_CRITERIA_SUCCESS"
export const CUSTOMER_CRITERIA_FAIL = "CUSTOMER_CRITERIA_FAIL"

/**
 * FIND FOLTERS
 */
export const CUSTOMER_FIND_FILTERS = "CUSTOMER_FIND_FILTERS"
export const CUSTOMER_FIND_FILTERS_SUCCESS = "CUSTOMER_FIND_FILTERS_SUCCESS"
export const CUSTOMER_FIND_FILTERS_FAIL = "CUSTOMER_FIND_FILTERS_FAIL"