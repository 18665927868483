import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    UncontrolledTooltip
} from "reactstrap";


import {Description, Name} from "./apartmentTypeCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
    addNewApartmentType as onAddNewApartmentType,
    deleteApartmentType as onDeleteApartmentType,
    getApartmentTypes as onGetApartmentTypes,
    updateApartmentType as onUpdateApartmentType
} from "store/apartmentType/actions";

//redux
import {useDispatch, useSelector} from "react-redux";
import {withTranslation} from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";

const ApartmentType = props => {

    //meta title
    document.title = `CRM | ${props.t("Apartment Rooms")}`;

    const initialApartmentType = {
        name: "",
    }

    const dispatch = useDispatch();
    const [deleteApartmentType, setDeleteApartmentType] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [apartmentType, setApartmentType] = useState(initialApartmentType);
    const [showNotification, setShowNotification] = useState(false);
    const [message, setMessage] = useState("");
    const [notificationType, setNotificationType] = useState("Info");

    const {apartmentTypes, error} = useSelector(state => ({
        apartmentTypes: state.ApartmentType.apartmentTypes,
        error: state.ApartmentType.error
    }));

    useEffect(() => {
        dispatch(onGetApartmentTypes());
    }, []);


    const handleRefresh = () => {
        dispatch(onGetApartmentTypes());
    };

    const columns = useMemo(
        () => [
            {
                HeaderLabel: `${props.t("Name")}`,
                accessor: "name",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                }
            },
            {
                HeaderLabel: `${props.t("Action")}`,
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-warning"
                                onClick={() => {
                                    setApartmentType(cellProps.row.original),
                                        handleApartmentTypeClick(cellProps.row);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip"/>
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    {props.t("Edit")}
                                </UncontrolledTooltip>
                            </Link>
                            <Link
                                to="#"
                                className="text-danger"
                                onClick={() => {
                                    const apartmentTypeData = cellProps.row.original;
                                    onClickDelete(apartmentTypeData);
                                }}
                            >
                                <i className="mdi mdi-delete font-size-18" id="deletetooltip"/>
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    {props.t("Delete")}
                                </UncontrolledTooltip>
                            </Link>
                        </div>
                    );
                }
            }
        ],
        []
    );

    const toggle = () => {
        if (modal) {
            setApartmentType(initialApartmentType);
            setModal(false);
            setIsEdit(false);
        } else {
            setModal(true);
        }
    };

    const handleApartmentTypeClick = arg => {
        const apartmentType = arg.original;
        setIsEdit(true);
        setApartmentType({
            id: apartmentType.id,
            name: apartmentType.name
        });
        toggle();
    };

    //delete lead
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = apartmentType => {
        setDeleteApartmentType(apartmentType);
        setDeleteModal(true);
    };

    const handleDeleteApartmentType = () => {
        dispatch(onDeleteApartmentType(deleteApartmentType.id));
        setDeleteModal(false);
    };

    const handleChange = (e) => {
        setApartmentType({
            ...apartmentType,
            [e.target.name]: e.target.value
        });
    };

    const handleClose = () => {
        setMessage("");
        setShowNotification(false);
    };

    const saveApartmentType = () => {
        if (isEdit) {
            dispatch(onUpdateApartmentType(apartmentType));
        } else {
            dispatch(onAddNewApartmentType(apartmentType));
        }
        toggle();
    };

    useEffect(() => {
        if (Object.entries(error).length > 0) {
            setMessage(error.message);
            setShowNotification(true);
            setNotificationType("Danger");
        }
    }, [error]);

    return (
        <React.Fragment>
            <WarningModal
                show={deleteModal}
                onApproveClick={handleDeleteApartmentType}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Notification
                message={message}
                show={showNotification}
                callbackShow={handleClose}
                type={notificationType}
            />
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title={props.t("Apartment Types")}/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <TableContainer
                                        refreshButton={true}
                                        handleRefreshClick={handleRefresh}
                                        columns={columns}
                                        data={apartmentTypes}
                                        isGlobalFilter={true}
                                        isAddOptions={true}
                                        handleAddClick={toggle}
                                        customPageSize={25}
                                        className="custom-header-css"
                                    />
                                    <Modal isOpen={modal} toggle={toggle}>
                                        <ModalHeader toggle={toggle} tag="h4">
                                            {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                                        </ModalHeader>
                                        <ModalBody>
                                            <Row form="true">
                                                <Col xs={12}>
                                                    <div className="mb-3">
                                                        <Label className="form-label">{props.t("Name")}</Label>
                                                        <Input
                                                            name="name"
                                                            type="text"
                                                            onChange={handleChange}
                                                            value={apartmentType.name || ""}
                                                            invalid={!apartmentType.name}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="text-end">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success save-apartmentType"
                                                            onClick={saveApartmentType}
                                                        >
                                                            {props.t("Save")}
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ModalBody>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ApartmentType));
