import {call, put, takeEvery} from "redux-saga/effects";

// CustomerAppointments Redux States
import {
  ADD_NEW_CUSTOMER_APPOINTMENT,
  ADD_NEW_CUSTOMER_WITH_APPT,
  DELETE_CUSTOMER_APPOINTMENT,
  GET_CUSTOMER_APPOINTMENT_CRITERIA,
  GET_CUSTOMER_APPOINTMENTS,
  GET_USER_CUSTOMER_APPOINTMENTS,
  UPDATE_CUSTOMER_APPOINTMENT
} from "./actionTypes";

import {
  addCustomerAppointmentFail,
  addCustomerAppointmentSuccess,
  addNewCustomerWithAppointmentFail,
  addNewCustomerWithAppointmentSuccess,
  deleteCustomerAppointmentFail,
  deleteCustomerAppointmentSuccess,
  getCustomerAppointmentCriteriaFail,
  getCustomerAppointmentCriteriaSuccess,
  getCustomerAppointmentsFail,
  getCustomerAppointmentsSuccess,
  getUserCustomerAppointmentsFail,
  getUserCustomerAppointmentsSuccess,
  updateCustomerAppointmentFail,
  updateCustomerAppointmentSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import {del, get, post} from "../../helpers/api_helper";

function* fetchCustomerAppointments() {
  try {
    const response = yield call(getCustomerAppointments);
    yield put(getCustomerAppointmentsSuccess(response));
  } catch (error) {
    yield put(getCustomerAppointmentsFail(error));
  }
}

const getCustomerAppointments = () => get(`${url.CUSTOMER_APPT_GET_ALL}`);

function* onUpdateCustomerAppointment({ payload: customerAppointment }) {
  try {
    const response = yield call(updateCustomerAppointment, customerAppointment);
    yield put(updateCustomerAppointmentSuccess(response));
  } catch (error) {
    yield put(updateCustomerAppointmentFail(error));
  }
}

const updateCustomerAppointment = (customerAppointment) => post(url.CUSTOMER_APPT_API, customerAppointment);

function* onDeleteCustomerAppointment({ id }) {
  try {
    const response = yield call(deleteCustomerAppointment, id);
    yield put(deleteCustomerAppointmentSuccess(response,id));
  } catch (error) {
    yield put(deleteCustomerAppointmentFail(error));
  }
}

const deleteCustomerAppointment = (id) => del(`${url.CUSTOMER_APPT_API}/${id}`);

function* onAddNewCustomerAppointment({ payload: customerAppointment }) {
  try {
    const response = yield call(addNewCustomerAppointment, customerAppointment);

    yield put(addCustomerAppointmentSuccess(response));
  } catch (error) {
    yield put(addCustomerAppointmentFail(error));
  }
}

const addNewCustomerAppointment = (customerAppointment) => post(url.CUSTOMER_APPT_API, customerAppointment);

function* fetchUserCustomerAppointments({ id }) {
  try {
    const response = yield call(getUserCustomerAppointments, id);
    yield put(getUserCustomerAppointmentsSuccess(response));
  } catch (error) {
    yield put(getUserCustomerAppointmentsFail(error));
  }
}

const getUserCustomerAppointments = (id) => get(`${url.CUSTOMER_APPT_API}/${id}`);

function* fetchCustomerAppointmentCriteria({ payload: body }) {
  try {
    const response = yield call(getCustomerAppointmentCriteria, body);
    yield put(getCustomerAppointmentCriteriaSuccess(response));
  } catch (error) {
    yield put(getCustomerAppointmentCriteriaFail(error));
  }
}

const getCustomerAppointmentCriteria = (body) => post(url.CUSTOMER_APPT_CRITERIA, body);

function* onAddNewCustomerWithAppt({ payload: customer }) {
  try {
    const response = yield call(addNewCustomerWithAppt, customer);
    yield put(addNewCustomerWithAppointmentSuccess(response));
  } catch (error) {
    yield put(addNewCustomerWithAppointmentFail(error));
  }
}

const addNewCustomerWithAppt = (customer) => post(url.SAVE_WITH_APPT, customer);

function* customerAppointmentsSaga() {
  yield takeEvery(GET_CUSTOMER_APPOINTMENTS, fetchCustomerAppointments);
  yield takeEvery(ADD_NEW_CUSTOMER_APPOINTMENT, onAddNewCustomerAppointment);
  yield takeEvery(UPDATE_CUSTOMER_APPOINTMENT, onUpdateCustomerAppointment);
  yield takeEvery(DELETE_CUSTOMER_APPOINTMENT, onDeleteCustomerAppointment);
  yield takeEvery(GET_USER_CUSTOMER_APPOINTMENTS, fetchUserCustomerAppointments);
  yield takeEvery(GET_CUSTOMER_APPOINTMENT_CRITERIA, fetchCustomerAppointmentCriteria);
  yield takeEvery(ADD_NEW_CUSTOMER_WITH_APPT, onAddNewCustomerWithAppt);
}

export default customerAppointmentsSaga;
