
/* CUSTOMER_APPOINTMENTS */
export const GET_CUSTOMER_APPOINTMENTS = "GET_CUSTOMER_APPOINTMENTS"
export const GET_CUSTOMER_APPOINTMENTS_SUCCESS = "GET_CUSTOMER_APPOINTMENTS_SUCCESS"
export const GET_CUSTOMER_APPOINTMENTS_FAIL = "GET_CUSTOMER_APPOINTMENTS_FAIL"

/**
 * add CUSTOMER_APPOINTMENT
 */
export const ADD_NEW_CUSTOMER_APPOINTMENT = "ADD_NEW_CUSTOMER_APPOINTMENT"
export const ADD_CUSTOMER_APPOINTMENT_SUCCESS = "ADD_CUSTOMER_APPOINTMENT_SUCCESS"
export const ADD_CUSTOMER_APPOINTMENT_FAIL = "ADD_CUSTOMER_APPOINTMENT_FAIL"

/**
 * Edit CUSTOMER_APPOINTMENT
 */
export const UPDATE_CUSTOMER_APPOINTMENT = "UPDATE_CUSTOMER_APPOINTMENT"
export const UPDATE_CUSTOMER_APPOINTMENT_SUCCESS = "UPDATE_CUSTOMER_APPOINTMENT_SUCCESS"
export const UPDATE_CUSTOMER_APPOINTMENT_FAIL = "UPDATE_CUSTOMER_APPOINTMENT_FAIL"

/**
 * Delete CUSTOMER_APPOINTMENT
 */
export const DELETE_CUSTOMER_APPOINTMENT = "DELETE_CUSTOMER_APPOINTMENT"
export const DELETE_CUSTOMER_APPOINTMENT_SUCCESS = "DELETE_CUSTOMER_APPOINTMENT_SUCCESS"
export const DELETE_CUSTOMER_APPOINTMENT_FAIL = "DELETE_CUSTOMER_APPOINTMENT_FAIL"

/**
 * Lead CUSTOMER_APPOINTMENT
 */
export const GET_LEAD_CUSTOMER_APPOINTMENTS = "GET_LEAD_CUSTOMER_APPOINTMENTS"
export const GET_LEAD_CUSTOMER_APPOINTMENTS_SUCCESS = "GET_LEAD_CUSTOMER_APPOINTMENTS_SUCCESS"
export const GET_LEAD_CUSTOMER_APPOINTMENTS_FAIL = "GET_LEAD_CUSTOMER_APPOINTMENTS_FAIL"


/**
 * Lead CUSTOMER_APPOINTMENT
 */
export const GET_USER_CUSTOMER_APPOINTMENTS = "GET_USER_CUSTOMER_APPOINTMENTS"
export const GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS = "GET_USER_CUSTOMER_APPOINTMENTS_SUCCESS"
export const GET_USER_CUSTOMER_APPOINTMENTS_FAIL = "GET_USER_CUSTOMER_APPOINTMENTS_FAIL"

/**
 * Today CUSTOMER_APPOINTMENT
 */
export const GET_TODAY_CUSTOMER_APPOINTMENTS = "GET_TODAY_CUSTOMER_APPOINTMENTS"
export const GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS = "GET_TODAY_CUSTOMER_APPOINTMENTS_SUCCESS"
export const GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL = "GET_TODAY_CUSTOMER_APPOINTMENTS_FAIL"


/**
 * APPT CRITERIA
 */
export const GET_CUSTOMER_APPOINTMENT_CRITERIA = "GET_CUSTOMER_APPOINTMENT_CRITERIA"
export const GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS = "GET_CUSTOMER_APPOINTMENT_CRITERIA_SUCCESS"
export const GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL = "GET_CUSTOMER_APPOINTMENT_CRITERIA_FAIL"

/**
 * add CUSTOMER With APPT
 */
export const ADD_NEW_CUSTOMER_WITH_APPT = "ADD_NEW_CUSTOMER_WITH_APPT"
export const ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS = "ADD_NEW_CUSTOMER_WITH_APPT_SUCCESS"
export const ADD_NEW_CUSTOMER_WITH_APPT_FAIL = "ADD_NEW_CUSTOMER_WITH_APPT_FAIL"