
/* APARTMENT_TYPES */
export const GET_APARTMENT_TYPES = "GET_APARTMENT_TYPES"
export const GET_APARTMENT_TYPES_SUCCESS = "GET_APARTMENT_TYPES_SUCCESS"
export const GET_APARTMENT_TYPES_FAIL = "GET_APARTMENT_TYPES_FAIL"

/**
 * add APARTMENT_TYPE
 */
export const ADD_NEW_APARTMENT_TYPE = "ADD_NEW_APARTMENT_TYPE"
export const ADD_APARTMENT_TYPE_SUCCESS = "ADD_APARTMENT_TYPE_SUCCESS"
export const ADD_APARTMENT_TYPE_FAIL = "ADD_APARTMENT_TYPE_FAIL"

/**
 * Edit APARTMENT_TYPE
 */
export const UPDATE_APARTMENT_TYPE = "UPDATE_APARTMENT_TYPE"
export const UPDATE_APARTMENT_TYPE_SUCCESS = "UPDATE_APARTMENT_TYPE_SUCCESS"
export const UPDATE_APARTMENT_TYPE_FAIL = "UPDATE_APARTMENT_TYPE_FAIL"

/**
 * Delete APARTMENT_TYPE
 */
export const DELETE_APARTMENT_TYPE = "DELETE_APARTMENT_TYPE"
export const DELETE_APARTMENT_TYPE_SUCCESS = "DELETE_APARTMENT_TYPE_SUCCESS"
export const DELETE_APARTMENT_TYPE_FAIL = "DELETE_APARTMENT_TYPE_FAIL"

/**
 * Lead Criteria
 */
export const APARTMENT_TYPE_CRITERIA = "APARTMENT_TYPE_CRITERIA"
export const APARTMENT_TYPE_CRITERIA_SUCCESS = "APARTMENT_TYPE_CRITERIA_SUCCESS"
export const APARTMENT_TYPE_CRITERIA_FAIL = "APARTMENT_TYPE_CRITERIA_FAIL"
