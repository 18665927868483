import {
    ADD_NEW_REAL_ESTATE_PROJECT,
    ADD_REAL_ESTATE_PROJECT_FAIL,
    ADD_REAL_ESTATE_PROJECT_SUCCESS,
    DELETE_REAL_ESTATE_PROJECT,
    DELETE_REAL_ESTATE_PROJECT_FAIL,
    DELETE_REAL_ESTATE_PROJECT_SUCCESS,
    GET_REAL_ESTATE_PROJECTS,
    GET_REAL_ESTATE_PROJECTS_FAIL,
    GET_REAL_ESTATE_PROJECTS_SUCCESS,
    UPDATE_REAL_ESTATE_PROJECT,
    UPDATE_REAL_ESTATE_PROJECT_FAIL,
    UPDATE_REAL_ESTATE_PROJECT_SUCCESS
} from "./actionTypes";

export const getRealEstateProjects = () => ({
    type: GET_REAL_ESTATE_PROJECTS,
})

export const getRealEstateProjectsSuccess = apartments => ({
    type: GET_REAL_ESTATE_PROJECTS_SUCCESS,
    payload: apartments,
})

export const getRealEstateProjectsFail = error => ({
    type: GET_REAL_ESTATE_PROJECTS_FAIL,
    payload: error,
})

export const addNewRealEstateProject = apartment => ({
    type: ADD_NEW_REAL_ESTATE_PROJECT,
    payload: apartment,
})

export const addRealEstateProjectSuccess = apartment => ({
    type: ADD_REAL_ESTATE_PROJECT_SUCCESS,
    payload: apartment,
})

export const addRealEstateProjectFail = error => ({
    type: ADD_REAL_ESTATE_PROJECT_FAIL,
    payload: error,
})

export const updateRealEstateProject = apartment => ({
    type: UPDATE_REAL_ESTATE_PROJECT,
    payload: apartment,
})

export const updateRealEstateProjectSuccess = apartment => ({
    type: UPDATE_REAL_ESTATE_PROJECT_SUCCESS,
    payload: apartment,
})

export const updateRealEstateProjectFail = error => ({
    type: UPDATE_REAL_ESTATE_PROJECT_FAIL,
    payload: error,
})

export const deleteRealEstateProject = id => ({
    type: DELETE_REAL_ESTATE_PROJECT,
    id,
})

export const deleteRealEstateProjectSuccess = (apartment,req) => ({
    type: DELETE_REAL_ESTATE_PROJECT_SUCCESS,
    payload: apartment,
    req: req,
})

export const deleteRealEstateProjectFail = error => ({
    type: DELETE_REAL_ESTATE_PROJECT_FAIL,
    payload: error,
})
