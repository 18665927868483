import {
    ADD_BUILDING_FAIL,
    ADD_BUILDING_SUCCESS,
    ADD_NEW_BUILDING,
    DELETE_BUILDING,
    DELETE_BUILDING_FAIL,
    DELETE_BUILDING_SUCCESS,
    GET_BUILDINGS,
    GET_BUILDINGS_FAIL,
    GET_BUILDINGS_SUCCESS,
    UPDATE_BUILDING,
    UPDATE_BUILDING_FAIL,
    UPDATE_BUILDING_SUCCESS
} from "./actionTypes";

export const getBuildings = () => ({
    type: GET_BUILDINGS,
})

export const getBuildingsSuccess = buildings => ({
    type: GET_BUILDINGS_SUCCESS,
    payload: buildings,
})

export const getBuildingsFail = error => ({
    type: GET_BUILDINGS_FAIL,
    payload: error,
})

export const addNewBuilding = building => ({
    type: ADD_NEW_BUILDING,
    payload: building,
})

export const addBuildingSuccess = building => ({
    type: ADD_BUILDING_SUCCESS,
    payload: building,
})

export const addBuildingFail = error => ({
    type: ADD_BUILDING_FAIL,
    payload: error,
})

export const updateBuilding = building => ({
    type: UPDATE_BUILDING,
    payload: building,
})

export const updateBuildingSuccess = building => ({
    type: UPDATE_BUILDING_SUCCESS,
    payload: building,
})

export const updateBuildingFail = error => ({
    type: UPDATE_BUILDING_FAIL,
    payload: error,
})

export const deleteBuilding = id => ({
    type: DELETE_BUILDING,
    id,
})

export const deleteBuildingSuccess = (building,req) => ({
    type: DELETE_BUILDING_SUCCESS,
    payload: building,
    req: req,
})

export const deleteBuildingFail = error => ({
    type: DELETE_BUILDING_FAIL,
    payload: error,
})
