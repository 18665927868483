import {
    ADD_APARTMENT_TYPE_FAIL,
    ADD_APARTMENT_TYPE_SUCCESS,
    APARTMENT_TYPE_CRITERIA_FAIL,
    APARTMENT_TYPE_CRITERIA_SUCCESS,
    DELETE_APARTMENT_TYPE_FAIL,
    DELETE_APARTMENT_TYPE_SUCCESS,
    GET_APARTMENT_TYPES_FAIL,
    GET_APARTMENT_TYPES_SUCCESS,
    UPDATE_APARTMENT_TYPE_FAIL,
    UPDATE_APARTMENT_TYPE_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    apartmentTypes: [],
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const ApartmentType = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_APARTMENT_TYPES_SUCCESS:
            return {
                ...state,
                apartmentTypes: action.payload,
            };

        case GET_APARTMENT_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_APARTMENT_TYPE_SUCCESS:
            return {
                ...state,
                apartmentTypes: [...state.apartmentTypes, action.payload],
            };

        case ADD_APARTMENT_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_APARTMENT_TYPE_SUCCESS:
            return {
                ...state,
                apartmentTypes: state.apartmentTypes.map(apartmentType =>
                    apartmentType.id.toString() === action.payload.id.toString()
                        ? { apartmentType, ...action.payload }
                        : apartmentType
                ),
            };

        case UPDATE_APARTMENT_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_APARTMENT_TYPE_SUCCESS:
            return {
                ...state,
                apartmentTypes: state.apartmentTypes.filter(
                    apartmentType => apartmentType.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_APARTMENT_TYPE_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case APARTMENT_TYPE_CRITERIA_SUCCESS:
            return {
                ...state,
                apartmentTypes: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case APARTMENT_TYPE_CRITERIA_FAIL:
            return {
                ...state,
                apartmentTypes: action.payload.data
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default ApartmentType;
