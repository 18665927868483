import {
    ADD_CUSTOMER_FAIL,
    ADD_CUSTOMER_SUCCESS,
    CUSTOMER_CRITERIA_FAIL,
    CUSTOMER_CRITERIA_SUCCESS, CUSTOMER_FIND_FILTERS, CUSTOMER_FIND_FILTERS_FAIL, CUSTOMER_FIND_FILTERS_SUCCESS,
    DELETE_CUSTOMER_FAIL,
    DELETE_CUSTOMER_SUCCESS,
    GET_CUSTOMERS_FAIL,
    GET_CUSTOMERS_SUCCESS,
    UPDATE_CUSTOMER_FAIL,
    UPDATE_CUSTOMER_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    customers: [],
    customerFilters:{},
    error: {},
    page: 0,
    totalCount: 0,
    totalPages: 0
};

const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customers: action.payload,
            };

        case GET_CUSTOMERS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: [...state.customers, action.payload],
            };

        case ADD_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.map(customer =>
                    customer.id.toString() === action.payload.id.toString()
                        ? { customer, ...action.payload }
                        : customer
                ),
            };

        case UPDATE_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: state.customers.filter(
                    customer => customer.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case CUSTOMER_CRITERIA_SUCCESS:
            return {
                ...state,
                customers: action.payload.content,
                page: action.payload.pageable.pageNumber ? action.payload.pageable.pageNumber : 0,
                totalCount: action.payload.totalElements,
                totalPages: (action.payload.totalPages - 1)
            };

        case CUSTOMER_CRITERIA_FAIL:
            return {
                ...state,
                customers: action.payload.data
            };

        case CUSTOMER_FIND_FILTERS_SUCCESS:
            return {
                ...state,
                customerFilters: action.payload,
            };

        case CUSTOMER_FIND_FILTERS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return {
                ...state,
                error: {}
            };
    }
};

export default Customer;
