import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";


import { Name } from "./apartmentDirectionCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import WarningModal from "components/Common/WarningModal";

import {
  addNewApartmentDirection as onAddNewApartmentDirection,
  deleteApartmentDirection as onDeleteApartmentDirection,
  getApartmentDirections as onGetApartmentDirections,
  updateApartmentDirection as onUpdateApartmentDirection
} from "store/apartmentDirection/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "../../hooks/withRouter";
import Notification from "../../components/Notification";

const ApartmentDirection = props => {

  //meta title
  document.title = `CRM | ${props.t("Apartment Rooms")}`;

  const initialApartmentDirection = {
    name: ""
  };

  const dispatch = useDispatch();
  const [deleteApartmentDirection, setDeleteApartmentDirection] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [apartmentDirection, setApartmentDirection] = useState(initialApartmentDirection);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");

  const { apartmentDirections, error } = useSelector(state => ({
    apartmentDirections: state.ApartmentDirection.apartmentDirections,
    error: state.ApartmentDirection.error
  }));

  useEffect(() => {
    dispatch(onGetApartmentDirections());
  }, []);


  const handleRefresh = () => {
    dispatch(onGetApartmentDirections());
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-warning"
                onClick={() => {
                  setApartmentDirection(cellProps.row.original),
                    handleApartmentDirectionClick(cellProps.row);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const apartmentDirectionData = cellProps.row.original;
                  onClickDelete(apartmentDirectionData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setApartmentDirection(initialApartmentDirection);
      setModal(false);
      setIsEdit(false);
    } else {
      setModal(true);
    }
  };

  const handleApartmentDirectionClick = arg => {
    const apartmentDirection = arg.original;
    setIsEdit(true);
    setApartmentDirection({
      id: apartmentDirection.id,
      name: apartmentDirection.name
    });
    toggle();
  };

  //delete lead
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = apartmentDirection => {
    setDeleteApartmentDirection(apartmentDirection);
    setDeleteModal(true);
  };

  const handleDeleteApartmentDirection = () => {
    dispatch(onDeleteApartmentDirection(deleteApartmentDirection.id));
    setDeleteModal(false);
  };

  const handleChange = (e) => {
    setApartmentDirection({
      ...apartmentDirection,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  const saveApartmentDirection = () => {
    if (isEdit) {
      dispatch(onUpdateApartmentDirection(apartmentDirection));
    } else {
      dispatch(onAddNewApartmentDirection(apartmentDirection));
    }
    toggle();
  };

  useEffect(() => {
    if (Object.entries(error).length > 0) {
      setMessage(error.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteApartmentDirection}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={props.t("Apartment Directions")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={apartmentDirections}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={toggle}
                    customPageSize={25}
                    className="custom-header-css"
                  />
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? `${props.t("Edit")}` : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Row form="true">
                        <Col xs={12}>
                          <div className="mb-3">
                            <Label className="form-label">{props.t("Name")}</Label>
                            <Input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={apartmentDirection.name || ""}
                              invalid={!apartmentDirection.name}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-apartmentDirection"
                              onClick={saveApartmentDirection}
                            >
                              {props.t("Save")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(ApartmentDirection));
